import { Alert, Col, Row, Space } from 'antd'
import { useEffect } from 'react'
import { PurchasePlan } from '../../../shared/types/PurchasePlan'
import PurchaseAffordabilityResults from './PurchaseAffordabilityResults'
import { useLazyQuery } from '../hooks/useLazyQuery'
import { APIRoute } from '../network'
import { PurchasePlanAffordability } from '../../../shared/types/PurchasePlan'
import PurchasePlanUpdateForm from './PurchasePlanUpdateForm'
import Text from 'antd/lib/typography/Text'

interface Props {
  plan: PurchasePlan
  onUpdate: (plan: PurchasePlan) => void
}

const PurchasePlanAffordabilityView: React.FC<Props> = ({ plan, onUpdate }) => {
  const {
    makeQuery: getAffordability,
    data: affordabilityData,
    loading: affordabilityLoading,
    error: affordabilityError,
  } = useLazyQuery<PurchasePlanAffordability[]>(
    APIRoute.GetPurchasePlanAffordability,
  )

  const fetchAffordability = () => getAffordability(undefined, plan.id)

  useEffect(() => {
    fetchAffordability()
  }, [])

  return (
    <>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        {plan && (
          <>
            <Row>
              <Col xs={24}>
                <Text type="secondary">
                  Analyse your current and future property purchase
                  affordability. This affordability calculates your monthly
                  affordability, taking into account the deposit you will have,
                  the property's upfront fees, the amount borrowed, and the
                  average monthly repayments (calculated against a default 30
                  year principal and interest loan) to calculate your maximum
                  affordability potential.
                </Text>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                {affordabilityError && (
                  <Alert
                    message="Error"
                    description="There was an issue getting the affordability for your purchase plan. Please try again later."
                    type="error"
                    showIcon
                  />
                )}
                <PurchaseAffordabilityResults
                  loading={affordabilityLoading}
                  results={affordabilityData || []}
                  plan={plan}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <PurchasePlanUpdateForm
                  purchasePlan={plan}
                  onUpdate={updatedPlan => {
                    fetchAffordability()
                    onUpdate(updatedPlan)
                  }}
                />
              </Col>
            </Row>
          </>
        )}
      </Space>
    </>
  )
}

export default PurchasePlanAffordabilityView
