import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Space,
  message,
  Alert,
  Input,
  Checkbox,
  Select,
  DatePicker,
  InputNumber,
  Tooltip,
  List,
} from 'antd'
import { useEffect, useState } from 'react'
import { useLazyQuery } from '../hooks/useLazyQuery'
import { APIRoute } from '../network'
import {
  Loan,
  LoanPaymentFrequency,
  LoanPrincipalAdjustment,
  LoanType,
} from '../../../shared/types/Loan'
import { EditOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons'
import PercentageInput, { PercentageInputRules } from './PercentageInput'
import DollarInput, { DollarInputRules } from './DollarInput'
import moment from 'moment'
import { HelpText } from '../HelpText'
import { HelpLabel } from './HelpLabel'

type LoanFormValues = Omit<
  Loan,
  'userID' | 'propertyID' | 'id' | 'startDate' | 'principalAdjustments'
> & {
  startDate: moment.Moment
  principalAdjustments: Array<
    Omit<LoanPrincipalAdjustment, 'date'> & {
      date: moment.Moment
    }
  >
}

const getFormValues = (loan: Loan): LoanFormValues => ({
  ...loan,
  startDate: moment(loan.startDate),
  interestRate: Number((loan.interestRate * 100).toFixed(2)),
  offsetContributions: Number((loan.offsetContributions * 100).toFixed(2)),
  principalAdjustments: loan.principalAdjustments
    ? loan.principalAdjustments.map(adjustment => ({
        ...adjustment,
        date: moment(adjustment.date),
      }))
    : [],
})

const PropertyLoanForm: React.FC<{
  loan: Loan
  isPrimaryDisabled: boolean
  onUpdate: (loan: Loan) => void
}> = ({ loan, isPrimaryDisabled, onUpdate }) => {
  const [form] = Form.useForm<LoanFormValues>()
  const [formOpen, setFormOpen] = useState(false)
  const [formUpdated, setFormUpdated] = useState(0)

  const { makeQuery, data, loading, error } = useLazyQuery<Loan>(
    APIRoute.UpdateLoan,
  )

  useEffect(() => {
    if (data) {
      message.success('Saved')
      form.setFieldsValue(getFormValues(data))
      setFormOpen(false)
      onUpdate(data)
    }
  }, [data])

  useEffect(() => {
    form.resetFields()
  }, [error])

  const fieldDisabled = loading || !formOpen

  return (
    <Form
      form={form}
      layout="vertical"
      name="loan"
      autoComplete="off"
      requiredMark="optional"
      initialValues={getFormValues(loan)}
      onFinish={async values => {
        await makeQuery(
          {
            ...values,
            startDate: new Date(
              values.startDate.format('yyyy-MM-DD'),
            ).toISOString(),
            interestRate: values.interestRate * 0.01,
            offsetContributions: values.offsetContributions * 0.01,
            principalAdjustments: values.principalAdjustments.map(
              adjustment => ({
                ...adjustment,
                date: new Date(
                  adjustment.date.format('yyyy-MM-DD'),
                ).toISOString(),
              }),
            ),
          },
          loan.propertyID,
          loan.id,
        )
      }}
    >
      <Card
        title="Loan"
        bordered={false}
        className={`form-card loan-form-card${formOpen ? '' : ' view-mode'}`}
        extra={
          <Button
            onClick={() => setFormOpen(true)}
            shape="circle"
            icon={<EditOutlined />}
            htmlType="button"
            disabled={formOpen}
          />
        }
      >
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          {error && (
            <Row>
              <Col xs={24}>
                <Alert
                  message="Error"
                  description="There was an issue saving this loan. Please try again later."
                  type="error"
                  showIcon
                />
              </Col>
            </Row>
          )}
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={4}>
              <Form.Item
                label="Name"
                name="name"
                style={{ margin: '0' }}
                required
                rules={[
                  {
                    required: true,
                    message: 'Please input a name',
                  },
                ]}
              >
                <Input size="large" disabled={fieldDisabled} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={3}>
              <Form.Item
                label={
                  <HelpLabel tooltip={HelpText.PrimaryLoan}>
                    Primary Loan
                  </HelpLabel>
                }
                name="isPrimary"
                valuePropName="checked"
                style={{ margin: '0' }}
                required
              >
                <Checkbox disabled={fieldDisabled || isPrimaryDisabled} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={4}>
              <Form.Item
                label={<HelpLabel tooltip={HelpText.LoanType}>Type</HelpLabel>}
                name="type"
                style={{ margin: '0' }}
                required
                rules={[
                  {
                    required: true,
                    message: 'Please select a type',
                  },
                ]}
              >
                <Select size="large" disabled={fieldDisabled}>
                  <Select.Option value={LoanType.VARIABLE}>
                    Variable
                  </Select.Option>
                  <Select.Option value={LoanType.FIXED}>Fixed</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={4}>
              <Form.Item
                label={
                  <HelpLabel tooltip={HelpText.LoanPaymentFrequency}>
                    Payment Frequency
                  </HelpLabel>
                }
                name="paymentFrequency"
                style={{ margin: '0' }}
                required
                rules={[
                  {
                    required: true,
                    message: 'Please select a frequency',
                  },
                ]}
              >
                <Select size="large" disabled={fieldDisabled}>
                  <Select.Option value={LoanPaymentFrequency.MONTHLY}>
                    Monthly
                  </Select.Option>
                  <Select.Option value={LoanPaymentFrequency.FORTNIGHTLY}>
                    Fortnightly
                  </Select.Option>
                  {/* <Select.Option value={LoanPaymentFrequency.WEEKLY}>
                    Weekly
                  </Select.Option> */}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={4}>
              <Form.Item
                label={
                  <HelpLabel tooltip={HelpText.LoanStartingPrincipal}>
                    Starting Principal
                  </HelpLabel>
                }
                name="amount"
                style={{ margin: '0' }}
                required
                rules={[
                  {
                    type: 'number',
                    min: 0.01,
                    message: 'Please input an amount greater than $0',
                  },
                  {
                    required: true,
                    message: 'Please input an amount',
                  },
                ]}
              >
                <DollarInput disabled={fieldDisabled} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={3}>
              <Form.Item
                label="Start Date"
                name="startDate"
                style={{ margin: '0' }}
                required
                rules={[
                  {
                    required: true,
                    message: 'Please input a date',
                  },
                ]}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  size="large"
                  style={{ width: '100%' }}
                  disabled={fieldDisabled}
                  onChange={() => setFormUpdated(formUpdated + 1)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={2}>
              <Form.Item
                label="Years"
                name="years"
                style={{ margin: '0' }}
                required
                rules={[
                  {
                    type: 'integer',
                    min: 1,
                    message: 'Please input whole loan years',
                  },
                  {
                    type: 'integer',
                    max: 30,
                    message:
                      'Please input loan years less than or equal to 30 years',
                  },
                  {
                    required: true,
                    message: 'Please input loan years',
                  },
                ]}
              >
                <InputNumber
                  size="large"
                  style={{ width: '100%' }}
                  disabled={fieldDisabled}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={3}>
              <Form.Item
                label="Interest Rate"
                name="interestRate"
                style={{ margin: '0' }}
                required
                rules={[
                  ...PercentageInputRules,
                  {
                    required: true,
                    message: 'Please input a percentage',
                  },
                  {
                    type: 'number',
                    max: 100,
                    message:
                      'Please input a percentage less than or equal to 100%',
                  },
                ]}
              >
                <PercentageInput disabled={fieldDisabled} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={4}>
              <Form.Item
                label={
                  <HelpLabel tooltip={HelpText.LoanOffsetContributions}>
                    Offset Contributions
                  </HelpLabel>
                }
                name="offsetContributions"
                style={{ margin: '0' }}
                required
                rules={[
                  {
                    required: true,
                    message: 'Please input a percentage',
                  },
                  {
                    type: 'number',
                    max: 100,
                    message:
                      'Please input a percentage less than or equal to 100%',
                  },
                ]}
              >
                <PercentageInput disabled={fieldDisabled} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <>
              <Col xs={12} sm={5}>
                <Tooltip title={HelpText.LoanPrincipalAdjustments}>
                  <h3
                    style={{
                      margin: '0.5rem 0',
                      textDecorationStyle: 'dotted',
                      textDecorationLine: 'underline',
                      width: 'auto',
                    }}
                  >
                    Principal Adjustments
                  </h3>
                </Tooltip>
              </Col>
              <Col xs={24}>
                <Form.List name="principalAdjustments">
                  {(principalAdjustments, { add, remove }) => (
                    <>
                      <List
                        itemLayout="horizontal"
                        dataSource={principalAdjustments}
                        renderItem={adjustment => (
                          <Space
                            key={adjustment.key}
                            direction="vertical"
                            size="small"
                            style={{ width: '100%', marginBottom: '0.5rem' }}
                          >
                            <Row gutter={[16, 16]}>
                              <Col xs={24} sm={12} md={4}>
                                <Form.Item
                                  label="Date"
                                  required
                                  name={[adjustment.name, 'date']}
                                  fieldKey={[adjustment.fieldKey, 'date']}
                                  style={{ margin: '0' }}
                                >
                                  <DatePicker
                                    format="DD/MM/YYYY"
                                    size="large"
                                    style={{ width: '100%' }}
                                    placeholder=""
                                    disabled={fieldDisabled}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={12} md={5}>
                                <Form.Item
                                  label="Amount"
                                  name={[adjustment.name, 'amount']}
                                  fieldKey={[adjustment.fieldKey, 'amount']}
                                  style={{ margin: '0' }}
                                  required
                                  rules={[
                                    ...DollarInputRules,
                                    {
                                      required: true,
                                      message: 'Please input an amount',
                                    },
                                  ]}
                                >
                                  <DollarInput disabled={fieldDisabled} />
                                </Form.Item>
                              </Col>
                              {formOpen && (
                                <Col
                                  xs={3}
                                  md={1}
                                  className="plan-debit-remove"
                                >
                                  <Button
                                    shape="circle"
                                    size="small"
                                    icon={<MinusOutlined />}
                                    onClick={() => {
                                      remove(adjustment.name)
                                    }}
                                  />
                                </Col>
                              )}
                            </Row>
                          </Space>
                        )}
                      />
                      {formOpen && (
                        <Button
                          type="primary"
                          size="large"
                          onClick={() => add({})}
                          icon={<PlusOutlined />}
                          style={{ marginTop: '1rem' }}
                        >
                          Add Adjustment
                        </Button>
                      )}
                    </>
                  )}
                </Form.List>
              </Col>
            </>
          </Row>
          {formOpen && (
            <Row>
              <Col xs={12} sm={12} md={3} style={{ display: 'flex' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={loading}
                  style={{ marginRight: '1rem' }}
                >
                  Save
                </Button>
                <Button
                  onClick={() => {
                    form.resetFields()
                    setFormOpen(false)
                  }}
                  disabled={loading}
                  htmlType="button"
                  size="large"
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          )}
        </Space>
      </Card>
    </Form>
  )
}

export default PropertyLoanForm
