import { createContext, useState } from 'react'

export const FinancesContext = createContext<{
  financesUpdated: number
  setFinancesUpdated: (value: number) => void
}>({
  financesUpdated: 0,
  setFinancesUpdated: () => null,
})

export const FinancesProvider: React.FC = ({ children }) => {
  const [financesUpdated, setFinancesUpdated] = useState(0)

  return (
    <FinancesContext.Provider
      value={{
        financesUpdated,
        setFinancesUpdated,
      }}
    >
      {children}
    </FinancesContext.Provider>
  )
}
