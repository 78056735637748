import { Link } from '@reach/router'
import {
  Card,
  Table,
  Space,
  Radio,
  Row,
  Col,
  Empty,
  Skeleton,
  Modal,
} from 'antd'
import Title from 'antd/lib/typography/Title'
import { format } from 'date-fns'
import { useState } from 'react'
import { LoanForecast } from '../../../shared/types/Loan'
import { HelpText } from '../HelpText'
import { currencyFormatter } from '../util'
import { HelpLabel } from './HelpLabel'

const LoanForecastTable: React.FC<{
  purchasePlan?: boolean
  loading: boolean
  results: LoanForecast[]
  statistics?: { label: React.ReactNode; value: string }[]
}> = ({ purchasePlan, loading, results, statistics }) => {
  const [toggle, setToggle] = useState<'MONTH' | 'YEAR'>('MONTH')
  const [disclaimersOpen, setDisclaimersOpen] = useState(false)

  if (results.length === 0 && loading) {
    return <Skeleton loading active />
  }

  return (
    <Card
      title={
        <HelpLabel
          tooltip={purchasePlan ? HelpText.PlanForecast : HelpText.LoanForecast}
        >
          Forecast
        </HelpLabel>
      }
      bordered={false}
      className="form-card"
    >
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        {results.length === 0 && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="The target you have set is not in the affordability table above and therefore cannot be forecast. Please try changing your target"
          />
        )}
        {results.length > 0 && (
          <>
            {statistics && (
              <Row>
                {statistics.map((statistic, i) => (
                  <Col xs={12} md={5} key={i}>
                    <p>{statistic.label}</p>
                    <Title level={4}>{statistic.value}</Title>
                  </Col>
                ))}
              </Row>
            )}
            <Row>
              <Col xs={24}>
                <Radio.Group
                  onChange={e => setToggle(e.target.value)}
                  value={toggle}
                >
                  <Radio.Button value="MONTH">Monthly</Radio.Button>
                  <Radio.Button value="YEAR">Yearly</Radio.Button>
                </Radio.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Link to="#" onClick={() => setDisclaimersOpen(true)}>
                  Disclaimers and Assumptions
                </Link>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Table
                  loading={loading}
                  columns={[
                    {
                      title: 'Date',
                      dataIndex: 'endDate',
                      fixed: true,
                      width: 100,
                    },
                    {
                      title: (
                        <HelpLabel tooltip={HelpText.LoanForecastPrincipal}>
                          Principal
                        </HelpLabel>
                      ),
                      dataIndex: 'principal',
                      fixed: false,
                      width: 150,
                    },
                    {
                      title: 'Repayment',
                      dataIndex: 'repaymentTotal',
                      fixed: false,
                      width: 150,
                    },
                    {
                      title: (
                        <HelpLabel tooltip={HelpText.LoanForecastInterest}>
                          Interest
                        </HelpLabel>
                      ),
                      dataIndex: 'interestTotal',
                      fixed: false,
                      width: 150,
                    },
                    {
                      title: (
                        <HelpLabel tooltip={HelpText.LoanForecastNetGainLoss}>
                          Net Gain/Loss
                        </HelpLabel>
                      ),
                      dataIndex: 'netGainOrLoss',
                      fixed: false,
                      width: 150,
                    },
                    {
                      title: (
                        <HelpLabel tooltip={HelpText.LoanForecastSavings}>
                          Savings
                        </HelpLabel>
                      ),
                      dataIndex: 'savings',
                      fixed: false,
                      width: 150,
                    },
                    {
                      title: (
                        <HelpLabel tooltip={HelpText.LoanForecastCredits}>
                          Income
                        </HelpLabel>
                      ),
                      dataIndex: 'creditTotal',
                      fixed: false,
                      width: 150,
                    },
                    {
                      title: (
                        <HelpLabel tooltip={HelpText.LoanForecastRentIncome}>
                          Rent Income
                        </HelpLabel>
                      ),
                      dataIndex: 'rentIncome',
                      fixed: false,
                      width: 150,
                    },
                    {
                      title: (
                        <HelpLabel tooltip={HelpText.LoanForecastDebits}>
                          Expenses
                        </HelpLabel>
                      ),
                      dataIndex: 'debitTotal',
                      fixed: false,
                      width: 150,
                    },
                    {
                      title: (
                        <HelpLabel
                          tooltip={HelpText.LoanForecastOtherLoanRepayments}
                        >
                          Other Loan Repayments
                        </HelpLabel>
                      ),
                      dataIndex: 'otherLoanRepayments',
                      fixed: false,
                      width: 150,
                    },
                    {
                      title: (
                        <HelpLabel
                          tooltip={HelpText.LoanForecastExtraRepayments}
                        >
                          Extra Repayments
                        </HelpLabel>
                      ),
                      dataIndex: 'extraRepayment',
                      fixed: false,
                      width: 150,
                    },
                    {
                      title: (
                        <HelpLabel tooltip={HelpText.LoanForecastEquity}>
                          Equity
                        </HelpLabel>
                      ),
                      dataIndex: 'equity',
                      fixed: false,
                      width: 150,
                    },
                    {
                      title: (
                        <HelpLabel
                          tooltip={HelpText.LoanForecastEquityPurchasePrice}
                        >
                          Estimated Equity Purchase Price
                        </HelpLabel>
                      ),
                      dataIndex: 'estimatedEquityPurchasePrice',
                      fixed: false,
                      width: 150,
                    },
                    {
                      title: (
                        <HelpLabel tooltip={HelpText.LoanForecastPropertyValue}>
                          Property Value
                        </HelpLabel>
                      ),
                      dataIndex: 'propertyValue',
                      fixed: false,
                      width: 150,
                    },
                  ]}
                  pagination={false}
                  dataSource={results
                    .reduce<LoanForecast[]>((acc, curr) => {
                      if (toggle === 'MONTH') {
                        return [...acc, curr]
                      }

                      const yearIndex = acc.findIndex(
                        ({ endDate }) =>
                          new Date(curr.endDate).getFullYear() ===
                          new Date(endDate).getFullYear(),
                      )

                      if (yearIndex === -1) {
                        return [...acc, curr]
                      }

                      return [
                        ...acc.slice(0, yearIndex),
                        {
                          ...acc[yearIndex],
                          principal: curr.principal,
                          repaymentTotal:
                            acc[yearIndex].repaymentTotal + curr.repaymentTotal,
                          interestTotal:
                            acc[yearIndex].interestTotal + curr.interestTotal,
                          savings: curr.savings,
                          creditTotal:
                            acc[yearIndex].creditTotal + curr.creditTotal,
                          debitTotal:
                            acc[yearIndex].debitTotal + curr.debitTotal,
                          netGainOrLoss:
                            acc[yearIndex].netGainOrLoss + curr.netGainOrLoss,
                          extraRepayment:
                            acc[yearIndex].extraRepayment + curr.extraRepayment,
                          equity: curr.equity,
                          estimatedEquityPurchasePrice:
                            curr.estimatedEquityPurchasePrice,
                          propertyValue: curr.propertyValue,
                          rentIncome:
                            acc[yearIndex].rentIncome + curr.rentIncome,
                          otherLoanRepayments:
                            acc[yearIndex].otherLoanRepayments +
                            curr.otherLoanRepayments,
                        },
                        ...acc.slice(yearIndex + 1),
                      ]
                    }, [])
                    .map(result => ({
                      ...result,
                      endDate: format(
                        new Date(String(result.endDate).split('T')[0]),
                        toggle === 'MONTH' ? 'LLL yyyy' : 'yyyy',
                      ),
                      principal: currencyFormatter.format(result.principal),
                      repaymentTotal: currencyFormatter.format(
                        result.repaymentTotal,
                      ),
                      interestTotal: currencyFormatter.format(
                        result.interestTotal,
                      ),
                      savings: currencyFormatter.format(result.savings),
                      creditTotal: currencyFormatter.format(result.creditTotal),
                      debitTotal: currencyFormatter.format(result.debitTotal),
                      netGainOrLoss: currencyFormatter.format(
                        result.netGainOrLoss,
                      ),
                      extraRepayment: currencyFormatter.format(
                        result.extraRepayment,
                      ),
                      equity: currencyFormatter.format(result.equity),
                      estimatedEquityPurchasePrice: currencyFormatter.format(
                        result.estimatedEquityPurchasePrice,
                      ),
                      propertyValue: currencyFormatter.format(
                        result.propertyValue,
                      ),
                      rentIncome: currencyFormatter.format(result.rentIncome),
                      otherLoanRepayments: currencyFormatter.format(
                        result.otherLoanRepayments,
                      ),
                    }))}
                  scroll={{ y: 500 }}
                />
              </Col>
            </Row>
          </>
        )}
      </Space>
      <Modal
        title="Disclaimers and Assumptions"
        visible={disclaimersOpen}
        okText="OK"
        closable={false}
        onCancel={() => setDisclaimersOpen(false)}
        onOk={() => setDisclaimersOpen(false)}
      >
        <p>
          Doorstep cannot and does not contain finance advice. The finance
          information is provided for general informational and educational
          purposes only and is not a substitute for professional advice.
          Accordingly, before taking any actions based upon such information, we
          encourage you to consult with the appropriate professionals. We do not
          provide any kind of finance advice. THE USE OR RELIANCE OF ANY
          INFORMATION CONTAINED ON THE SITE IS SOLELY AT YOUR OWN RISK.
        </p>
        <p>
          All values provided in this loan forecast should be considered
          estimates and not actual forecasts of finances against such a loan.
        </p>
        <ul>
          <li>
            Balances (such as savings, interest, principal) are calculated at
            the start and end of a month
          </li>
          <li>
            Any financial changes within the month (eg. income payments) are
            considered to have occurred at the end of the month. This means when
            calculating interest, these changes are not considered until the
            next month's interest calculation.
          </li>
          <li>
            Available equity does not take into account the financial
            institution's assessment of how much equity you can actually unlock.
            Please see your financial institution to understand how much equity
            you can unlock.
          </li>
        </ul>
      </Modal>
    </Card>
  )
}

export default LoanForecastTable
