export enum ExtraRepaymentFrequency {
  WEEKLY = "WEEKLY",
  FORTNIGHTLY = "FORTNIGHTLY",
  MONTHLY = "MONTHLY",
  YEARLY = "YEARLY",
  ONCE_OFF = "ONCE_OFF",
}

export class ExtraRepayment {
  constructor(
    public userID: string,
    public loanID: string,
    public id: string,
    public amount: number,
    public frequency: ExtraRepaymentFrequency,
    public startDate: Date,
    public endDate?: Date
  ) {}
}
