import { Alert, Skeleton, Card, Empty, Row, Col, message, Button } from 'antd'
import { useEffect, useState } from 'react'
import { useLazyQuery } from '../hooks/useLazyQuery'
import { APIRoute } from '../network'
import { Loan } from '../../../shared/types/Loan'
import { Property } from '../../../shared/types/Property'
import PropertyLoanRentPeriodForm from './PropertyLoanRentPeriodForm'
import { LoanRentPeriod } from '../../../shared/types/LoanRentPeriod'
import { Interval } from 'date-fns'
import PropertyLoanRentPeriodCreateForm from './PropertyLoanRentPeriodCreateForm'
import { HelpLabel } from './HelpLabel'
import { HelpText } from '../HelpText'

const RentPeriodView: React.FC<{
  property?: Property
  loan: Loan
  onUpdate: () => void
}> = ({ property, loan, onUpdate }) => {
  const [rentPeriods, setRentPeriods] = useState<LoanRentPeriod[] | undefined>(
    undefined,
  )
  const [createOpen, setCreateOpen] = useState(false)

  const {
    makeQuery: getRentPeriods,
    data: rentPeriodsData,
    loading: rentPeriodsLoading,
    error: rentPeriodsError,
  } = useLazyQuery<LoanRentPeriod[]>(APIRoute.GetRentPeriods)
  const {
    makeQuery: createRentPeriod,
    data: createData,
    error: createError,
  } = useLazyQuery<LoanRentPeriod>(APIRoute.CreateRentPeriod)
  const {
    makeQuery: updateRentPeriod,
    data: updateData,
    error: updateError,
  } = useLazyQuery<LoanRentPeriod>(APIRoute.UpdateRentPeriod)
  const {
    makeQuery: deleteRentPeriod,
    data: deleteData,
    error: deleteError,
  } = useLazyQuery<LoanRentPeriod>(APIRoute.DeleteRentPeriod)

  useEffect(() => {
    getRentPeriods(undefined, loan.id)
  }, [])

  useEffect(() => {
    if (rentPeriodsData) {
      setRentPeriods(rentPeriodsData)
    }
  }, [rentPeriodsData])

  useEffect(() => {
    if (createData) {
      message.success('Saved')
      setCreateOpen(false)

      setRentPeriods([...(rentPeriods || []), createData])
      onUpdate()
    }
  }, [createData])

  useEffect(() => {
    if (updateData && rentPeriods) {
      message.success('Saved')

      const rentPeriodIndex = rentPeriods.findIndex(
        rentPeriod => rentPeriod.id === updateData.id,
      )

      if (rentPeriodIndex !== -1) {
        setRentPeriods([
          ...rentPeriods.slice(0, rentPeriodIndex),
          updateData,
          ...rentPeriods.slice(rentPeriodIndex + 1),
        ])
      }

      onUpdate()
    }
  }, [updateData])

  useEffect(() => {
    if (deleteData && rentPeriods) {
      message.success('Deleted')

      const rentPeriodIndex = rentPeriods.findIndex(
        rentPeriod => rentPeriod.id === deleteData.id,
      )

      if (rentPeriodIndex !== -1) {
        setRentPeriods([
          ...rentPeriods.slice(0, rentPeriodIndex),
          ...rentPeriods.slice(rentPeriodIndex + 1),
        ])
      }

      onUpdate()
    }
  }, [deleteData])

  return (
    <Card
      bordered={false}
      className="form-card body-no-padding"
      title={
        <HelpLabel tooltip={HelpText.LoanRentPeriods}>Rent Periods</HelpLabel>
      }
    >
      <Skeleton loading={rentPeriodsLoading} active />
      {rentPeriodsError && (
        <Alert
          message="Error"
          description="There was an issue getting your rent periods. Please try again later."
          type="error"
          showIcon
        />
      )}
      {createError && (
        <Alert
          message="Error"
          description="There was an issue creating your rent period. Please try again later."
          type="error"
          showIcon
        />
      )}
      {updateError && (
        <Alert
          message="Error"
          description="There was an issue updating your rent period. Please try again later."
          type="error"
          showIcon
        />
      )}
      {deleteError && (
        <Alert
          message="Error"
          description="There was an issue deleting your rent period. Please try again later."
          type="error"
          showIcon
        />
      )}
      {rentPeriods && !rentPeriodsLoading && (
        <>
          {rentPeriods.length === 0 && !createOpen && (
            <Empty
              style={{ marginTop: '1rem' }}
              description="There are no rent periods against this loan."
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )}
          {rentPeriods.map(rentPeriod => (
            <Row key={rentPeriod.id}>
              <Col xs={24}>
                <PropertyLoanRentPeriodForm
                  loan={loan}
                  invalidDateRanges={
                    rentPeriods
                      ?.filter(({ id }) => rentPeriod.id !== id)
                      .reduce<Interval[]>(
                        (acc, curr) =>
                          acc.concat({
                            start: new Date(curr.startDate),
                            end: new Date(curr.endDate),
                          }),
                        [],
                      ) || []
                  }
                  rentPeriod={rentPeriod}
                  onUpdate={values =>
                    updateRentPeriod(
                      {
                        ...values,
                        startDate: new Date(
                          values.startDate.format('yyyy-MM-DD'),
                        ).toISOString(),
                        endDate: new Date(
                          values.endDate.format('yyyy-MM-DD'),
                        ).toISOString(),
                      },
                      loan.id,
                      rentPeriod.id,
                    )
                  }
                  onDelete={() =>
                    deleteRentPeriod(undefined, loan.id, rentPeriod.id)
                  }
                />
              </Col>
            </Row>
          ))}
        </>
      )}
      {!rentPeriodsLoading && !createOpen && (
        <Button size="large" type="primary" onClick={() => setCreateOpen(true)}>
          Add Rent Period
        </Button>
      )}
      {createOpen && !rentPeriodsLoading && (
        <PropertyLoanRentPeriodCreateForm
          loan={loan}
          invalidDateRanges={
            rentPeriods?.reduce<Interval[]>(
              (acc, curr) =>
                acc.concat({
                  start: new Date(curr.startDate),
                  end: new Date(curr.endDate),
                }),
              [],
            ) || []
          }
          initialAmountPerWeek={property?.estimatedRentPerWeek}
          onCreate={values =>
            createRentPeriod(
              {
                ...values,
                startDate: new Date(
                  values.startDate.format('yyyy-MM-DD'),
                ).toISOString(),
                endDate: new Date(
                  values.endDate.format('yyyy-MM-DD'),
                ).toISOString(),
              },
              loan.id,
            )
          }
          onCancel={() => setCreateOpen(false)}
        />
      )}
    </Card>
  )
}

export default RentPeriodView
