import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  message,
  Modal,
  Checkbox,
  List,
} from 'antd'
import { createRef, useEffect, useState } from 'react'
import { useLazyQuery } from '../hooks/useLazyQuery'
import { APIRoute } from '../network'
import DollarInput, { DollarInputRules } from './DollarInput'
import { Property } from '../../../shared/types/Property'
import {
  PurchasePlan,
  PurchasePlanFinanceSource,
  PurchasePlanFinance,
  PurchasePlanFinanceFees,
  PurchasePlanProperty,
  PurchasePlanStructuralType,
  PurchasePlanPropertyType,
  PurchasePlanPropertyStatus,
  PurchasePlanLoan,
  PurchasePlanExistingPropertyUsageType,
} from '../../../shared/types/PurchasePlan'
import PercentageInput, { PercentageInputRules } from './PercentageInput'
import { HelpText } from '../HelpText'
import { HelpLabel } from './HelpLabel'
import { Link } from '@reach/router'
import Title from 'antd/lib/typography/Title'

type PurchasePlanFormValues = Pick<
  PurchasePlan,
  'name' | 'cashReserve' | 'equityReserve'
> &
  Omit<PurchasePlanFinance, 'fees'> &
  PurchasePlanFinanceFees & {
    market: Partial<PurchasePlanProperty['market']> & {
      suburbValue?: string
    }
    property: PurchasePlanProperty
    loan: PurchasePlanLoan
    properties?: {
      id: string
      leased: boolean
      rentPerWeek?: number
    }[]
  }

const getFormValues = (
  data: PurchasePlan,
  properties: Property[],
): PurchasePlanFormValues => ({
  name: data.name,
  source: data.finance.source,
  propertyIDs: data.finance.propertyIDs,
  cashReserve: data.cashReserve,
  equityReserve: data.equityReserve
    ? Number((data.equityReserve * 100).toFixed(0))
    : undefined,
  agent: data.finance.fees
    ? Number((data.finance.fees.agent * 100).toFixed(1))
    : 2.5,
  conveyancing: data.finance.fees ? data.finance.fees.conveyancing : 1400,
  marketing: data.finance.fees ? data.finance.fees?.marketing : 5500,
  lender: data.finance.fees ? data.finance.fees?.lender : 825,
  staging: data.finance.fees ? data.finance.fees?.staging : 3000,
  auction: data.finance.fees ? data.finance.fees?.auction : 700,
  other: data.finance.fees ? data.finance.fees?.other : 0,
  market: data.property.market
    ? {
        ...data.property.market,
        suburbValue: data.property.market.suburb,
      }
    : {},
  property: {
    state: data.property.state,
    status: data.property.status,
    firstHomeBuyer: data.property.firstHomeBuyer,
    type: data.property.type,
    minimumDeposit: Number((data.property.minimumDeposit * 100).toFixed(0)),
    inflationRatePerAnnum: Number(
      (data.property.inflationRatePerAnnum * 100).toFixed(2),
    ),
    rentalYield: data.property.rentalYield
      ? Number((data.property.rentalYield * 100).toFixed(0))
      : undefined,
  },
  loan: {
    interestRate: Number((data.loan.interestRate * 100).toFixed(2)),
    bufferSavings: data.loan.bufferSavings,
  },
  properties: properties
    .filter(
      property =>
        data.finance.source !== PurchasePlanFinanceSource.SALE ||
        !data.finance.propertyIDs?.includes(property.id),
    )
    .map(property => ({
      id: property.id,
      leased:
        data.existingPropertyUsages?.find(u => u.id === property.id)?.type ===
        PurchasePlanExistingPropertyUsageType.LEASE,
      rentPerWeek:
        data.existingPropertyUsages?.find(u => u.id === property.id)
          ?.rentPerWeek || property.estimatedRentPerWeek,
    })),
})

const PurchasePlanUpdateForm: React.FC<{
  purchasePlan: PurchasePlan
  onUpdate: (plan: PurchasePlan) => void
}> = ({ purchasePlan, onUpdate }) => {
  const [form] = Form.useForm<PurchasePlanFormValues>()
  const [formOpen, setFormOpen] = useState(false)
  const [formUpdated, setFormUpdated] = useState(0)
  const [saleFeesOpen, setSaleFeesOpen] = useState(false)
  const [autocomplete, setAutocomplete] = useState<any>(undefined)
  const addressRef = createRef<Input>()
  const [suburbValue, setSuburbValue] = useState('')

  const {
    makeQuery: getProperties,
    data: propertiesData,
    loading: propertiesLoading,
    error: propertiesError,
  } = useLazyQuery<Property[]>(APIRoute.GetProperties)
  const { makeQuery, data, loading, error } = useLazyQuery<PurchasePlan>(
    APIRoute.UpdatePurchasePlan,
  )

  const handlePlaceChanged = () => {
    if (!autocomplete) {
      return
    }

    const address = autocomplete.getPlace().formatted_address

    form.setFieldsValue({
      market: {
        suburb: address,
      },
    })
    setSuburbValue(address)

    setFormUpdated(formUpdated + 1)
  }

  useEffect(() => {
    getProperties()
  }, [])

  useEffect(() => {
    if (data) {
      message.success('Saved')
      form.setFieldsValue(getFormValues(data, propertiesData || []))
      setFormOpen(false)
      onUpdate(data)
    }
  }, [data])

  useEffect(() => {
    form.resetFields()
    setFormUpdated(formUpdated + 1)
  }, [error])

  useEffect(() => {
    if (
      addressRef?.current?.input &&
      !autocomplete &&
      formOpen &&
      !addressRef?.current?.input.disabled
    ) {
      const address = document.getElementById('address')

      if (!address) {
        return
      }

      const a = new (window as any).google.maps.places.Autocomplete(address, {
        componentRestrictions: { country: 'au' },
        types: ['(cities)'],
      })

      setAutocomplete(a)
    }
  }, [addressRef, formOpen])

  useEffect(() => {
    if (!formOpen) {
      setAutocomplete(undefined)
    }
  }, [formOpen])

  useEffect(() => {
    if (autocomplete) {
      autocomplete.addListener('place_changed', handlePlaceChanged)
    }
  }, [autocomplete])

  useEffect(() => {
    if (!suburbValue && purchasePlan.property.market?.suburb) {
      setSuburbValue(purchasePlan.property.market.suburb)
    }
  }, [purchasePlan])

  useEffect(() => {
    if (!propertiesData || form.getFieldValue('properties')?.length > 0) {
      return
    }

    form.setFieldsValue({
      properties: propertiesData
        .filter(
          property =>
            purchasePlan.finance.source !== PurchasePlanFinanceSource.SALE ||
            !purchasePlan.finance.propertyIDs?.includes(property.id),
        )
        .map(property => ({
          id: property.id,
          leased:
            purchasePlan.existingPropertyUsages?.find(u => u.id === property.id)
              ?.type === PurchasePlanExistingPropertyUsageType.LEASE,
          rentPerWeek:
            purchasePlan.existingPropertyUsages?.find(u => u.id === property.id)
              ?.rentPerWeek || property.estimatedRentPerWeek,
        })),
    })
    setFormUpdated(formUpdated + 1)
  }, [propertiesData])

  const fieldDisabled = loading || propertiesLoading || !formOpen

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        name="purchase-plan-overview"
        autoComplete="off"
        requiredMark="optional"
        initialValues={getFormValues(purchasePlan, propertiesData || [])}
        onFinish={async values => {
          await makeQuery(
            {
              name: values.name,
              finance: {
                source: values.source,
                propertyIDs:
                  values.source !== PurchasePlanFinanceSource.CASH
                    ? values.propertyIDs
                    : undefined,
                fees:
                  values.source === PurchasePlanFinanceSource.SALE
                    ? {
                        agent: values.agent * 0.01,
                        conveyancing: values.conveyancing,
                        marketing: values.marketing,
                        lender: values.lender,
                        staging: values.staging,
                        auction: values.auction,
                        other: values.other,
                      }
                    : undefined,
              },
              cashReserve:
                values.source !== PurchasePlanFinanceSource.EQUITY
                  ? values.cashReserve
                  : undefined,
              equityReserve:
                values.source === PurchasePlanFinanceSource.EQUITY ||
                values.source === PurchasePlanFinanceSource.EQUITY_CASH
                  ? (values.equityReserve || 0) * 0.01
                  : undefined,
              property: {
                ...purchasePlan.property,
                inflationRatePerAnnum: values.market
                  ? undefined
                  : values.property.inflationRatePerAnnum
                  ? values.property.inflationRatePerAnnum * 0.01
                  : purchasePlan.property.inflationRatePerAnnum,
                state: values.property.state,
                status: values.property.status,
                firstHomeBuyer: values.property.firstHomeBuyer,
                type: values.property.type,
                minimumDeposit: values.property.minimumDeposit * 0.01,
                rentalYield:
                  values.property.type ===
                    PurchasePlanPropertyType.INVESTMENT &&
                  values.property.rentalYield
                    ? values.property.rentalYield * 0.01
                    : undefined,
                market: values.market
                  ? {
                      suburb: suburbValue,
                      bedrooms: Number(values.market.bedrooms),
                      structuralType: values.market.structuralType,
                    }
                  : null,
              },
              loan: {
                interestRate: values.loan.interestRate * 0.01,
                bufferSavings:
                  values.loan.bufferSavings === undefined
                    ? null
                    : values.loan.bufferSavings,
              },
              existingPropertyUsages: values.properties?.map(
                (property, index) => ({
                  id: (propertiesData || [])[index]?.id,
                  type: property.leased
                    ? PurchasePlanExistingPropertyUsageType.LEASE
                    : PurchasePlanExistingPropertyUsageType.LIVE,
                  rentPerWeek: property.leased
                    ? property.rentPerWeek
                    : undefined,
                }),
              ),
            },
            purchasePlan.id,
          )
        }}
      >
        {error && (
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Alert
                message="Error"
                description="There was an issue saving this purchase plan. Please try again later."
                type="error"
                showIcon
              />
            </Col>
          </Row>
        )}
        {!formOpen && (
          <Button
            size="large"
            onClick={() => setFormOpen(true)}
            htmlType="button"
          >
            Edit Plan
          </Button>
        )}
        {formOpen && (
          <Row>
            <Col xs={12} sm={12} md={3} style={{ display: 'flex' }}>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                loading={loading}
                style={{ marginRight: '1rem' }}
              >
                Save
              </Button>
              <Button
                onClick={() => {
                  form.resetFields()
                  setFormOpen(false)
                }}
                disabled={loading}
                htmlType="button"
                size="large"
              >
                Cancel
              </Button>
            </Col>
          </Row>
        )}
        <Card
          title="Overview"
          bordered={false}
          className={`form-card${formOpen ? '' : ' view-mode'}`}
        >
          <Space direction="vertical" size="middle" style={{ width: '100%' }}>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} md={6}>
                <Form.Item
                  label="Name"
                  name="name"
                  style={{ margin: '0' }}
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Please input name',
                    },
                  ]}
                >
                  <Input size="large" disabled={fieldDisabled} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} md={6}>
                <Form.Item
                  label={
                    <HelpLabel tooltip={HelpText.PlanFinanceSource}>
                      Finance Source
                    </HelpLabel>
                  }
                  name="source"
                  style={{ margin: '0' }}
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Please select a finance source',
                    },
                  ]}
                >
                  <Select
                    size="large"
                    disabled={fieldDisabled}
                    onChange={() => setFormUpdated(formUpdated + 1)}
                  >
                    <Select.Option value={PurchasePlanFinanceSource.CASH}>
                      Cash
                    </Select.Option>
                    {propertiesData && propertiesData.length > 0 && (
                      <>
                        <Select.Option value={PurchasePlanFinanceSource.EQUITY}>
                          Equity
                        </Select.Option>
                        <Select.Option
                          value={PurchasePlanFinanceSource.EQUITY_CASH}
                        >
                          Equity and Cash
                        </Select.Option>
                        <Select.Option value={PurchasePlanFinanceSource.SALE}>
                          Sale
                        </Select.Option>
                      </>
                    )}
                  </Select>
                </Form.Item>
              </Col>
              {form.getFieldValue('source') !==
                PurchasePlanFinanceSource.CASH &&
                (!propertiesError ||
                  propertiesError.name !== 'RequiresUpgradeError') && (
                  <Col xs={24} sm={12} md={10}>
                    {propertiesError && (
                      <Alert
                        message="There was an issue retrieving your properties."
                        type="error"
                      />
                    )}
                    <Form.Item
                      label="Properties"
                      name="propertyIDs"
                      style={{ margin: '0' }}
                      required
                      rules={[
                        {
                          required: true,
                          message: 'Please select at least one property',
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        allowClear
                        size="large"
                        disabled={fieldDisabled}
                        loading={propertiesLoading}
                      >
                        {(propertiesData || []).map(property => (
                          <Select.Option key={property.id} value={property.id}>
                            {property.address}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
              {form.getFieldValue('source') !==
                PurchasePlanFinanceSource.EQUITY && (
                <Col xs={24} sm={12} md={6}>
                  <Form.Item
                    label={
                      <HelpLabel tooltip={HelpText.CashReserve}>
                        Cash Buffer
                      </HelpLabel>
                    }
                    name="cashReserve"
                    style={{ margin: '0' }}
                    required
                    rules={[
                      {
                        type: 'number',
                        min: 0,
                        message:
                          'Please input an amount greater than or equal to 0',
                      },
                      {
                        required: true,
                        message: 'Please input a cash reserve amount',
                      },
                    ]}
                  >
                    <DollarInput disabled={fieldDisabled} />
                  </Form.Item>
                </Col>
              )}
              {(form.getFieldValue('source') ===
                PurchasePlanFinanceSource.EQUITY ||
                form.getFieldValue('source') ===
                  PurchasePlanFinanceSource.EQUITY_CASH) && (
                <Col xs={24} sm={12} md={6}>
                  <Form.Item
                    label={
                      <HelpLabel tooltip={HelpText.EquityReserve}>
                        Equity Buffer
                      </HelpLabel>
                    }
                    name="equityReserve"
                    style={{ margin: '0 1rem 0 0' }}
                    required
                    rules={[
                      {
                        type: 'number',
                        min: 0,
                        message:
                          'Please input a percentage greater than or equal to 0%',
                      },
                      {
                        type: 'number',
                        max: 100,
                        message:
                          'Please input a percentage less than or equal to 100%',
                      },
                    ]}
                  >
                    <PercentageInput disabled={fieldDisabled} />
                  </Form.Item>
                </Col>
              )}
            </Row>
            {form.getFieldValue('source') ===
              PurchasePlanFinanceSource.SALE && (
              <>
                <Row>
                  <h3>
                    <HelpLabel tooltip={HelpText.PurchaseSaleFees}>
                      Sale Fees
                    </HelpLabel>
                  </h3>
                </Row>
                <Row>
                  <Col xs={24}>
                    <Link to="#" onClick={() => setSaleFeesOpen(true)}>
                      How are sale fee defaults calculated?
                    </Link>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={12} md={3}>
                    <Form.Item
                      label="Agent Fees"
                      name="agent"
                      style={{ margin: '0' }}
                      rules={[
                        {
                          type: 'number',
                          min: 0,
                          message: 'Please input a percentage',
                        },
                      ]}
                      required
                    >
                      <PercentageInput disabled={fieldDisabled} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={3}>
                    <Form.Item
                      label="Conveyancing Fees"
                      name="conveyancing"
                      style={{ margin: '0' }}
                      required
                      rules={[
                        ...DollarInputRules,
                        {
                          required: true,
                          message: 'Please input an amount',
                        },
                      ]}
                    >
                      <DollarInput disabled={fieldDisabled} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={3}>
                    <Form.Item
                      label="Marketing Fees"
                      name="marketing"
                      style={{ margin: '0' }}
                      required
                      rules={[
                        ...DollarInputRules,
                        {
                          required: true,
                          message: 'Please input an amount',
                        },
                      ]}
                    >
                      <DollarInput disabled={fieldDisabled} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={3}>
                    <Form.Item
                      label="Lender Fees"
                      name="lender"
                      style={{ margin: '0' }}
                      required
                      rules={[
                        ...DollarInputRules,
                        {
                          required: true,
                          message: 'Please input an amount',
                        },
                      ]}
                    >
                      <DollarInput disabled={fieldDisabled} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={3}>
                    <Form.Item
                      label="Staging Fees"
                      name="staging"
                      style={{ margin: '0' }}
                      required
                      rules={[
                        ...DollarInputRules,
                        {
                          required: true,
                          message: 'Please input an amount',
                        },
                      ]}
                    >
                      <DollarInput disabled={fieldDisabled} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={3}>
                    <Form.Item
                      label="Auction Fees"
                      name="auction"
                      style={{ margin: '0' }}
                      required
                      rules={[
                        ...DollarInputRules,
                        {
                          required: true,
                          message: 'Please input an amount',
                        },
                      ]}
                    >
                      <DollarInput disabled={fieldDisabled} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={3}>
                    <Form.Item
                      label="Other Fees"
                      name="other"
                      style={{ margin: '0' }}
                      required
                      rules={[
                        ...DollarInputRules,
                        {
                          required: true,
                          message: 'Please input an amount',
                        },
                      ]}
                    >
                      <DollarInput disabled={fieldDisabled} />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
          </Space>
        </Card>
        <Card
          title="Market"
          bordered={false}
          className={`form-card${formOpen ? '' : ' view-mode'}`}
        >
          <Space direction="vertical" size="middle" style={{ width: '100%' }}>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  label="Suburb"
                  name="market.suburb"
                  required={form.getFieldValue(['market', 'suburb'])}
                  style={{ margin: '0 1rem 0 0' }}
                >
                  <Input
                    disabled={fieldDisabled}
                    size="large"
                    ref={addressRef}
                    id="address"
                    onChange={() => {
                      if (!form.getFieldValue(['market', 'suburb'])) {
                        form.setFieldsValue({
                          market: {
                            suburb: '',
                            bedrooms: undefined,
                            structuralType: undefined,
                          },
                        })
                        setSuburbValue('')
                        setFormUpdated(formUpdated + 1)
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={3}>
                <Form.Item
                  label="Beds"
                  name="market.bedrooms"
                  required={form.getFieldValue(['market', 'suburb'])}
                  style={{ margin: '0' }}
                >
                  <Select
                    size="large"
                    disabled={
                      fieldDisabled || !form.getFieldValue(['market', 'suburb'])
                    }
                  >
                    <Select.Option value="1">1</Select.Option>
                    <Select.Option value="2">2</Select.Option>
                    <Select.Option value="3">3</Select.Option>
                    <Select.Option value="4">4</Select.Option>
                    <Select.Option value="5">5</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={5}>
                <Form.Item
                  label="Type"
                  name="market.structuralType"
                  required={form.getFieldValue(['market', 'suburb'])}
                  style={{ margin: '0' }}
                >
                  <Select
                    size="large"
                    disabled={
                      fieldDisabled || !form.getFieldValue(['market', 'suburb'])
                    }
                  >
                    <Select.Option value={PurchasePlanStructuralType.HOUSE}>
                      House
                    </Select.Option>
                    <Select.Option value={PurchasePlanStructuralType.UNIT}>
                      Unit
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Space>
        </Card>
        <Card
          title="Property"
          bordered={false}
          className={`form-card${formOpen ? '' : ' view-mode'}`}
        >
          <Space direction="vertical" size="middle" style={{ width: '100%' }}>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} md={5}>
                <Form.Item
                  label="State"
                  name={['property', 'state']}
                  style={{ margin: '0' }}
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Please select a state',
                    },
                  ]}
                >
                  <Select size="large" disabled={fieldDisabled}>
                    <Select.Option value="VIC">Victoria</Select.Option>
                    <Select.Option value="NSW">New South Wales</Select.Option>
                    <Select.Option value="ACT">ACT</Select.Option>
                    <Select.Option value="QLD">Queensland</Select.Option>
                    <Select.Option value="SA">South Australia</Select.Option>
                    <Select.Option value="NT">Northern Territory</Select.Option>
                    <Select.Option value="WA">Western Australia</Select.Option>
                    <Select.Option value="TAS">Tasmania</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={5}>
                <Form.Item
                  label={
                    <HelpLabel tooltip={HelpText.PlanStatus}>Status</HelpLabel>
                  }
                  name={['property', 'status']}
                  style={{ margin: '0' }}
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Please select a status',
                    },
                  ]}
                >
                  <Select size="large" disabled={fieldDisabled}>
                    <Select.Option
                      value={PurchasePlanPropertyStatus.ESTABLISHED_HOME}
                    >
                      Established Home
                    </Select.Option>
                    <Select.Option value={PurchasePlanPropertyStatus.NEW_HOME}>
                      New Home
                    </Select.Option>
                    <Select.Option
                      value={PurchasePlanPropertyStatus.VACANT_LAND}
                    >
                      Land
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={3}>
                <Form.Item
                  label="First Home Buyer"
                  name={['property', 'firstHomeBuyer']}
                  valuePropName="checked"
                  style={{ margin: '0' }}
                  required
                >
                  <Checkbox disabled={fieldDisabled} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={5}>
                <Form.Item
                  label={
                    <HelpLabel tooltip={HelpText.PlanPropertyType}>
                      Property Type
                    </HelpLabel>
                  }
                  name={['property', 'type']}
                  style={{ margin: '0' }}
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Please select a type',
                    },
                  ]}
                >
                  <Select
                    size="large"
                    disabled={fieldDisabled}
                    onChange={() => setFormUpdated(formUpdated + 1)}
                  >
                    <Select.Option
                      value={PurchasePlanPropertyType.PRIMARY_RESIDENCE}
                    >
                      Primary Residence
                    </Select.Option>
                    <Select.Option value={PurchasePlanPropertyType.INVESTMENT}>
                      Investment
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              {form.getFieldValue(['property', 'type']) ===
                PurchasePlanPropertyType.INVESTMENT && (
                <Col xs={24} sm={12} md={5}>
                  <Form.Item
                    label={
                      <HelpLabel tooltip={HelpText.RentalYield}>
                        Gross Rental Yield
                      </HelpLabel>
                    }
                    name={['property', 'rentalYield']}
                    style={{ margin: '0' }}
                    required
                    rules={[
                      {
                        required: true,
                        message: 'Please input a percentage',
                      },
                      {
                        type: 'number',
                        min: 0,
                        message:
                          'Please input a percentage greater than or equal to 0%',
                      },
                      {
                        type: 'number',
                        max: 100,
                        message:
                          'Please input a percentage less than or equal to 100%',
                      },
                    ]}
                  >
                    <PercentageInput disabled={fieldDisabled} />
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} md={5}>
                <Form.Item
                  label={
                    <HelpLabel tooltip={HelpText.PlanMinimumDeposit}>
                      Minimum Deposit
                    </HelpLabel>
                  }
                  name={['property', 'minimumDeposit']}
                  style={{ margin: '0' }}
                  required
                  rules={[
                    {
                      type: 'number',
                      min: 5,
                      message:
                        'Please input a percentage greater than or equal to 5%',
                    },
                    {
                      required: true,
                      message: 'Please input a percentage',
                    },
                  ]}
                >
                  <PercentageInput disabled={fieldDisabled} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={4}>
                <Form.Item
                  label={
                    <HelpLabel tooltip={HelpText.PlanGrowthRate}>
                      Growth Per Annum
                    </HelpLabel>
                  }
                  name={['property', 'inflationRatePerAnnum']}
                  style={{ margin: '0' }}
                  required
                  rules={[
                    ...PercentageInputRules,
                    {
                      required: true,
                      message: 'Please input a percentage',
                    },
                  ]}
                >
                  <PercentageInput disabled={fieldDisabled} />
                </Form.Item>
              </Col>
            </Row>
          </Space>
        </Card>
        <Card
          title="Loan"
          bordered={false}
          className={`form-card${formOpen ? '' : ' view-mode'}`}
        >
          <Space direction="vertical" size="middle" style={{ width: '100%' }}>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} md={3}>
                <Form.Item
                  label="Interest Rate"
                  name={['loan', 'interestRate']}
                  style={{ margin: '0' }}
                  required
                  rules={[
                    ...PercentageInputRules,
                    {
                      required: true,
                      message: 'Please input a percentage',
                    },
                  ]}
                >
                  <PercentageInput disabled={fieldDisabled} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={7}>
                <Form.Item
                  label={
                    <HelpLabel tooltip={HelpText.PlanBufferSavings}>
                      Monthly Savings Buffer
                    </HelpLabel>
                  }
                  name={['loan', 'bufferSavings']}
                  style={{ margin: '0' }}
                  rules={[
                    {
                      type: 'number',
                      min: 0,
                      message: 'Please input an amount',
                    },
                  ]}
                >
                  {fieldDisabled &&
                  !form.getFieldValue(['loan', 'bufferSavings']) ? null : (
                    <DollarInput disabled={fieldDisabled} />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Space>
        </Card>
        {form.getFieldValue('properties')?.length > 0 && (
          <Card
            title={
              <HelpLabel tooltip={HelpText.PlanTargetExistingProperties}>
                Existing Properties
              </HelpLabel>
            }
            bordered={false}
            className={`form-card${formOpen ? '' : ' view-mode'}`}
          >
            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
              <Form.List name="properties">
                {properties => (
                  <Col xs={24}>
                    <List
                      itemLayout="horizontal"
                      dataSource={properties}
                      renderItem={usage => (
                        <Space
                          key={usage.key}
                          direction="vertical"
                          size="small"
                          style={{ width: '100%', marginBottom: '0.5rem' }}
                        >
                          <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12} md={4}>
                              <b style={{ lineHeight: 2.2 }}>
                                {
                                  propertiesData?.find(
                                    property =>
                                      property.id ===
                                      form.getFieldValue([
                                        'properties',
                                        usage.name,
                                        'id',
                                      ]),
                                  )?.address
                                }
                              </b>
                            </Col>
                            <Col xs={24} sm={12} md={3}>
                              <Form.Item
                                label="Leased"
                                name={[usage.name, 'leased']}
                                fieldKey={[usage.fieldKey, 'leased']}
                                valuePropName="checked"
                                style={{ margin: '0 1rem 0 0' }}
                                required
                              >
                                <Checkbox
                                  disabled={fieldDisabled}
                                  onChange={() =>
                                    setFormUpdated(formUpdated + 1)
                                  }
                                />
                              </Form.Item>
                            </Col>
                            {form.getFieldValue([
                              'properties',
                              usage.name,
                              'leased',
                            ]) && (
                              <Col xs={24} sm={12} md={5}>
                                <Form.Item
                                  label="Rent Per Week"
                                  name={[usage.name, 'rentPerWeek']}
                                  fieldKey={[usage.fieldKey, 'rentPerWeek']}
                                  style={{ margin: '0' }}
                                  required={form.getFieldValue([
                                    'properties',
                                    usage.name,
                                    'leased',
                                  ])}
                                  rules={
                                    form.getFieldValue([
                                      'properties',
                                      usage.name,
                                      'leased',
                                    ])
                                      ? [
                                          {
                                            type: 'number',
                                            min: 0.01,
                                            message:
                                              'Please input an amount greater than $0',
                                          },
                                          {
                                            required: true,
                                            message: 'Please input an amount',
                                          },
                                        ]
                                      : []
                                  }
                                >
                                  <DollarInput
                                    disabled={
                                      fieldDisabled ||
                                      !form.getFieldValue([
                                        'properties',
                                        usage.name,
                                        'leased',
                                      ])
                                    }
                                    onChange={() =>
                                      setFormUpdated(formUpdated + 1)
                                    }
                                  />
                                </Form.Item>
                              </Col>
                            )}
                          </Row>
                        </Space>
                      )}
                    />
                  </Col>
                )}
              </Form.List>
            </Space>
          </Card>
        )}
      </Form>
      <Modal
        title="Sale fee defaults calculations"
        visible={saleFeesOpen}
        okText="OK"
        closable={false}
        onCancel={() => setSaleFeesOpen(false)}
        onOk={() => setSaleFeesOpen(false)}
      >
        <p>
          Doorstep cannot and does not contain finance advice. The finance
          information is provided for general informational and educational
          purposes only and is not a substitute for professional advice.
          Accordingly, before taking any actions based upon such information, we
          encourage you to consult with the appropriate professionals. We do not
          provide any kind of finance advice. THE USE OR RELIANCE OF ANY
          INFORMATION CONTAINED ON THE SITE IS SOLELY AT YOUR OWN RISK.
        </p>
        <Title level={4}>How are these sale fee defaults calculated?</Title>
        <p>
          When selling a property, there are many fees to consider before
          calculating the profit you could make.
        </p>
        <p>The default fees provided by Doorstep are as follows:</p>
        <ul>
          <li>
            <b>Agent Fees: 2.5%</b> - Commission that the real estate agent
            takes, usually as a percentage of the sale price.
          </li>
          <li>
            <b>Conveyancing Fees: $1,400</b> - Costs of hiring a licensed
            conveyancer or solicitor to transfer legal ownership of the property
            to the buyer.
          </li>
          <li>
            <b>Marketing Fees: $5,500</b> - Costs of placing advertising boards
            and online listings etc. to market your property.
          </li>
          <li>
            <b>Lender Fees: $825</b> - Mortgage discharge cost with your current
            lender. Only applies if you are exiting out of your existing
            mortgage.
          </li>
          <li>
            <b>Staging Fees: $3,000</b> - Costs to style and place furniture in
            your property while it is on the market.
          </li>
          <li>
            <b>Auction Fees: $700</b> - Costs for real estate agents to host an
            auction for your property. Only applies if your property goes to
            auction.
          </li>
          <li>
            <b>Other Fees: $0</b> - No other costs are considered by Doorstep,
            but you may have additional costs you'd like to add.
          </li>
        </ul>
        <p>
          For more information on these costs and how they vary state to state,
          please see:{' '}
          <a
            href="https://www.realestate.com.au/advice/how-much-does-it-cost-to-sell-a-house/"
            target="_blank"
          >
            https://www.realestate.com.au/advice/how-much-does-it-cost-to-sell-a-house/
          </a>
        </p>
        <p>
          Doorstep's defaults are an average of cost ranges across all states
          listed in the article linked above. There is no differentiation
          between states, please consider this when applying your sale costs for
          a purchase plan.
        </p>
      </Modal>
    </>
  )
}

export default PurchasePlanUpdateForm
