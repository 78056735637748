import { StarOutlined } from '@ant-design/icons'
import { Link, navigate, useLocation } from '@reach/router'
import { Button, Empty, Tabs, Tooltip } from 'antd'
import { useEffect, useState } from 'react'
import { Loan } from '../../../shared/types/Loan'
import { Property } from '../../../shared/types/Property'
import { HelpText } from '../HelpText'
import { Routes } from '../routes'
import LoanView from './LoanView'

const LoanList: React.FC<{ property?: Property; loans: Loan[] }> = ({
  property,
  loans,
}) => {
  const location = useLocation()
  const [loanList, setLoanList] = useState(loans)

  useEffect(() => {
    setLoanList(loans)
  }, [loans])

  useEffect(() => {
    if (location.pathname.split('/').length === 4) {
      const primaryLoan = loans.find(loan => loan.isPrimary)

      if (primaryLoan) {
        navigate(`loans/${primaryLoan.id}`)
      }
    }
  }, [location])

  const loanID =
    location.pathname.split('/').length === 4
      ? ''
      : location.pathname.split('/').slice(-1)[0]
  const loan = loanList.find(({ id }) => id === loanID)

  return (
    <>
      <div
        style={{
          margin: '0 0 1rem',
          padding: '0.5rem',
        }}
      >
        <Link to={`${Routes.PROPERTIES}/${property?.id}/loans/create`}>
          <Tooltip title={HelpText.AddLoan}>
            <Button size="large" type="primary" style={{ marginRight: '1rem' }}>
              Add Loan
            </Button>
          </Tooltip>
        </Link>
        {loanList.length > 1 && (
          <Tooltip title={HelpText.CompareLoans}>
            <Link to={`${Routes.PROPERTIES}/${property?.id}/loans/compare`}>
              <Button size="large">Compare Loans</Button>
            </Link>
          </Tooltip>
        )}
      </div>
      <div
        style={{
          width: '100%',
          margin: '0 0 1rem',
          padding: '0.5rem',
        }}
      >
        <Tabs
          activeKey={loan?.id}
          onChange={value =>
            navigate(`${Routes.PROPERTIES}/${property?.id}/loans/${value}`)
          }
        >
          {loanList
            .sort(a => (a.isPrimary ? -1 : 0))
            .map((loan, index) => (
              <Tabs.TabPane
                key={loan.id}
                tab={
                  <span>
                    {loan.isPrimary && <StarOutlined />}
                    {loan.name}
                  </span>
                }
              >
                <LoanView
                  loan={loan}
                  numberOfLoans={loanList.length}
                  onDelete={() => {
                    setLoanList([
                      ...loanList.slice(0, index).map(data => ({
                        ...data,
                        isPrimary: loan.isPrimary ? false : data.isPrimary,
                      })),
                      ...loanList.slice(index + 1).map(data => ({
                        ...data,
                        isPrimary: loan.isPrimary ? false : data.isPrimary,
                      })),
                    ])
                  }}
                  onCopy={loan => {
                    setLoanList([...loanList, loan])
                    navigate(loan.id)
                  }}
                  onUpdate={loan => {
                    const loanIndex = loanList.findIndex(
                      ({ id }) => id === loan.id,
                    )

                    if (loanIndex !== -1) {
                      setLoanList([
                        ...loanList.slice(0, loanIndex).map(data => ({
                          ...data,
                          isPrimary: loan.isPrimary ? false : data.isPrimary,
                        })),
                        loan,
                        ...loanList.slice(loanIndex + 1).map(data => ({
                          ...data,
                          isPrimary: loan.isPrimary ? false : data.isPrimary,
                        })),
                      ])
                    }
                  }}
                />
              </Tabs.TabPane>
            ))}
        </Tabs>
        {loanList.length === 0 && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="You have no loans against this property. Please create a loan to get started."
          />
        )}
      </div>
    </>
  )
}

export default LoanList
