import { Tooltip } from 'antd'

export const HelpLabel: React.FC<{
  tooltip: string
  children?: React.ReactNode
}> = ({ tooltip, children }) => (
  <Tooltip title={tooltip}>
    <span
      style={{
        textDecorationStyle: 'dotted',
        textDecorationLine: 'underline',
      }}
    >
      {children}
    </span>
  </Tooltip>
)
