import { Button, Card, Col, Form, Row, Space, DatePicker } from 'antd'
import { useState } from 'react'
import DollarInput, { DollarInputRules } from './DollarInput'
import { LoanRentPeriod } from '../../../shared/types/LoanRentPeriod'
import moment from 'moment'
import { addYears, isAfter, isBefore, isWithinInterval } from 'date-fns'
import isSameDay from 'date-fns/isSameDay'
import { Loan } from '../../../shared/types/Loan'

type LoanRentPeriodCreateValues = Pick<LoanRentPeriod, 'amountPerWeek'> & {
  startDate: moment.Moment
  endDate: moment.Moment
}

const PropertyLoanLoanRentPeriodCreateForm: React.FC<{
  loan: Loan
  onCreate: (credit: LoanRentPeriodCreateValues) => void
  onCancel: () => void
  invalidDateRanges: Interval[]
  initialAmountPerWeek?: number
}> = ({
  loan,
  onCreate,
  onCancel,
  invalidDateRanges,
  initialAmountPerWeek,
}) => {
  const [form] = Form.useForm<LoanRentPeriodCreateValues>()
  const [isUpdating, setIsUpdating] = useState(false)

  return (
    <Form
      form={form}
      layout="vertical"
      name="extra-repayment-create"
      autoComplete="off"
      requiredMark="optional"
      initialValues={{
        amountPerWeek: initialAmountPerWeek || 0,
      }}
      onFinish={values => {
        setIsUpdating(true)
        onCreate(values)
      }}
    >
      <Card bordered={false} className="form-card">
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={5}>
              <Form.Item
                label="Amount Per Week"
                name="amountPerWeek"
                style={{ margin: '0' }}
                required
                rules={[
                  ...DollarInputRules,
                  {
                    required: true,
                    message: 'Please input an amount',
                  },
                ]}
              >
                <DollarInput />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={4}>
              <Form.Item
                label="Start Date"
                name="startDate"
                style={{ margin: '0' }}
                required
                rules={[
                  {
                    required: true,
                    message: 'Please input a date',
                  },
                ]}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  size="large"
                  style={{ width: '100%' }}
                  placeholder=""
                  disabledDate={current => {
                    if (
                      isBefore(current.toDate(), new Date(loan.startDate)) ||
                      isAfter(
                        current.toDate(),
                        addYears(new Date(loan.startDate), loan.years),
                      ) ||
                      invalidDateRanges.some(
                        range =>
                          isWithinInterval(current.toDate(), range) ||
                          isSameDay(current.toDate(), range.start) ||
                          isSameDay(current.toDate(), range.end),
                      )
                    ) {
                      return true
                    }

                    const endDate = form.getFieldValue(['endDate'])

                    return current && endDate && current > endDate
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={4}>
              <Form.Item
                label="End Date"
                name="endDate"
                style={{ margin: '0' }}
                required
                rules={[
                  {
                    required: true,
                    message: 'Please input a date',
                  },
                ]}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  size="large"
                  style={{ width: '100%' }}
                  placeholder=""
                  disabledDate={current => {
                    if (
                      isBefore(current.toDate(), new Date(loan.startDate)) ||
                      isAfter(
                        current.toDate(),
                        addYears(new Date(loan.startDate), loan.years),
                      ) ||
                      invalidDateRanges.some(
                        range =>
                          isWithinInterval(current.toDate(), range) ||
                          isSameDay(current.toDate(), range.start) ||
                          isSameDay(current.toDate(), range.end),
                      )
                    ) {
                      return true
                    }

                    const startDate = form.getFieldValue(['startDate'])

                    return current && startDate && current < startDate
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={12} sm={12} md={3} style={{ display: 'flex' }}>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                loading={isUpdating}
                style={{ marginRight: '1rem' }}
              >
                Save
              </Button>
              <Button
                onClick={onCancel}
                disabled={isUpdating}
                htmlType="button"
                size="large"
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Space>
      </Card>
    </Form>
  )
}

export default PropertyLoanLoanRentPeriodCreateForm
