import { Alert, Skeleton, Card, Empty, Row, Col, message, Button } from 'antd'
import { useEffect, useState } from 'react'
import { useLazyQuery } from '../hooks/useLazyQuery'
import { APIRoute } from '../network'
import { Loan } from '../../../shared/types/Loan'
import PropertyLoanExtraRepaymentForm from './PropertyLoanExtraRepaymentForm'
import { ExtraRepayment } from '../../../shared/types/ExtraRepayment'
import { Interval } from 'date-fns'
import PropertyLoanExtraRepaymentCreateForm from './PropertyLoanExtraRepaymentCreateForm'
import { HelpLabel } from './HelpLabel'
import { HelpText } from '../HelpText'

const ExtraRepaymentView: React.FC<{ loan: Loan; onUpdate: () => void }> = ({
  loan,
  onUpdate,
}) => {
  const [extraRepayments, setExtraRepayments] = useState<
    ExtraRepayment[] | undefined
  >(undefined)
  const [createOpen, setCreateOpen] = useState(false)

  const {
    makeQuery: getExtraRepayments,
    data: extraRepaymentsData,
    loading: extraRepaymentsLoading,
    error: extraRepaymentsError,
  } = useLazyQuery<ExtraRepayment[]>(APIRoute.GetExtraRepayments)
  const {
    makeQuery: createExtraRepayment,
    data: createData,
    error: createError,
  } = useLazyQuery<ExtraRepayment>(APIRoute.CreateExtraRepayment)
  const {
    makeQuery: updateExtraRepayment,
    data: updateData,
    error: updateError,
  } = useLazyQuery<ExtraRepayment>(APIRoute.UpdateExtraRepayment)
  const {
    makeQuery: deleteExtraRepayment,
    data: deleteData,
    error: deleteError,
  } = useLazyQuery<ExtraRepayment>(APIRoute.DeleteExtraRepayment)

  useEffect(() => {
    getExtraRepayments(undefined, loan.id)
  }, [])

  useEffect(() => {
    if (extraRepaymentsData) {
      setExtraRepayments(extraRepaymentsData)
    }
  }, [extraRepaymentsData])

  useEffect(() => {
    if (createData) {
      message.success('Saved')
      setCreateOpen(false)

      setExtraRepayments([...(extraRepayments || []), createData])
      onUpdate()
    }
  }, [createData])

  useEffect(() => {
    if (updateData && extraRepayments) {
      message.success('Saved')

      const extraRepaymentIndex = extraRepayments.findIndex(
        extraRepayment => extraRepayment.id === updateData.id,
      )

      if (extraRepaymentIndex !== -1) {
        setExtraRepayments([
          ...extraRepayments.slice(0, extraRepaymentIndex),
          updateData,
          ...extraRepayments.slice(extraRepaymentIndex + 1),
        ])
      }

      onUpdate()
    }
  }, [updateData])

  useEffect(() => {
    if (deleteData && extraRepayments) {
      message.success('Deleted')

      const extraRepaymentIndex = extraRepayments.findIndex(
        extraRepayment => extraRepayment.id === deleteData.id,
      )

      if (extraRepaymentIndex !== -1) {
        setExtraRepayments([
          ...extraRepayments.slice(0, extraRepaymentIndex),
          ...extraRepayments.slice(extraRepaymentIndex + 1),
        ])
      }

      onUpdate()
    }
  }, [deleteData])

  return (
    <Card
      bordered={false}
      className="form-card body-no-padding"
      title={
        <HelpLabel tooltip={HelpText.ExtraRepayments}>
          Extra Repayments
        </HelpLabel>
      }
    >
      <Skeleton loading={extraRepaymentsLoading} active />
      {extraRepaymentsError && (
        <Alert
          message="Error"
          description="There was an issue getting your extra repayments. Please try again later."
          type="error"
          showIcon
        />
      )}
      {createError && (
        <Alert
          message="Error"
          description="There was an issue creating your extra repayment. Please try again later."
          type="error"
          showIcon
        />
      )}
      {updateError && (
        <Alert
          message="Error"
          description="There was an issue updating your extra repayment. Please try again later."
          type="error"
          showIcon
        />
      )}
      {deleteError && (
        <Alert
          message="Error"
          description="There was an issue deleting your extra repayment. Please try again later."
          type="error"
          showIcon
        />
      )}
      {extraRepayments && !extraRepaymentsLoading && (
        <>
          {extraRepayments.length === 0 && !createOpen && (
            <Empty
              style={{ marginTop: '1rem' }}
              description="There are no extra repayments against this loan."
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )}
          {extraRepayments.map(extraRepayment => (
            <Row key={extraRepayment.id}>
              <Col xs={24}>
                <PropertyLoanExtraRepaymentForm
                  loan={loan}
                  invalidDateRanges={
                    extraRepayments
                      ?.filter(({ id }) => extraRepayment.id !== id)
                      .reduce<Interval[]>(
                        (acc, curr) =>
                          acc.concat({
                            start: new Date(curr.startDate),
                            end: curr.endDate
                              ? new Date(curr.endDate)
                              : new Date(curr.startDate),
                          }),
                        [],
                      ) || []
                  }
                  extraRepayment={extraRepayment}
                  onUpdate={values =>
                    updateExtraRepayment(
                      {
                        ...values,
                        startDate: new Date(
                          values.startDate.format('yyyy-MM-DD'),
                        ).toISOString(),
                        endDate:
                          values.endDate &&
                          new Date(
                            values.endDate.format('yyyy-MM-DD'),
                          ).toISOString(),
                      },
                      loan.id,
                      extraRepayment.id,
                    )
                  }
                  onDelete={() =>
                    deleteExtraRepayment(undefined, loan.id, extraRepayment.id)
                  }
                />
              </Col>
            </Row>
          ))}
        </>
      )}
      {!extraRepaymentsLoading && !createOpen && (
        <Button size="large" type="primary" onClick={() => setCreateOpen(true)}>
          Add Extra Repayment
        </Button>
      )}
      {createOpen && !extraRepaymentsLoading && (
        <PropertyLoanExtraRepaymentCreateForm
          loan={loan}
          invalidDateRanges={
            extraRepayments?.reduce<Interval[]>(
              (acc, curr) =>
                acc.concat({
                  start: new Date(curr.startDate),
                  end: curr.endDate
                    ? new Date(curr.endDate)
                    : new Date(curr.startDate),
                }),
              [],
            ) || []
          }
          onCreate={values =>
            createExtraRepayment(
              {
                ...values,
                startDate: new Date(
                  values.startDate.format('yyyy-MM-DD'),
                ).toISOString(),
                endDate:
                  values.endDate &&
                  new Date(values.endDate.format('yyyy-MM-DD')).toISOString(),
              },
              loan.id,
            )
          }
          onCancel={() => setCreateOpen(false)}
        />
      )}
    </Card>
  )
}

export default ExtraRepaymentView
