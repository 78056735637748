import { MenuOutlined } from '@ant-design/icons'
import { Link } from '@reach/router'
import { Alert, Button, Drawer, Layout } from 'antd'
import Text from 'antd/lib/typography/Text'
import { useContext, useEffect, useState } from 'react'
import { useLazyQuery } from '../hooks/useLazyQuery'
import { APIRoute } from '../network'
import Menu from './Menu'
import { UserContext } from './UserContext'
import UserMenu from './UserMenu'

export const authenticatedRoute = (Component: React.FC) => (props: any) => {
  const [width, setWidth] = useState<number>(window.innerWidth)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const user = useContext(UserContext)
  const [sentEmail, setSentEmail] = useState(false)
  const [hadError, setHadError] = useState(false)

  const { makeQuery, data, loading, error } = useLazyQuery<null>(
    APIRoute.SendUserVerificationEmail,
  )

  useEffect(() => {
    if (!loading && !error && data) {
      setSentEmail(true)
    }

    if (!loading && error) {
      setHadError(true)
    }
  }, [loading])

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  useEffect(() => {
    if (width > 768 && drawerOpen) {
      setDrawerOpen(false)
    }
  }, [width])

  const isMobile = width <= 768

  return (
    <Layout>
      {user && !user.emailVerified && (
        <Alert
          showIcon={false}
          type="info"
          message={
            sentEmail ? (
              <span>Verification email sent, please check your inbox</span>
            ) : hadError ? (
              <span>
                There was an error sending your verification email. Please{' '}
                <Button
                  type="link"
                  style={{ paddingLeft: '0', paddingRight: '0' }}
                  loading={loading}
                  onClick={async () => {
                    await makeQuery({ redirectURL: window.location.origin })
                  }}
                >
                  try again
                </Button>
              </span>
            ) : (
              <span>
                Welcome to Doorstep! Please{' '}
                <Button
                  type="link"
                  style={{ paddingLeft: '0', paddingRight: '0' }}
                  loading={loading}
                  onClick={async () => {
                    await makeQuery({ redirectURL: window.location.origin })
                  }}
                >
                  verify your email
                </Button>
              </span>
            )
          }
          banner
          style={{ textAlign: 'center' }}
          closable
        />
      )}
      <Layout.Header style={{ display: 'flex', padding: 0 }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '0 2rem 0 1rem',
            flex: 1,
          }}
        >
          <Link
            to="/"
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              marginRight: '3rem',
            }}
          >
            <img src="/logo.png" height="24px" />
          </Link>
          {!isMobile && <Menu />}
        </div>
        {!isMobile && <UserMenu />}
        {isMobile && (
          <div
            style={{ display: 'flex', alignItems: 'center', padding: '0 1rem' }}
          >
            <UserMenu mobile />
            <Button
              onClick={() => setDrawerOpen(true)}
              size="large"
              style={{ marginLeft: '1rem' }}
              icon={<MenuOutlined />}
            />
          </div>
        )}
        <Drawer
          title="Menu"
          placement="right"
          onClose={() => setDrawerOpen(false)}
          visible={drawerOpen}
        >
          <Menu mobile />
        </Drawer>
      </Layout.Header>
      <Layout.Content className="main-content">
        <Component {...props} />
      </Layout.Content>
      <Layout.Footer>
        <Text>
          <a
            href="https://doorstepapp.com.au/terms-and-conditions"
            style={{ marginRight: '1rem' }}
          >
            Terms and Conditions
          </a>
          <a
            href="https://doorstepapp.com.au/disclaimer"
            style={{ marginRight: '1rem' }}
          >
            Disclaimer
          </a>
          <a href="https://doorstepapp.com.au/privacy-policy">Privacy Policy</a>
        </Text>
      </Layout.Footer>
    </Layout>
  )
}
