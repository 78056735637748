import { Line } from '@ant-design/charts'
import { Link } from '@reach/router'
import {
  Alert,
  Card,
  Col,
  Divider,
  Modal,
  Radio,
  Row,
  Skeleton,
  Space,
  Table,
} from 'antd'
import Text from 'antd/lib/typography/Text'
import Title from 'antd/lib/typography/Title'
import { format } from 'date-fns'
import { startCase } from 'lodash'
import { memo, useState } from 'react'
import {
  PurchasePlan,
  PurchasePlanAffordability,
  PurchasePlanFinanceSource,
  PurchasePlanPropertyType,
} from '../../../shared/types/PurchasePlan'
import { HelpText } from '../HelpText'
import { currencyFormatter } from '../util'
import { HelpLabel } from './HelpLabel'

const PurchaseAffordabilityResults: React.FC<{
  results: PurchasePlanAffordability[]
  loading: boolean
  plan: PurchasePlan
}> = memo(({ results, loading, plan }) => {
  const [disclaimersOpen, setDisclaimersOpen] = useState(false)
  const [marketDisclaimerOpen, setMarketDisclaimerOpen] = useState(false)
  const [toggle, setToggle] = useState<'GRAPH' | 'TABLE'>('GRAPH')

  const data = results.reduce<
    Array<{ name: string; date: Date; amount: number }>
  >((acc, result) => {
    return [
      ...acc,
      {
        name: 'Maximum Affordable Price',
        date: result.endDate,
        amount: result.maximumAffordablePrice,
      },
      {
        name: 'Deposit',
        date: result.endDate,
        amount: result.deposit,
      },
      {
        name: 'Fees',
        date: result.endDate,
        amount: result.fees,
      },
    ]
  }, [])

  const loanConfig = {
    data,
    xField: 'date',
    yField: 'amount',
    seriesField: 'name',
    xAxis: {
      tickCount: 10,
      label: {
        formatter: (date: string) => format(new Date(date), 'MMM yyyy'),
      },
      title: {
        text: 'Date',
      },
    },
    yAxis: {
      label: {
        formatter: (price: any) => currencyFormatter.format(price),
      },
    },
    legend: { position: 'top' as any },
    smooth: false,
    tooltip: {
      customContent: (date: any) => {
        const result = results.find(({ endDate }) => endDate === date)

        if (!result) {
          return ''
        }

        return `
          <div class="graph-tooltip-row">
            <b>${format(new Date(result.endDate), 'MMM yyyy')}</b>
          </div>

          <div class="graph-tooltip-row">
            <div class="graph-tooltip-row-label">Price:</div>
            <div>${currencyFormatter.format(
              result.maximumAffordablePrice,
            )}</div>
          </div>

          <div class="graph-tooltip-row">
            <div class="graph-tooltip-row-label">Deposit:</div>
            <div>${currencyFormatter.format(result.deposit)}</div>
          </div>

          <div class="graph-tooltip-row">
            <div class="graph-tooltip-row-label">Fees:</div>
            <div>${currencyFormatter.format(result.fees)}</div>
          </div>
        `
      },
    },
  }

  const resultAffordableMarket = plan.property.market?.targetPrice
    ? results?.find(
        result =>
          plan.property.market &&
          result.maximumAffordablePrice >= plan.property.market.targetPrice,
      )
    : undefined

  const statistics = {
    affordability:
      !results || results.length < 24
        ? []
        : [
            {
              label: format(
                new Date(
                  (results && results[0] && results[0].endDate) || undefined,
                ),
                'LLL yyyy',
              ),
              value:
                results && results[0] ? results[0].maximumAffordablePrice : 0,
            },
            {
              label: format(
                new Date(
                  (results && results[5] && results[5].endDate) || undefined,
                ),
                'LLL yyyy',
              ),
              value:
                results && results[5] ? results[5].maximumAffordablePrice : 0,
            },
            {
              label: format(
                new Date(
                  (results && results[11] && results[11].endDate) || undefined,
                ),
                'LLL yyyy',
              ),
              value:
                results && results[11] ? results[11].maximumAffordablePrice : 0,
            },
            {
              label: format(
                new Date(
                  (results && results[17] && results[17].endDate) || undefined,
                ),
                'LLL yyyy',
              ),
              value:
                results && results[17] ? results[17].maximumAffordablePrice : 0,
            },
          ],
    averageDepositGrowthPerMonth:
      results &&
      results.reduce((acc, curr, index) => {
        if (index === 0) {
          return curr.deposit
        }

        return acc + (curr.deposit - results[index - 1].deposit)
      }, 0) / results.length,
    dateAffordableMarket: resultAffordableMarket
      ? format(new Date(resultAffordableMarket.endDate), 'LLL yyyy')
      : undefined,
  }

  return (
    <>
      <Card bordered={false} className="form-card">
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          {loading && <Skeleton loading active />}
          {!loading && results.length > 0 && (
            <>
              <Row>
                {statistics.affordability.map(affordability => (
                  <Col xs={12} md={6} lg={4} key={affordability.label}>
                    <p>{affordability.label}</p>
                    <Title level={4}>
                      {currencyFormatter.format(affordability.value)}
                    </Title>
                  </Col>
                ))}
                {statistics.averageDepositGrowthPerMonth > 0 && (
                  <Col xs={12} md={6}>
                    <p>Avg. monthly deposit growth</p>
                    <Title level={4}>
                      {currencyFormatter.format(
                        statistics.averageDepositGrowthPerMonth,
                      )}
                    </Title>
                  </Col>
                )}
              </Row>
              {plan.property.market && (
                <>
                  <Divider orientation="left" style={{ margin: 0 }}>
                    Affordability of a {plan.property.market.bedrooms} bedroom{' '}
                    {startCase(
                      plan.property.market.structuralType.toLowerCase(),
                    )}{' '}
                    in {plan.property.market.suburb}
                  </Divider>
                  <Row>
                    <Col xs={24}>
                      <Link
                        to="#"
                        onClick={() => setMarketDisclaimerOpen(true)}
                      >
                        How is this calculated?
                      </Link>
                    </Col>
                  </Row>
                  {plan.property.market.targetPrice ? (
                    <>
                      <Row>
                        <>
                          <Col xs={12} md={6}>
                            <p>Median price</p>
                            <Title level={4}>
                              {currencyFormatter.format(
                                plan.property.market.targetPrice,
                              )}
                            </Title>
                          </Col>
                          <Col xs={12} md={6}>
                            <p>Projected date</p>
                            <Title level={4}>
                              {statistics.dateAffordableMarket ||
                                'Not affordable'}
                            </Title>
                          </Col>
                        </>
                      </Row>
                    </>
                  ) : (
                    <Row>
                      <Col xs={24}>
                        <Text type="secondary">
                          Sorry, there is no data based on your selected suburb,
                          bedrooms, and property type.
                        </Text>
                      </Col>
                    </Row>
                  )}
                </>
              )}
              <Row>
                <Col xs={24}>
                  <Radio.Group
                    onChange={e => setToggle(e.target.value)}
                    value={toggle}
                  >
                    <Radio.Button value="GRAPH">Graph</Radio.Button>
                    <Radio.Button value="TABLE">Table</Radio.Button>
                  </Radio.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={24}>
                  <Link to="#" onClick={() => setDisclaimersOpen(true)}>
                    Disclaimers and Assumptions
                  </Link>
                </Col>
              </Row>
              {results.some(r => r.maximumAffordablePrice === 4950000) && (
                <Alert
                  message="Doorstep calculates affordability up to and including a property price of $4,950,000. Any purchase value above this price is not reflected in the information below."
                  type="info"
                />
              )}
              <Row>
                <Col xs={24}>
                  {toggle === 'GRAPH' ? (
                    <Line loading={loading} {...loanConfig} />
                  ) : (
                    <Table
                      loading={loading}
                      columns={
                        [
                          {
                            title: 'Date',
                            dataIndex: 'endDate',
                            fixed: true,
                            width: 100,
                          },
                          {
                            title: 'Maximum Affordable Price',
                            dataIndex: 'maximumAffordablePrice',
                            fixed: false,
                            width: 115,
                          },

                          {
                            title: 'Monthly Repayments',
                            dataIndex: 'repayments',
                            fixed: false,
                            width: 115,
                          },
                          {
                            title: 'Deposit Available',
                            dataIndex: 'deposit',
                            fixed: false,
                            width: 115,
                          },
                          plan.property.minimumDeposit < 0.2
                            ? {
                                title: (
                                  <HelpLabel
                                    tooltip={HelpText.PlanAffordabilityLMI}
                                  >
                                    LMI
                                  </HelpLabel>
                                ),
                                dataIndex: 'lmi',
                                fixed: false,
                                width: 115,
                              }
                            : undefined,
                          {
                            title: (
                              <HelpLabel
                                tooltip={
                                  HelpText.PlanAffordabilityTotalUpfrontFees
                                }
                              >
                                Total Upfront Fees
                              </HelpLabel>
                            ),
                            dataIndex: 'fees',
                            fixed: false,
                            width: 115,
                          },
                          // {
                          //   title: 'Stamp Duty',
                          //   dataIndex: 'stampDuty',
                          //   fixed: false,
                          //   width: 115,
                          // },
                          plan.finance.source === PurchasePlanFinanceSource.SALE
                            ? {
                                title: (
                                  <HelpLabel
                                    tooltip={HelpText.PlanAffordabilitySaleFees}
                                  >
                                    Sale Fees
                                  </HelpLabel>
                                ),
                                dataIndex: 'saleFees',
                                fixed: false,
                                width: 115,
                              }
                            : undefined,
                          // {
                          //   title: (
                          //     <HelpLabel
                          //       tooltip={
                          //         HelpText.PlanAffordabilityHigherInterestRepayments
                          //       }
                          //     >
                          //       Monthly Repayments +3% Interest
                          //     </HelpLabel>
                          //   ),
                          //   dataIndex: 'higherInterestRepayments',
                          //   fixed: false,
                          //   width: 115,
                          // },
                          plan.property.type ===
                          PurchasePlanPropertyType.INVESTMENT
                            ? {
                                title: 'Avg. Monthly Rent Income',
                                dataIndex: 'rent',
                                fixed: false,
                                width: 115,
                              }
                            : undefined,
                          {
                            title: 'Avg. Monthly Income',
                            dataIndex: 'credit',
                            fixed: false,
                            width: 115,
                          },
                          {
                            title: 'Avg. Monthly Expenses',
                            dataIndex: 'debit',
                            fixed: false,
                            width: 115,
                          },
                          results.some(r => r.otherRepayments > 0)
                            ? {
                                title: 'Avg. Monthly Other Loan Repayments',
                                dataIndex: 'otherRepayments',
                                fixed: false,
                                width: 115,
                              }
                            : undefined,
                          {
                            title: (
                              <HelpLabel tooltip={HelpText.PlanAverage}>
                                Avg. Monthly Cash After Repayments
                              </HelpLabel>
                            ),
                            dataIndex: 'netAfterRepayments',
                            fixed: false,
                            width: 115,
                          },
                        ].filter(c => !!c) as any
                      }
                      pagination={false}
                      dataSource={results.map(result => ({
                        ...result,
                        endDate: format(
                          new Date(String(result.endDate).split('T')[0]),
                          'LLL yyyy',
                        ),
                        maximumAffordablePrice: currencyFormatter.format(
                          result.maximumAffordablePrice,
                        ),
                        lmi: currencyFormatter.format(result.lmi),
                        deposit: currencyFormatter.format(result.deposit),
                        repayments: currencyFormatter.format(result.repayments),
                        higherInterestRepayments: currencyFormatter.format(
                          result.higherInterestRepayments,
                        ),
                        credit: currencyFormatter.format(result.credit),
                        debit: currencyFormatter.format(result.debit),
                        otherRepayments: currencyFormatter.format(
                          result.otherRepayments,
                        ),
                        rent: currencyFormatter.format(result.rent),
                        netAfterRepayments: currencyFormatter.format(
                          result.netAfterRepayments,
                        ),
                        fees: currencyFormatter.format(result.fees),
                        stampDuty: currencyFormatter.format(result.stampDuty),
                        saleFees: currencyFormatter.format(result.saleFees),
                      }))}
                      scroll={{ y: 500 }}
                    />
                  )}
                </Col>
              </Row>
            </>
          )}
        </Space>
      </Card>
      <Modal
        title="Disclaimers and Assumptions"
        visible={disclaimersOpen}
        okText="OK"
        closable={false}
        onCancel={() => setDisclaimersOpen(false)}
        onOk={() => setDisclaimersOpen(false)}
      >
        <p>
          Doorstep cannot and does not contain finance advice. The finance
          information is provided for general informational and educational
          purposes only and is not a substitute for professional advice.
          Accordingly, before taking any actions based upon such information, we
          encourage you to consult with the appropriate professionals. We do not
          provide any kind of finance advice. THE USE OR RELIANCE OF ANY
          INFORMATION CONTAINED ON THE SITE IS SOLELY AT YOUR OWN RISK.
        </p>
        <Title level={4}>
          If this plan's finance source is the sale of an investment property
        </Title>
        <p>
          Tax is calculated against the proceeds from the sale of the investment
          property with the following considerations:
        </p>
        <ul>
          <li>
            Your total income for the financial year of the sale is calculated
            as the total taxable credits plus the proceeds from the sale of the
            investment property.
          </li>
          <li>
            A 50% CGT discount is applied if you are selling an investment
            property you had purchased more than 12 months from the dates shown
            in this section.
          </li>
          <li>
            No claimed expenses on the purchase, improvement, and maintenance of
            the property are considered when calculating the total taxable
            capital gains on your investment property.
          </li>
          <li>
            The tax rates are for individual Australian residents and do not
            consider household income for more than a single person.
          </li>
          <li>
            The Medicare levy is calculated as 2% of taxable income for most
            taxpayers. The Medicare levy in this calculator is based on
            individual rates and does not take into account family income or
            dependent children.
          </li>
          <li>
            The tax calculations do not include the Medicare Levy Surcharge
            (1%-1.5%), an additional levy on individuals and families with
            higher incomes who do not have private health insurance.
          </li>
          <li>
            The tax calculations do not take into account any tax rebates or tax
            offsets you may be entitled to.
          </li>
        </ul>
        <Title level={4}>How is my affordability calculated?</Title>
        <ul>
          <li>
            <b>Maximum Affordable Price:</b> Calculated by considering the
            state, status, type, and minimum deposit registered against this
            purchase plan, as well as the loan maximum monthly repayments, if
            provided, sale fees, if provided, and all financial information
            registered in your account to determine your deposit available. The
            deposit available is taken, and upfront fees are deducted, to
            determine the amount of financial ownership in the property. The
            remainder is considered the principal of the loan against your
            property. The deposit you have is divded by the minimum deposit
            percentage to calculate your maximum affordable price. Please note
            that this information does not take into consideration your
            eligibility for a loan to afford a stated purchase price. Please
            consult your financial institution to see if you are eligible for
            such a loan stated below.
          </li>
          <li>
            <b>LMI:</b> Lender's mortgage insurance is calculated against
            deposits less than 20% of the purchase property value. See:{' '}
            <a
              href="https://www.savings.com.au/home-loans/lenders-mortgage-insurance"
              target="_blank"
            >
              https://www.savings.com.au/home-loans/lenders-mortgage-insurance
            </a>
            .
          </li>
          <li>
            <b>Total Upfront Fees:</b> Based on the maximum affordable price,
            the state you are purchasing in, whether you are a first home buyer
            or not, and whether you are purchasing an investment property or a
            primary residence, upfront fees including stamp duty, transfer fees,
            government fees apply. This field includes all applicable fees.
          </li>
          <li>
            <b>Stamp Duty:</b> Based on the maximum affordable price, the state
            you are purchasing in, whether you are a first home buyer or not,
            and whether you are purchasing an investment property or a primary
            residence, upfront fees including stamp duty apply. This field only
            includes stamp duty.
          </li>
          <li>
            <b>Deposit Available:</b> This field is calculated differently based
            on the finance source of this purchase plan:
            <ul>
              <li>
                <b>Cash:</b> Considering your credits, debits, and loans
                registered in your account, a cash deposit amount is calculated
                based on the projected amount of savings you will have, minus
                the cash reserve for this plan.
              </li>
              <li>
                <b>Equity:</b> Considering your source property and loan and the
                parameters of that loan, an equity deposit amount is calculated
                based on 80% of the projected value of the source property,
                minus the outstanding principal at the time of purchase. From
                this value, X% is removed based on the equity buffer provided to
                leave you with the equity deposit available.
              </li>
              <li>
                <b>Equity and Cash:</b> Please read how cash and equity deposits
                are calculated. This is a sum of both amounts.
              </li>
              <li>
                <b>Sale:</b> Considering your source property and loan and the
                parameteres of that loan, a sale deposit amount is calculated
                based on the projected value of the source property, minus the
                remaining principal at the time of purchase, minus any tax if it
                is an investment property, minus the sale fees registered
                against the purchase plan, plus any savings registered against
                your account, minus the cash reserve against this purchase plan.
              </li>
            </ul>
          </li>
          <li>
            <b>Sale Fees:</b> Multiples the percentage of agent fees against the
            maximum affordable price and adds all other fixed fees registered
            against this plan.
          </li>
          <li>
            <b>Repayments Per Month:</b> Considering the principal of the loan
            being the maximum affordable price, plus upfront fees, minus deposit
            available, this field is calculated assuming a 30 year variable loan
            with the interest rate registered against this plan, 0% offset,
            monthly repayments starting from the date of purchase.
          </li>
          <li>
            <b>Repayments Per Month +3% Interest:</b> This is calculated exactly
            the same as repayments per month, but considers an interest rate
            that is +3% higher than the interest rate registered against this
            purchase plan.
          </li>
          <li>
            <b>Purchase Price Today:</b> Considering the growth rate per annum
            registered against this purchase plan, this field calculates the
            purchase price of the same property value today. This is an estimate
            only.
          </li>
        </ul>
      </Modal>
      <Modal
        title="How is this market's price and date calculated?"
        visible={marketDisclaimerOpen}
        okText="OK"
        closable={false}
        onCancel={() => setMarketDisclaimerOpen(false)}
        onOk={() => setMarketDisclaimerOpen(false)}
      >
        <p>
          Doorstep cannot and does not contain finance advice. The finance
          information is provided for general informational and educational
          purposes only and is not a substitute for professional advice.
          Accordingly, before taking any actions based upon such information, we
          encourage you to consult with the appropriate professionals. We do not
          provide any kind of finance advice. THE USE OR RELIANCE OF ANY
          INFORMATION CONTAINED ON THE SITE IS SOLELY AT YOUR OWN RISK.
        </p>
        <Title level={4}>How is this market's median price calculated?</Title>
        <p>
          Based on the suburb, bedrooms, and property type selected against this
          purchase plan, suburb profile data based on the last 5 years has been
          retrieved, filtered through, and averaged to determine the estimated
          latest median price for your target purchase.
        </p>
        <p>
          This data is kept as up to date as possible, but is not guaranteed to
          be completely up to date, nor completely accurate.
        </p>
        <p>
          For an example of the source data, please follow this link:{' '}
          <a
            href="https://www.domain.com.au/suburb-profile/fitzroy-vic-3065"
            target="_blank"
          >
            https://www.domain.com.au/suburb-profile/fitzroy-vic-3065
          </a>
        </p>
        <Title level={4}>How is this the projected date calculated?</Title>
        <p>
          This date is reflective of when Doorstep projects you to be able to
          purchase the median price for your suburb, bedrooms, and property
          type. This does not take into account this median price increasing or
          decreasing with the market over time.
        </p>
        <p>
          To calculate this date, Doorstep takes into account your cash
          accounts, credits, debits, property equity, property growth rate, and
          loans/loan repayments and calculates your affordability and finds the
          date when your maximum affordable price equals the median price for
          this market. Please see the Disclaimers and Assumptions on the
          affordability graph and table to understand how affordability is
          calculated.
        </p>
        <p>
          If your projected date is 'Not affordable', this means that your
          maximum affordable price does not reach the median price for this
          market.
        </p>
        <p>
          Please note that you should conduct your own research and consider
          consulting a professional to determine the actual market prices and
          your affordability of such prices.
        </p>
      </Modal>
    </>
  )
})

export default PurchaseAffordabilityResults
