import * as auth from 'firebase/auth'
import { navigate } from '@reach/router'
import { Routes } from './routes'

export interface Route {
  route: string
  method: string
  headers?: {
    [key: string]: any
  }
}

export type RouteFunction = (...params: any[]) => Route

export const APIRoute = {
  SendUserVerificationEmail: () => ({
    route: 'user/send-verification',
    method: 'POST',
  }),
  SendUserWelcomeEmail: () => ({
    route: 'user/send-welcome',
    method: 'POST',
  }),
  GetCredits: () => ({
    route: 'credits',
    method: 'GET',
  }),
  CreateCredit: () => ({
    route: 'credits',
    method: 'POST',
  }),
  UpdateCredit: (id?: string) => ({
    route: `credits/${id}`,
    method: 'PUT',
  }),
  DeleteCredit: (id?: string) => ({
    route: `credits/${id}`,
    method: 'DELETE',
  }),
  GetDebits: () => ({
    route: 'debits',
    method: 'GET',
  }),
  CreateDebit: () => ({
    route: 'debits',
    method: 'POST',
  }),
  UpdateDebit: (id?: string) => ({
    route: `debits/${id}`,
    method: 'PUT',
  }),
  DeleteDebit: (id?: string) => ({
    route: `debits/${id}`,
    method: 'DELETE',
  }),
  GetCashAccounts: () => ({
    route: 'cash-accounts',
    method: 'GET',
  }),
  CreateCashAccount: () => ({
    route: 'cash-accounts',
    method: 'POST',
  }),
  UpdateCashAccount: (id?: string) => ({
    route: `cash-accounts/${id}`,
    method: 'PUT',
  }),
  DeleteCashAccount: (id?: string) => ({
    route: `cash-accounts/${id}`,
    method: 'DELETE',
  }),
  GetCreditDebitOverview: () => ({
    route: 'finances/credits-debits',
    method: 'GET',
  }),
  GetProperties: () => ({
    route: 'properties',
    method: 'GET',
  }),
  GetProperty: (id?: string) => ({
    route: `properties/${id}`,
    method: 'GET',
  }),
  UpdateProperty: (id?: string) => ({
    route: `properties/${id}`,
    method: 'PUT',
  }),
  DeleteProperty: (id?: string) => ({
    route: `properties/${id}`,
    method: 'DELETE',
  }),
  GetAddressEstimates: (address?: string) => ({
    route: `properties/address/estimates?address=${address}`,
    method: 'GET',
  }),
  GetLoans: (propertyID?: string) => ({
    route: `properties/${propertyID}/loans`,
    method: 'GET',
  }),
  GetLoanForecast: (propertyID?: string, id?: string) => ({
    route: `properties/${propertyID}/loans/${id}/forecast`,
    method: 'GET',
  }),
  CreateLoan: (propertyID?: string) => ({
    route: `properties/${propertyID}/loans`,
    method: 'POST',
  }),
  UpdateLoan: (propertyID?: string, id?: string) => ({
    route: `properties/${propertyID}/loans/${id}`,
    method: 'PUT',
  }),
  DeleteLoan: (propertyID?: string, id?: string) => ({
    route: `properties/${propertyID}/loans/${id}`,
    method: 'DELETE',
  }),
  CopyLoan: (propertyID?: string, id?: string) => ({
    route: `properties/${propertyID}/loans/${id}/copy`,
    method: 'POST',
  }),
  GetLoanVariants: (id?: string) => ({
    route: `loans/${id}/variants`,
    method: 'GET',
  }),
  CreateLoanVariant: (id?: string) => ({
    route: `loans/${id}/variants`,
    method: 'POST',
  }),
  UpdateLoanVariant: (loanId?: string, id?: string) => ({
    route: `loans/${loanId}/variants/${id}`,
    method: 'PUT',
  }),
  DeleteLoanVariant: (loanId?: string, id?: string) => ({
    route: `loans/${loanId}/variants/${id}`,
    method: 'DELETE',
  }),
  GetExtraRepayments: (id?: string) => ({
    route: `loans/${id}/extra-repayments`,
    method: 'GET',
  }),
  CreateExtraRepayment: (id?: string) => ({
    route: `loans/${id}/extra-repayments`,
    method: 'POST',
  }),
  UpdateExtraRepayment: (loanId?: string, id?: string) => ({
    route: `loans/${loanId}/extra-repayments/${id}`,
    method: 'PUT',
  }),
  DeleteExtraRepayment: (loanId?: string, id?: string) => ({
    route: `loans/${loanId}/extra-repayments/${id}`,
    method: 'DELETE',
  }),
  GetRentPeriods: (id?: string) => ({
    route: `loans/${id}/rent-periods`,
    method: 'GET',
  }),
  CreateRentPeriod: (id?: string) => ({
    route: `loans/${id}/rent-periods`,
    method: 'POST',
  }),
  UpdateRentPeriod: (loanId?: string, id?: string) => ({
    route: `loans/${loanId}/rent-periods/${id}`,
    method: 'PUT',
  }),
  DeleteRentPeriod: (loanId?: string, id?: string) => ({
    route: `loans/${loanId}/rent-periods/${id}`,
    method: 'DELETE',
  }),
  GetResults: (id?: string) => ({
    route: `loans/${id}/results`,
    method: 'GET',
  }),
  CompareResults: (
    propertyID?: string,
    dates?: { startDate: string; endDate: string },
  ) => ({
    route: `properties/${propertyID}/loans/compare${
      dates ? `?startDate=${dates.startDate}&endDate=${dates.endDate}` : ''
    }`,
    method: 'GET',
  }),
  GetPurchasePlans: () => ({
    route: 'purchase-plans',
    method: 'GET',
  }),
  CreatePurchasePlan: () => ({
    route: 'purchase-plans',
    method: 'POST',
  }),
  GetPurchasePlan: (id?: string) => ({
    route: `purchase-plans/${id}`,
    method: 'GET',
  }),
  UpdatePurchasePlan: (id?: string) => ({
    route: `purchase-plans/${id}`,
    method: 'PUT',
  }),
  DeletePurchasePlan: (id?: string) => ({
    route: `purchase-plans/${id}`,
    method: 'DELETE',
  }),
  GetPurchasePlanAffordability: (id?: string) => ({
    route: `purchase-plans/${id}/affordability`,
    method: 'GET',
  }),
  GetPurchasePlanForecast: (id?: string) => ({
    route: `purchase-plans/${id}/forecast`,
    method: 'GET',
  }),
  GetPropertyEstimations: (address?: string) => ({
    route: `properties/estimations?address=${address}`,
    method: 'GET',
  }),
  GetAddresses: (query?: string) => ({
    route: `https://addressr.p.rapidapi.com/addresses?q=${query}`,
    method: 'GET',
    headers: {
      'x-rapidapi-host': 'addressr.p.rapidapi.com',
      'x-rapidapi-key': '4dceb772c6msh9b68f80068eb529p18df28jsnf43cf455a78a',
    },
  }),
  CreateProperty: () => ({
    route: 'properties',
    method: 'POST',
  }),
  GetProducts: () => ({
    route: 'products',
    method: 'GET',
  }),
  Checkout: () => ({
    route: 'customers/checkout',
    method: 'POST',
  }),
  Customer: () => ({
    route: 'customers',
    method: 'GET',
  }),
}

export const BASE_URL = process.env.REACT_APP_API || 'http://localhost:8080'

export async function query<T>(
  { route, method, headers }: Route,
  body?: object,
): Promise<T> {
  const user = ((window as any).globalAuth as auth.Auth).currentUser
  const userID = await user?.getIdToken(true)

  const response = await fetch(
    route.includes('http') ? route : `${BASE_URL}/${route}`,
    {
      method,
      headers: {
        'content-type': 'application/json',
        ...(userID
          ? {
              Authorization: `Bearer ${userID}`,
            }
          : {}),
        ...headers,
      },
      body: body && JSON.stringify(body),
    },
  )

  const responseBody = await response.json()

  if (
    response.status === 401 &&
    (responseBody.error === 'NoSubscriptionError' ||
      responseBody.error === 'AccessPeriodEndedError')
  ) {
    navigate(Routes.PLANS)
  }

  if (response.status === 401 && responseBody.error === 'UnauthorisedError') {
    navigate(Routes.LOGOUT)
  }

  return responseBody
}
