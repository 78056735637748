import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Space,
  message,
  List,
  DatePicker,
  Tooltip,
  Spin,
  Select,
} from 'antd'
import { createRef, useEffect, useState } from 'react'
import { useLazyQuery } from '../hooks/useLazyQuery'
import { APIRoute } from '../network'
import DollarInput, { DollarInputRules } from './DollarInput'
import {
  Property,
  PropertyType,
  PropertyValueAdjustment,
} from '../../../shared/types/Property'
import { EditOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons'
import PercentageInput from './PercentageInput'
import { HelpLabel } from './HelpLabel'
import { HelpText } from '../HelpText'
import moment from 'moment'

type PropertyFormValues = Pick<
  Property,
  | 'address'
  | 'price'
  | 'estimatedRentPerWeek'
  | 'estimatedGrowthPerAnnum'
  | 'type'
> & {
  purchaseDate: moment.Moment
  valueAdjustments: Array<
    Omit<PropertyValueAdjustment, 'date'> & {
      date: moment.Moment
    }
  >
}

const getFormValues = (data: Property): PropertyFormValues => ({
  type: data.type,
  address: data.address,
  purchaseDate: moment(data.purchaseDate),
  price: data.price,
  estimatedRentPerWeek: data.estimatedRentPerWeek,
  estimatedGrowthPerAnnum: Number(
    (data.estimatedGrowthPerAnnum * 100).toFixed(2),
  ),
  valueAdjustments: data.valueAdjustments
    ? data.valueAdjustments.map(adjustment => ({
        ...adjustment,
        date: moment(adjustment.date),
      }))
    : [],
})

const PropertyForm: React.FC<{
  property: Property
  onUpdate: (plan: Property) => void
}> = ({ property, onUpdate }) => {
  const [form] = Form.useForm<PropertyFormValues>()
  const [formOpen, setFormOpen] = useState(false)
  const [formUpdated, setFormUpdated] = useState(0)
  const [autocomplete, setAutocomplete] = useState(undefined)
  const addressRef = createRef<Input>()

  const { makeQuery, data, loading, error } = useLazyQuery<Property>(
    APIRoute.UpdateProperty,
  )
  const {
    makeQuery: getEstimates,
    data: estimatesData,
    loading: estimatesLoading,
  } = useLazyQuery<{
    price?: number
    estimatedRentPerWeek?: number
    estimatedGrowthPerAnnum?: number
    suburb?: string
    propertyCategory?: string
    beds?: number
    dateOfPurchase?: Date
  }>(APIRoute.GetAddressEstimates)

  useEffect(() => {
    if (data) {
      message.success('Saved')
      form.setFieldsValue(getFormValues(data))
      setFormOpen(false)
      onUpdate(data)
    }
  }, [data])

  useEffect(() => {
    form.resetFields()
    setFormUpdated(formUpdated + 1)
  }, [error])

  useEffect(() => {
    if (addressRef?.current?.input && !autocomplete && formOpen) {
      const address = document.getElementById('address')

      if (!address) {
        return
      }

      const a = new (window as any).google.maps.places.Autocomplete(address, {
        componentRestrictions: { country: 'au' },
      })

      a.addListener('place_changed', () => {
        const address = a.getPlace().formatted_address

        form.setFieldsValue({
          address,
        })
        getEstimates(undefined, address)
      })

      setAutocomplete(a)
    }
  }, [addressRef])

  useEffect(() => {
    if (estimatesData) {
      form.setFieldsValue({
        purchaseDate: estimatesData.dateOfPurchase
          ? moment(estimatesData.dateOfPurchase)
          : undefined,
        price: estimatesData.price,
        estimatedRentPerWeek: estimatesData.estimatedRentPerWeek || 0,
        estimatedGrowthPerAnnum: estimatesData.estimatedGrowthPerAnnum || 0,
      })
    }
  }, [estimatesData])

  const fieldDisabled = loading || !formOpen || estimatesLoading

  return (
    <Form
      form={form}
      layout="vertical"
      name="property-overview"
      autoComplete="off"
      requiredMark="optional"
      initialValues={getFormValues(property)}
      onFinish={async values => {
        await makeQuery(
          {
            type: values.type,
            address: values.address,
            purchaseDate: new Date(
              values.purchaseDate.format('yyyy-MM-DD'),
            ).toISOString(),
            price: values.price,
            estimatedRentPerWeek: values.estimatedRentPerWeek,
            estimatedGrowthPerAnnum:
              typeof values.estimatedGrowthPerAnnum === 'number'
                ? values.estimatedGrowthPerAnnum * 0.01
                : null,
            valueAdjustments: values.valueAdjustments.map(adjustment => ({
              ...adjustment,
              date: new Date(
                adjustment.date.format('yyyy-MM-DD'),
              ).toISOString(),
            })),
          },
          property.id,
        )
      }}
    >
      <Card
        title="Overview"
        bordered={false}
        className={`form-card${formOpen ? '' : ' view-mode'}`}
        extra={
          <Button
            onClick={() => setFormOpen(true)}
            shape="circle"
            icon={<EditOutlined />}
            htmlType="button"
            disabled={formOpen}
          />
        }
      >
        <Spin
          spinning={estimatesLoading}
          tip="Fetching data on your property. This might take a few seconds."
        >
          <Space direction="vertical" size="middle" style={{ width: '100%' }}>
            {error && (
              <Row>
                <Col xs={24}>
                  <Alert
                    message="Error"
                    description="There was an issue saving this property. Please try again later."
                    type="error"
                    showIcon
                  />
                </Col>
              </Row>
            )}
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} md={12}>
                <Form.Item
                  label="Address"
                  name="address"
                  style={{ margin: '0' }}
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Please input address',
                    },
                  ]}
                >
                  <Input
                    size="large"
                    disabled={fieldDisabled}
                    ref={addressRef}
                    id="address"
                  />
                </Form.Item>
              </Col>
            </Row>
            {estimatesData && (
              <Row>
                <Col xs={24}>
                  <Alert
                    message={`The following fields have been pre populated based on data retrieved about your property address. ${
                      estimatesData.estimatedRentPerWeek &&
                      estimatesData.estimatedGrowthPerAnnum
                        ? `The rent per week and growth per annum are based on a ${
                            estimatesData.beds
                          } bedroom ${estimatesData.propertyCategory?.toLowerCase()} in ${
                            estimatesData.suburb
                          }.`
                        : ''
                    }`}
                    type="info"
                    showIcon
                  />
                </Col>
              </Row>
            )}
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} md={4}>
                <Form.Item
                  label="Type"
                  name="type"
                  style={{ margin: '0' }}
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Please select a type',
                    },
                  ]}
                >
                  <Select
                    size="large"
                    disabled={fieldDisabled}
                    onChange={() => setFormUpdated(formUpdated + 1)}
                  >
                    <Select.Option value={PropertyType.PRIMARY_RESIDENCE}>
                      Primary Residence
                    </Select.Option>
                    <Select.Option value={PropertyType.INVESTMENT}>
                      Investment
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={4}>
                <Form.Item
                  label="Purchase Date"
                  name="purchaseDate"
                  style={{ margin: '0' }}
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Please input a date',
                    },
                  ]}
                >
                  <DatePicker
                    format="DD/MM/YYYY"
                    size="large"
                    style={{ width: '100%' }}
                    placeholder=""
                    disabled={fieldDisabled}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={4}>
                <Form.Item
                  label={
                    <HelpLabel tooltip={HelpText.PropertyPurchasePrice}>
                      Purchase Price
                    </HelpLabel>
                  }
                  name="price"
                  style={{ margin: '0' }}
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Please input an amount',
                    },
                  ]}
                >
                  <DollarInput disabled={fieldDisabled} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={5}>
                <Form.Item
                  label={
                    <HelpLabel tooltip={HelpText.PropertyEstimatedRent}>
                      Estimated Rent Per Week
                    </HelpLabel>
                  }
                  name="estimatedRentPerWeek"
                  style={{ margin: '0' }}
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Please input an amount',
                    },
                  ]}
                >
                  <DollarInput disabled={fieldDisabled} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={5}>
                <Form.Item
                  label={
                    <HelpLabel tooltip={HelpText.PropertyEstimatedGrowth}>
                      Estimated Growth Per Annum
                    </HelpLabel>
                  }
                  name="estimatedGrowthPerAnnum"
                  style={{ margin: '0' }}
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Please input a percentage',
                    },
                    {
                      type: 'number',
                      min: 0,
                      message: 'Please input a percentage',
                    },
                  ]}
                >
                  <PercentageInput disabled={fieldDisabled} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <>
                <Col xs={12} sm={4}>
                  <Tooltip title={HelpText.PropertyValueAdjustments}>
                    <h3
                      style={{
                        margin: '0.5rem 0',
                        textDecorationStyle: 'dotted',
                        textDecorationLine: 'underline',
                        width: 'auto',
                      }}
                    >
                      Value Adjustments
                    </h3>
                  </Tooltip>
                </Col>
                <Col xs={24}>
                  <Form.List name="valueAdjustments">
                    {(valueAdjustments, { add, remove }) => (
                      <>
                        <List
                          itemLayout="horizontal"
                          dataSource={valueAdjustments}
                          renderItem={adjustment => (
                            <Space
                              key={adjustment.key}
                              direction="vertical"
                              size="small"
                              style={{ width: '100%', marginBottom: '0.5rem' }}
                            >
                              <Row gutter={[16, 16]}>
                                <Col xs={24} sm={12} md={4}>
                                  <Form.Item
                                    label="Date"
                                    required
                                    name={[adjustment.name, 'date']}
                                    fieldKey={[adjustment.fieldKey, 'date']}
                                    style={{ margin: '0' }}
                                  >
                                    <DatePicker
                                      format="DD/MM/YYYY"
                                      size="large"
                                      style={{ width: '100%' }}
                                      placeholder=""
                                      disabled={fieldDisabled}
                                      disabledDate={current => {
                                        const purchaseDate = form.getFieldValue(
                                          ['purchaseDate'],
                                        )

                                        return (
                                          current &&
                                          purchaseDate &&
                                          current < purchaseDate
                                        )
                                      }}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={5}>
                                  <Form.Item
                                    label="Amount"
                                    name={[adjustment.name, 'amount']}
                                    fieldKey={[adjustment.fieldKey, 'amount']}
                                    style={{ margin: '0' }}
                                    required
                                    rules={[
                                      ...DollarInputRules,
                                      {
                                        required: true,
                                        message: 'Please input an amount',
                                      },
                                    ]}
                                  >
                                    <DollarInput disabled={fieldDisabled} />
                                  </Form.Item>
                                </Col>
                                {formOpen && (
                                  <Col
                                    xs={3}
                                    md={1}
                                    className="plan-debit-remove"
                                  >
                                    <Button
                                      shape="circle"
                                      size="small"
                                      icon={<MinusOutlined />}
                                      onClick={() => {
                                        remove(adjustment.name)
                                      }}
                                    />
                                  </Col>
                                )}
                              </Row>
                            </Space>
                          )}
                        />
                        {formOpen && (
                          <Button
                            type="primary"
                            size="large"
                            onClick={() => add({})}
                            icon={<PlusOutlined />}
                            style={{ marginTop: '1rem' }}
                          >
                            Add Adjustment
                          </Button>
                        )}
                      </>
                    )}
                  </Form.List>
                </Col>
              </>
            </Row>
            {formOpen && (
              <Row>
                <Col xs={12} sm={12} md={3} style={{ display: 'flex' }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    loading={loading}
                    style={{ marginRight: '1rem' }}
                  >
                    Save
                  </Button>
                  <Button
                    onClick={() => {
                      form.resetFields()
                      setFormOpen(false)
                    }}
                    disabled={loading}
                    htmlType="button"
                    size="large"
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            )}
          </Space>
        </Spin>
      </Card>
    </Form>
  )
}

export default PropertyForm
