import { InputNumber, InputNumberProps } from 'antd'
import { Rule } from 'antd/lib/form'

const PercentageInput: React.FC<InputNumberProps<string>> = props => {
  return (
    <InputNumber<string>
      {...props}
      size="large"
      formatter={value => `${value}%`}
      parser={value => (value ? value.replace('%', '') : '0')}
      style={{ width: '100%' }}
    />
  )
}

export const PercentageInputRules: Rule[] = [
  {
    type: 'number',
    min: 0.01,
    message: 'Please input a percentage greater than 0%',
  },
]

export default PercentageInput
