import { RouteComponentProps } from '@reach/router'
import { Helmet } from 'react-helmet'
import { Alert, Col, Row, Skeleton, Space, message, Button } from 'antd'
import { useEffect, useState } from 'react'
import { CashAccount } from '../../../shared/types/CashAccount'
import CashAccountCreateForm from '../components/CashAccountCreateForm'
import CashAccountForm from '../components/CashAccountForm'
import { useLazyQuery } from '../hooks/useLazyQuery'
import { APIRoute } from '../network'

const CashAccountList: React.FC<RouteComponentProps> = () => {
  const [cashAccounts, setCashAccounts] = useState<CashAccount[] | undefined>(
    undefined,
  )
  const [createOpen, setCreateOpen] = useState(false)

  const {
    makeQuery: getCashAccounts,
    data: cashAccountsData,
    loading: cashAccountsLoading,
    error: cashAccountsError,
  } = useLazyQuery<CashAccount[]>(APIRoute.GetCashAccounts)
  const {
    makeQuery: createCashAccount,
    data: createData,
    error: createError,
  } = useLazyQuery<CashAccount>(APIRoute.CreateCashAccount)
  const {
    makeQuery: updateCashAccount,
    data: updateData,
    error: updateError,
  } = useLazyQuery<CashAccount>(APIRoute.UpdateCashAccount)
  const {
    makeQuery: deleteCashAccount,
    data: deleteData,
    error: deleteError,
  } = useLazyQuery<CashAccount>(APIRoute.DeleteCashAccount)

  useEffect(() => {
    getCashAccounts()
  }, [])

  useEffect(() => {
    if (cashAccountsData) {
      setCashAccounts(cashAccountsData)
    }
  }, [cashAccountsData])

  useEffect(() => {
    if (createData) {
      message.success('Saved')
      setCreateOpen(false)

      setCashAccounts([...(cashAccounts || []), createData])
    }
  }, [createData])

  useEffect(() => {
    if (updateData && cashAccounts) {
      message.success('Saved')

      const cashAccountIndex = cashAccounts.findIndex(
        cashAccount => cashAccount.id === updateData.id,
      )

      if (cashAccountIndex !== -1) {
        setCashAccounts([
          ...cashAccounts.slice(0, cashAccountIndex),
          updateData,
          ...cashAccounts.slice(cashAccountIndex + 1),
        ])
      }
    }
  }, [updateData])

  useEffect(() => {
    if (deleteData && cashAccounts) {
      message.success('Deleted')

      const cashAccountIndex = cashAccounts.findIndex(
        cashAccount => cashAccount.id === deleteData.id,
      )

      if (cashAccountIndex !== -1) {
        setCashAccounts([
          ...cashAccounts.slice(0, cashAccountIndex),
          ...cashAccounts.slice(cashAccountIndex + 1),
        ])
      }
    }
  }, [deleteData])

  return (
    <>
      <Helmet>
        <title>Cash Accounts - Doorstep</title>
      </Helmet>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Skeleton loading={cashAccountsLoading} active />
        {cashAccountsError && (
          <Alert
            message="Error"
            description="There was an issue getting your cash accounts. Please try again later."
            type="error"
            showIcon
          />
        )}
        {createError && (
          <Alert
            message="Error"
            description="There was an issue creating your cash account. Please try again later."
            type="error"
            showIcon
          />
        )}
        {updateError && (
          <Alert
            message="Error"
            description="There was an issue updating your cash account. Please try again later."
            type="error"
            showIcon
          />
        )}
        {deleteError && (
          <Alert
            message="Error"
            description="There was an issue deleting your cash account. Please try again later."
            type="error"
            showIcon
          />
        )}
        {cashAccounts && (
          <>
            {cashAccounts.map(cashAccount => (
              <Row key={cashAccount.id}>
                <Col xs={24}>
                  <CashAccountForm
                    cashAccount={cashAccount}
                    onUpdate={values =>
                      updateCashAccount(
                        {
                          ...values,
                          date: new Date(
                            values.date.format('yyyy-MM-DD'),
                          ).toISOString(),
                        },
                        cashAccount.id,
                      )
                    }
                    onDelete={() =>
                      deleteCashAccount(undefined, cashAccount.id)
                    }
                  />
                </Col>
              </Row>
            ))}
          </>
        )}
        {!cashAccountsLoading && !createOpen && cashAccounts?.length === 0 && (
          <Button
            size="large"
            type="primary"
            onClick={() => setCreateOpen(true)}
          >
            Add Savings Balance
          </Button>
        )}
        {createOpen && (
          <CashAccountCreateForm
            onCreate={values =>
              createCashAccount({
                ...values,
                date: new Date(values.date.format('yyyy-MM-DD')).toISOString(),
              })
            }
            onCancel={() => setCreateOpen(false)}
          />
        )}
      </Space>
    </>
  )
}

export default CashAccountList
