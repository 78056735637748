export class Credit {
  constructor(
    public userID: string,
    public id: string,
    public type: CreditType,
    public name: string,
    public amount: number,
    public frequency: CreditFrequency,
    public startDate: Date,
    public endDate?: Date,
    public annualGrowthPercentage?: number,
    public maximumAmount?: number
  ) {}
}

export enum CreditType {
  // amount is total before tax for the year, frequency is how often payments occur. Maximum amount is total before tax for the year.
  ANNUAL_SALARY = "ANNUAL_SALARY",
  // amount is per frequency
  WAGES = "WAGES",
  // amount is per frequency
  OTHER_TAXABLE_INCOME = "OTHER_TAXABLE_INCOME",
  // amount is per frequency
  NON_TAXABLE_INCOME = "NON_TAXABLE_INCOME",
}

export enum CreditFrequency {
  WEEKLY = "WEEKLY",
  FORTNIGHTLY = "FORTNIGHTLY",
  MONTHLY = "MONTHLY",
  YEARLY = "YEARLY",
  ONCE_OFF = "ONCE_OFF",
}
