import { navigate, RouteComponentProps } from '@reach/router'
import { useContext, useEffect } from 'react'
import {
  Alert,
  Button,
  Card,
  Col,
  Divider,
  Layout,
  Row,
  Skeleton,
  Space,
  Spin,
  Timeline,
} from 'antd'
import { Helmet } from 'react-helmet'
import { APIRoute } from '../network'
import { useLazyQuery } from '../hooks/useLazyQuery'
import { Product } from '../../../shared/types/Product'
import {
  CustomerCheckoutCreateResponse,
  CustomerGetResponse,
} from '../../../shared/types/Customer'
import { currencyFormatter } from '../util'
import Title from 'antd/lib/typography/Title'
import Text from 'antd/lib/typography/Text'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { UserContext } from '../components/UserContext'

const PlanSelect: React.FC<RouteComponentProps> = () => {
  const user = useContext(UserContext)

  const { makeQuery, data, loading, error } = useLazyQuery<Product[]>(
    APIRoute.GetProducts,
  )
  const {
    makeQuery: getCheckoutUrl,
    data: checkoutUrlData,
    loading: checkoutLoading,
    error: checkoutError,
  } = useLazyQuery<CustomerCheckoutCreateResponse>(APIRoute.Checkout)

  const { makeQuery: customerQuery, data: customerData } =
    useLazyQuery<CustomerGetResponse>(APIRoute.Customer)

  useEffect(() => {
    makeQuery()
    customerQuery()
  }, [])

  useEffect(() => {
    if (checkoutUrlData) {
      window.location.assign(checkoutUrlData.url)
    }
  }, [checkoutUrlData])

  return (
    <>
      <Helmet>
        <title>Select a plan - Doorstep</title>
      </Helmet>
      <Layout>
        <Layout.Header
          style={{
            background: 'none',
            display: 'flex',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              marginRight: '3rem',
            }}
          >
            <img src="/logo-dark.png" height="24px" />
          </div>
          <div style={{ marginLeft: 'auto' }}>
            <Text>
              {user?.displayName ? `Welcome, ${user.displayName}` : 'Welcome'}
            </Text>
            <Button
              size="large"
              style={{ marginLeft: '1rem' }}
              onClick={() => navigate('/logout')}
            >
              Logout
            </Button>
          </div>
        </Layout.Header>
        <Layout.Content
          style={{
            padding: '1rem',
            maxWidth: '1000px',
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <Space direction="vertical" size="middle" style={{ width: '100%' }}>
            <Title style={{ textAlign: 'center' }} level={2}>
              Select a plan
            </Title>
            {error && (
              <Alert
                message="Error"
                description="There was an issue getting the list of plans. Please try again later."
                type="error"
                showIcon
              />
            )}
            {checkoutError && (
              <Alert
                message="Error"
                description="There was an issue selecting your plan. Please try again."
                type="error"
                showIcon
              />
            )}
            {loading && <Skeleton />}
            {data && (
              <Spin spinning={checkoutLoading}>
                <Row gutter={4}>
                  <Col
                    xs={24}
                    style={{ textAlign: 'center', marginBottom: '1rem' }}
                  >
                    {customerData?.isFreeTrial
                      ? 'Your free trial has expired. To continue using Doorstep, please select a plan below.'
                      : 'Your plan has ended. To continue using Doorstep, please select another plan below.'}
                  </Col>
                </Row>
                <Row gutter={4}>
                  {data
                    .filter(product => product.prices.length > 0)
                    .map(product => (
                      <Col xs={24} sm={6} key={product.id}>
                        <Card
                          style={{
                            height: '100%',
                            textAlign: 'center',
                          }}
                        >
                          <Space direction="vertical" size="middle">
                            <Title level={3} style={{ margin: 0 }}>
                              {product.name}
                            </Title>
                            {product.description && (
                              <Text type="secondary">
                                {product.description}
                              </Text>
                            )}
                            <div>
                              <Title
                                level={1}
                                style={{
                                  marginRight: '0.5rem',
                                  marginBottom: 0,
                                }}
                              >
                                {
                                  currencyFormatter
                                    .format(product.prices[0].unitAmount / 100)
                                    .split('.')[0]
                                }{' '}
                              </Title>
                              <div>
                                <Text type="secondary">
                                  {product.prices[0].currency.toUpperCase()}
                                </Text>
                                {product.prices[0].interval && (
                                  <Text type="secondary">
                                    /{product.prices[0].interval}
                                  </Text>
                                )}
                              </div>
                            </div>
                            <Button
                              size="large"
                              type="primary"
                              onClick={() =>
                                getCheckoutUrl({
                                  lineItems: [
                                    {
                                      price: product.prices[0].id,
                                      quantity: 1,
                                    },
                                  ],
                                  oneTimePayment:
                                    product.prices[0].type === 'one_time',
                                })
                              }
                              disabled={checkoutLoading}
                            >
                              Select Plan
                            </Button>
                            {product.prices[0].type === 'one_time' ? null : (
                              <>
                                <Divider />
                                <Title level={4} style={{ textAlign: 'left' }}>
                                  Features
                                </Title>
                                <Timeline style={{ textAlign: 'left' }}>
                                  <Timeline.Item
                                    color="green"
                                    dot={<CheckCircleOutlined />}
                                  >
                                    Manage income
                                  </Timeline.Item>
                                  <Timeline.Item
                                    color="green"
                                    dot={<CheckCircleOutlined />}
                                  >
                                    Manage expenses
                                  </Timeline.Item>
                                  <Timeline.Item
                                    color="green"
                                    dot={<CheckCircleOutlined />}
                                  >
                                    Manage cash balance
                                  </Timeline.Item>
                                  <Timeline.Item
                                    color="green"
                                    dot={<CheckCircleOutlined />}
                                  >
                                    Create cash purchase plans
                                  </Timeline.Item>
                                  <Timeline.Item
                                    color={
                                      product.metadata.id === 'premium'
                                        ? 'green'
                                        : 'red'
                                    }
                                    dot={
                                      product.metadata.id === 'premium' ? (
                                        <CheckCircleOutlined />
                                      ) : (
                                        <CloseCircleOutlined />
                                      )
                                    }
                                  >
                                    Create equity purchase plans
                                  </Timeline.Item>
                                  <Timeline.Item
                                    color={
                                      product.metadata.id === 'premium'
                                        ? 'green'
                                        : 'red'
                                    }
                                    dot={
                                      product.metadata.id === 'premium' ? (
                                        <CheckCircleOutlined />
                                      ) : (
                                        <CloseCircleOutlined />
                                      )
                                    }
                                  >
                                    Create purchase plans from sale of property
                                  </Timeline.Item>
                                  <Timeline.Item
                                    color="green"
                                    dot={<CheckCircleOutlined />}
                                  >
                                    Analyse purchase affordability
                                  </Timeline.Item>
                                  <Timeline.Item
                                    color="green"
                                    dot={<CheckCircleOutlined />}
                                  >
                                    Set purchase targets
                                  </Timeline.Item>
                                  <Timeline.Item
                                    color="green"
                                    dot={<CheckCircleOutlined />}
                                  >
                                    Forecast finances for purchases
                                  </Timeline.Item>
                                  <Timeline.Item
                                    color={
                                      product.metadata.id === 'premium'
                                        ? 'green'
                                        : 'red'
                                    }
                                    dot={
                                      product.metadata.id === 'premium' ? (
                                        <CheckCircleOutlined />
                                      ) : (
                                        <CloseCircleOutlined />
                                      )
                                    }
                                  >
                                    Register and manage property portfolio
                                  </Timeline.Item>
                                  <Timeline.Item
                                    color={
                                      product.metadata.id === 'premium'
                                        ? 'green'
                                        : 'red'
                                    }
                                    dot={
                                      product.metadata.id === 'premium' ? (
                                        <CheckCircleOutlined />
                                      ) : (
                                        <CloseCircleOutlined />
                                      )
                                    }
                                  >
                                    Compare and forecast loan structures
                                  </Timeline.Item>
                                  <Timeline.Item
                                    color={
                                      product.metadata.id === 'premium'
                                        ? 'green'
                                        : 'red'
                                    }
                                    dot={
                                      product.metadata.id === 'premium' ? (
                                        <CheckCircleOutlined />
                                      ) : (
                                        <CloseCircleOutlined />
                                      )
                                    }
                                  >
                                    Compare and forecast property strategies
                                  </Timeline.Item>
                                </Timeline>
                              </>
                            )}
                          </Space>
                        </Card>
                      </Col>
                    ))}{' '}
                </Row>
              </Spin>
            )}
          </Space>
        </Layout.Content>
      </Layout>
    </>
  )
}

export default PlanSelect
