import { RouteComponentProps, Link, navigate, useLocation } from '@reach/router'
import { Alert, Menu, Skeleton, Space, Breadcrumb } from 'antd'
import { useEffect } from 'react'
import { Property } from '../../../shared/types/Property'
import PageHeader from '../components/PageHeader'
import { useLazyQuery } from '../hooks/useLazyQuery'
import { APIRoute } from '../network'
import { Routes } from '../routes'
import PropertyForm from '../components/PropertyForm'
import { authenticatedRoute } from '../components/AuthenticatedRoute'
import { Loan } from '../../../shared/types/Loan'
import LoanList from '../components/LoanList'
import { Helmet } from 'react-helmet'

const PropertyView: React.FC<RouteComponentProps<{ id: string }>> = ({
  id,
}) => {
  const location = useLocation()

  const {
    makeQuery: getProperty,
    data: propertyData,
    loading: propertyLoading,
    error: propertyError,
  } = useLazyQuery<Property>(APIRoute.GetProperty)

  const {
    makeQuery: getLoans,
    data: loansData,
    loading: loansLoading,
    error: loansError,
  } = useLazyQuery<Loan[]>(APIRoute.GetLoans)

  useEffect(() => {
    getProperty(undefined, id)
  }, [])

  useEffect(() => {
    if (location.href.includes('loans') && !loansData) {
      getLoans(undefined, id)
    }
  }, [location.href])

  const current = location.href.includes('loans') ? 'loans' : 'property'

  return (
    <>
      <Helmet>
        <title>
          {propertyData ? propertyData.address : 'Property'} - Doorstep
        </title>
      </Helmet>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={Routes.PROPERTIES}>My Properties</Link>
          </Breadcrumb.Item>
          {propertyData && (
            <Breadcrumb.Item>{propertyData.address}</Breadcrumb.Item>
          )}
        </Breadcrumb>
        <PageHeader title={propertyData ? propertyData.address : 'Property'} />
        {propertyError && (
          <Alert
            message="Error"
            description="There was an issue getting this property. Please try again later."
            type="error"
            showIcon
          />
        )}
        {propertyData && (
          <Menu
            onClick={({ key }) =>
              navigate(
                `${Routes.PROPERTIES}/${id}${key === 'loans' ? '/loans' : ''}`,
              )
            }
            selectedKeys={[current]}
            mode="horizontal"
          >
            <Menu.Item key="property">Property</Menu.Item>
            <Menu.Item key="loans">Loans</Menu.Item>
          </Menu>
        )}
        <Skeleton loading={propertyLoading || loansLoading} active />
        {propertyData && current === 'property' && (
          <PropertyForm property={propertyData} onUpdate={() => null} />
        )}
        {loansError && (
          <Alert
            message="Error"
            description="There was an issue getting the loans for this property. Please try again later."
            type="error"
            showIcon
          />
        )}
        {loansData && current === 'loans' && (
          <LoanList property={propertyData} loans={loansData} />
        )}
      </Space>
    </>
  )
}

export default authenticatedRoute(PropertyView)
