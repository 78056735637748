import { Button, Card, Col, Form, Row, Space, DatePicker, Select } from 'antd'
import { useState } from 'react'
import DollarInput, { DollarInputRules } from './DollarInput'
import {
  ExtraRepayment,
  ExtraRepaymentFrequency,
} from '../../../shared/types/ExtraRepayment'
import moment from 'moment'
import { Loan } from '../../../shared/types/Loan'
import { HelpText } from '../HelpText'
import { HelpLabel } from './HelpLabel'

type ExtraRepaymentCreateValues = Pick<
  ExtraRepayment,
  'amount' | 'frequency'
> & {
  startDate: moment.Moment
  endDate?: moment.Moment
}

const PropertyLoanExtraRepaymentCreateForm: React.FC<{
  loan: Loan
  onCreate: (credit: ExtraRepaymentCreateValues) => void
  onCancel: () => void
  invalidDateRanges: Interval[]
}> = ({ onCreate, onCancel }) => {
  const [form] = Form.useForm<ExtraRepaymentCreateValues>()
  const [isUpdating, setIsUpdating] = useState(false)
  const [formUpdated, setFormUpdated] = useState(0)

  return (
    <Form
      form={form}
      layout="vertical"
      name="extra-repayment-create"
      autoComplete="off"
      requiredMark="optional"
      onFinish={values => {
        setIsUpdating(true)
        onCreate(values)
      }}
    >
      <Card bordered={false} className="form-card">
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={5}>
              <Form.Item
                label={
                  <HelpLabel tooltip={HelpText.ExtraRepaymentAmount}>
                    Amount
                  </HelpLabel>
                }
                name="amount"
                style={{ margin: '0' }}
                required
                rules={[
                  ...DollarInputRules,
                  {
                    required: true,
                    message: 'Please input an amount',
                  },
                ]}
              >
                <DollarInput />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={4}>
              <Form.Item
                label="Frequency"
                name="frequency"
                style={{ margin: '0' }}
                required
                rules={[
                  {
                    required: true,
                    message: 'Please select a frequency',
                  },
                ]}
              >
                <Select
                  size="large"
                  onChange={() => setFormUpdated(formUpdated + 1)}
                >
                  <Select.Option value={ExtraRepaymentFrequency.WEEKLY}>
                    Weekly
                  </Select.Option>
                  <Select.Option value={ExtraRepaymentFrequency.FORTNIGHTLY}>
                    Fortnightly
                  </Select.Option>
                  <Select.Option value={ExtraRepaymentFrequency.MONTHLY}>
                    Monthly
                  </Select.Option>
                  <Select.Option value={ExtraRepaymentFrequency.YEARLY}>
                    Yearly
                  </Select.Option>
                  <Select.Option value={ExtraRepaymentFrequency.ONCE_OFF}>
                    Once Off
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={4}>
              <Form.Item
                label={
                  form.getFieldValue('frequency') ===
                  ExtraRepaymentFrequency.ONCE_OFF
                    ? 'Date'
                    : 'Starts'
                }
                name="startDate"
                style={{ margin: '0' }}
                required
                rules={[
                  {
                    required: true,
                    message: 'Please input a date',
                  },
                ]}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  size="large"
                  style={{ width: '100%' }}
                  placeholder=""
                  disabledDate={current => {
                    const endDate = form.getFieldValue(['endDate'])

                    return current && endDate && current > endDate
                  }}
                />
              </Form.Item>
            </Col>
            {form.getFieldValue('frequency') !==
              ExtraRepaymentFrequency.ONCE_OFF && (
              <Col xs={24} sm={12} md={4}>
                <Form.Item
                  label="Ends"
                  name="endDate"
                  style={{ margin: '0' }}
                  required
                >
                  <DatePicker
                    format="DD/MM/YYYY"
                    size="large"
                    style={{ width: '100%' }}
                    placeholder=""
                    disabledDate={current => {
                      const startDate = form.getFieldValue(['startDate'])

                      return current && startDate && current < startDate
                    }}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>

          <Row>
            <Col xs={12} sm={12} md={3} style={{ display: 'flex' }}>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                loading={isUpdating}
                style={{ marginRight: '1rem' }}
              >
                Save
              </Button>
              <Button
                onClick={onCancel}
                disabled={isUpdating}
                htmlType="button"
                size="large"
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Space>
      </Card>
    </Form>
  )
}

export default PropertyLoanExtraRepaymentCreateForm
