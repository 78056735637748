import { createContext, useState, useEffect } from 'react'
import * as auth from 'firebase/auth'
import * as firebaseui from 'firebaseui'

export const AuthContext = createContext<{
  auth: auth.Auth | null
  ui: firebaseui.auth.AuthUI | null
}>({
  auth: null,
  ui: null,
})

export const AuthenticationProvider: React.FC = ({ children }) => {
  const [authContext, setAuthContext] = useState<{
    auth: auth.Auth | null
    ui: firebaseui.auth.AuthUI | null
  }>({
    auth: null,
    ui: null,
  })

  useEffect(() => {
    const globalAuth = auth.getAuth()

    ;(window as any).globalAuth = globalAuth

    setAuthContext({
      auth: globalAuth,
      ui: new firebaseui.auth.AuthUI(globalAuth),
    })
  }, [])

  return (
    <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  )
}
