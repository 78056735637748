import { ArrowLeftOutlined } from '@ant-design/icons'
import { Link } from '@reach/router'
import { Button } from 'antd'
import Text from 'antd/lib/typography/Text'
import Title from 'antd/lib/typography/Title'

interface PageHeaderProps {
  back?: string
  title: string
  subtitle?: string
}

const PageHeader: React.FC<PageHeaderProps> = ({ back, title, subtitle }) => (
  <div>
    {back && (
      <Link to={back}>
        <Button type="text" icon={<ArrowLeftOutlined />}>
          Back
        </Button>
      </Link>
    )}
    <Title level={2}>{title}</Title>
    {subtitle && <Text type="secondary">{subtitle}</Text>}
  </div>
)

export default PageHeader
