import { Alert, Skeleton, Card, Empty, Row, Col, message, Button } from 'antd'
import { useEffect, useState } from 'react'
import { useLazyQuery } from '../hooks/useLazyQuery'
import { APIRoute } from '../network'
import { Loan } from '../../../shared/types/Loan'
import PropertyLoanVariantForm from './PropertyLoanVariantForm'
import { LoanVariant } from '../../../shared/types/LoanVariant'
import PropertyLoanVariantCreateForm from './PropertyLoanVariantCreateForm'
import { Interval } from 'date-fns'
import { HelpText } from '../HelpText'
import { HelpLabel } from './HelpLabel'

const LoanVariantView: React.FC<{ loan: Loan; onUpdate: () => void }> = ({
  loan,
  onUpdate,
}) => {
  const [variants, setVariants] = useState<LoanVariant[] | undefined>(undefined)
  const [createOpen, setCreateOpen] = useState(false)

  const {
    makeQuery: getVariants,
    data: variantsData,
    loading: variantsLoading,
    error: variantsError,
  } = useLazyQuery<LoanVariant[]>(APIRoute.GetLoanVariants)
  const {
    makeQuery: createVariant,
    data: createData,
    error: createError,
  } = useLazyQuery<LoanVariant>(APIRoute.CreateLoanVariant)
  const {
    makeQuery: updateVariant,
    data: updateData,
    error: updateError,
  } = useLazyQuery<LoanVariant>(APIRoute.UpdateLoanVariant)
  const {
    makeQuery: deleteVariant,
    data: deleteData,
    error: deleteError,
  } = useLazyQuery<LoanVariant>(APIRoute.DeleteLoanVariant)

  useEffect(() => {
    getVariants(undefined, loan.id)
  }, [])

  useEffect(() => {
    if (variantsData) {
      setVariants(variantsData)
    }
  }, [variantsData])

  useEffect(() => {
    if (createData) {
      message.success('Saved')
      setCreateOpen(false)

      setVariants([...(variants || []), createData])
      onUpdate()
    }
  }, [createData])

  useEffect(() => {
    if (updateData && variants) {
      message.success('Saved')

      const variantIndex = variants.findIndex(
        variant => variant.id === updateData.id,
      )

      if (variantIndex !== -1) {
        setVariants([
          ...variants.slice(0, variantIndex),
          updateData,
          ...variants.slice(variantIndex + 1),
        ])
      }

      onUpdate()
    }
  }, [updateData])

  useEffect(() => {
    if (deleteData && variants) {
      message.success('Deleted')

      const variantIndex = variants.findIndex(
        variant => variant.id === deleteData.id,
      )

      if (variantIndex !== -1) {
        setVariants([
          ...variants.slice(0, variantIndex),
          ...variants.slice(variantIndex + 1),
        ])
      }

      onUpdate()
    }
  }, [deleteData])

  return (
    <Card
      bordered={false}
      className="form-card body-no-padding"
      title={
        <HelpLabel tooltip={HelpText.LoanVariants}>Loan Variants</HelpLabel>
      }
    >
      <Skeleton loading={variantsLoading} active />
      {variantsError && (
        <Alert
          message="Error"
          description="There was an issue getting your variants. Please try again later."
          type="error"
          showIcon
        />
      )}
      {createError && (
        <Alert
          message="Error"
          description="There was an issue creating your variant. Please try again later."
          type="error"
          showIcon
        />
      )}
      {updateError && (
        <Alert
          message="Error"
          description="There was an issue updating your variant. Please try again later."
          type="error"
          showIcon
        />
      )}
      {deleteError && (
        <Alert
          message="Error"
          description="There was an issue deleting your variant. Please try again later."
          type="error"
          showIcon
        />
      )}
      {variants && (
        <>
          {variants.length === 0 && (
            <Empty
              style={{ marginTop: '1rem' }}
              description="There are no variants against this loan."
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )}
          {variants.map(variant => (
            <Row key={variant.id}>
              <Col xs={24}>
                <PropertyLoanVariantForm
                  loan={loan}
                  invalidDateRanges={
                    variants
                      ?.filter(({ id }) => variant.id !== id)
                      .reduce<Interval[]>(
                        (acc, curr) =>
                          acc.concat({
                            start: new Date(curr.startDate),
                            end: new Date(curr.endDate),
                          }),
                        [],
                      ) || []
                  }
                  variant={variant}
                  onUpdate={values =>
                    updateVariant(
                      {
                        ...values,
                        startDate: new Date(
                          values.startDate.format('yyyy-MM-DD'),
                        ).toISOString(),
                        endDate: new Date(
                          values.endDate.format('yyyy-MM-DD'),
                        ).toISOString(),
                        portions: values.portions.map(portion => ({
                          ...portion,
                          percentage: portion.percentage * 0.01,
                          interestRate: portion.interestRate * 0.01,
                          offsetContributions:
                            portion.offsetContributions * 0.01,
                          extraRepaymentPercentage:
                            portion.extraRepaymentPercentage * 0.01,
                        })),
                      },
                      loan.id,
                      variant.id,
                    )
                  }
                  onDelete={() => deleteVariant(undefined, loan.id, variant.id)}
                />
              </Col>
            </Row>
          ))}
        </>
      )}
      {!variantsLoading && !createOpen && (
        <Button size="large" type="primary" onClick={() => setCreateOpen(true)}>
          Add Variant
        </Button>
      )}
      {createOpen && (
        <PropertyLoanVariantCreateForm
          loan={loan}
          invalidDateRanges={
            variants?.reduce<Interval[]>(
              (acc, curr) =>
                acc.concat({
                  start: new Date(curr.startDate),
                  end: new Date(curr.endDate),
                }),
              [],
            ) || []
          }
          onCreate={values =>
            createVariant(
              {
                ...values,
                startDate: new Date(
                  values.startDate.format('yyyy-MM-DD'),
                ).toISOString(),
                endDate: new Date(
                  values.endDate.format('yyyy-MM-DD'),
                ).toISOString(),
                portions: values.portions.map(portion => ({
                  ...portion,
                  percentage: portion.percentage * 0.01,
                  interestRate: portion.interestRate * 0.01,
                  offsetContributions: portion.offsetContributions * 0.01,
                  extraRepaymentPercentage:
                    portion.extraRepaymentPercentage * 0.01,
                })),
              },
              loan.id,
            )
          }
          onCancel={() => setCreateOpen(false)}
        />
      )}
    </Card>
  )
}

export default LoanVariantView
