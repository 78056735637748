import { CaretDownOutlined, UserOutlined } from '@ant-design/icons'
import { navigate } from '@reach/router'
import { Avatar, Dropdown, Menu } from 'antd'
import { useContext } from 'react'
import { Routes } from '../routes'
import { UserContext } from './UserContext'

const UserMenu: React.FC<{ mobile?: boolean }> = ({ mobile }) => {
  const user = useContext(UserContext)
  return user ? (
    <Dropdown
      overlay={
        <Menu>
          <Menu.Item key="0">
            <button
              style={{
                textAlign: 'left',
                background: 'none',
                border: 'none',
                outline: 'none',
                cursor: 'pointer',
                width: '100%',
              }}
              onClick={() => navigate(Routes.PROFILE)}
            >
              Profile
            </button>
          </Menu.Item>
          <Menu.Item key="1">
            <button
              style={{
                textAlign: 'left',
                background: 'none',
                border: 'none',
                outline: 'none',
                cursor: 'pointer',
                width: '100%',
              }}
              onClick={() => navigate(Routes.LOGOUT)}
            >
              Logout
            </button>
          </Menu.Item>
        </Menu>
      }
      trigger={['click']}
    >
      <button
        type="button"
        className="antd-dropdown-link"
        style={{
          background: 'none',
          border: 'none',
          outline: 'none',
          cursor: 'pointer',
          color: '#ffffff',
        }}
      >
        {user.photoURL ? (
          <Avatar src={user.photoURL} />
        ) : (
          <Avatar icon={<UserOutlined />} />
        )}
        {!mobile && (
          <>
            <span style={{ margin: '0 0.5rem' }}>
              {user.displayName || user.email}
            </span>
            <CaretDownOutlined />
          </>
        )}
      </button>
    </Dropdown>
  ) : null
}

export default UserMenu
