import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Row,
  Space,
  message,
  Alert,
  Checkbox,
  List,
  Skeleton,
  Input,
  Select,
  Tooltip,
  InputNumber,
} from 'antd'
import { useEffect, useState } from 'react'
import { useLazyQuery } from '../hooks/useLazyQuery'
import { APIRoute } from '../network'
import DollarInput, { DollarInputRules } from './DollarInput'
import {
  PurchasePlan,
  PurchasePlanPropertyType,
  PurchasePlanTarget,
} from '../../../shared/types/PurchasePlan'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import moment from 'moment'
import { Debit, DebitFrequency } from '../../../shared/types/Debit'
import { addYears } from 'date-fns'
import { HelpLabel } from './HelpLabel'
import { HelpText } from '../HelpText'
import PercentageInput from './PercentageInput'
import Text from 'antd/lib/typography/Text'

type PurchasePlanFormValues = Omit<PurchasePlanTarget, 'date'> & {
  date?: moment.Moment
  moveInDate?: moment.Moment
  debits?: {
    id?: string
    debitID?: string
    enabled: boolean
    amount: number
    name?: string
    frequency?: string
    endDate?: moment.Moment
  }[]
  interestOnlyYears?: number
  offsetContributions?: number
}

const getFormValues = (
  data: PurchasePlan,
  debits: Debit[],
): PurchasePlanFormValues => ({
  date: data.target?.date ? moment(data.target.date) : undefined,
  price: data.target?.price,
  moveInDate: data.moveInDate ? moment(data.moveInDate) : undefined,
  debits: [
    ...debits.map(debit => {
      const planDebit = data.debits?.find(({ debitID }) => debitID === debit.id)

      return {
        id: planDebit?.id,
        debitID: debit.id,
        name: debit.name,
        amount: planDebit?.amount || debit.amount,
        enabled: planDebit ? planDebit.enabled : true,
        frequency: debit.frequency,
        endDate: debit.endDate ? moment(debit.endDate) : undefined,
      }
    }),
    ...(data.debits
      ? data.debits
          .filter(debit => !debit.debitID)
          .map(debit => ({
            id: debit.id,
            amount: debit.amount,
            name: debit.name,
            enabled: debit.enabled,
            frequency: debit.frequency,
            endDate: debit.endDate ? moment(debit.endDate) : undefined,
          }))
      : []),
  ],
  interestOnlyYears: data.interestOnlyYears,
  offsetContributions: data.offsetContributions
    ? Number((data.offsetContributions * 100).toFixed(2))
    : undefined,
})

const PurchasePlanTargetForm: React.FC<{
  purchasePlan: PurchasePlan
  onUpdate: (plan: PurchasePlan) => void
}> = ({ purchasePlan, onUpdate }) => {
  const [form] = Form.useForm<PurchasePlanFormValues>()
  const [formOpen, setFormOpen] = useState(false)
  const [formUpdated, setFormUpdated] = useState(0)

  const {
    makeQuery: getDebits,
    data: debitsData,
    loading: debitsLoading,
  } = useLazyQuery<Debit[]>(APIRoute.GetDebits)
  const { makeQuery, data, loading, error } = useLazyQuery<PurchasePlan>(
    APIRoute.UpdatePurchasePlan,
  )

  useEffect(() => {
    getDebits()
  }, [])

  useEffect(() => {
    if (data) {
      message.success('Saved')
      form.setFieldsValue(getFormValues(data, debitsData || []))
      setFormOpen(false)
      onUpdate(data)
    }
  }, [data])

  useEffect(() => {
    form.resetFields()
    setFormUpdated(formUpdated + 1)
  }, [error])

  const fieldDisabled = loading || !formOpen
  const plan = data || purchasePlan

  if (debitsLoading || !debitsData) {
    return <Skeleton />
  }

  return (
    <Form
      form={form}
      layout="vertical"
      name="purchase-plan-target"
      autoComplete="off"
      requiredMark="optional"
      initialValues={getFormValues(plan, debitsData || [])}
      onFinish={async values => {
        await makeQuery(
          {
            target: {
              price: !values.date && values.price ? values.price : undefined,
              date:
                !values.price && values.date
                  ? new Date(values.date.format('yyyy-MM-DD')).toISOString()
                  : undefined,
            },
            moveInDate: values.moveInDate
              ? new Date(values.moveInDate.format('yyyy-MM-DD')).toISOString()
              : undefined,
            debits: values.debits?.map(debit => ({
              ...debit,
              name: debit.debitID ? undefined : debit.name,
              frequency: debit.debitID ? undefined : debit.frequency,
              endDate: debit.endDate
                ? new Date(debit.endDate.format('yyyy-MM-DD')).toISOString()
                : undefined,
            })),
            interestOnlyYears: values.interestOnlyYears,
            offsetContributions: values.offsetContributions
              ? values.offsetContributions * 0.01
              : null,
          },
          plan.id,
        )
      }}
    >
      <Card
        bordered={false}
        className={`form-card${formOpen ? '' : ' view-mode'}`}
      >
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <Row>
            <Col xs={24}>
              <Text type="secondary">
                Set a target date or price to purchase a property at. From here,
                you can drill down into the specifics of your target purchase
                and gain a fine grain view of your month to month finances if
                you were to pursue this purchase.
              </Text>
            </Col>
          </Row>
          {error && (
            <Row gutter={[16, 16]}>
              <Col xs={24}>
                <Alert
                  message="Error"
                  description="There was an issue saving this purchase plan. Please try again later."
                  type="error"
                  showIcon
                />
              </Col>
            </Row>
          )}
          {!formOpen && !plan.target && (
            <Button
              size="large"
              type="primary"
              htmlType="button"
              onClick={() => setFormOpen(true)}
            >
              Add Target
            </Button>
          )}
          {!formOpen && plan.target && (
            <Button
              size="large"
              htmlType="button"
              onClick={() => setFormOpen(true)}
            >
              Edit Target
            </Button>
          )}
          {formOpen && (
            <Row>
              <Col xs={12} sm={12} md={3} style={{ display: 'flex' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={loading}
                  style={{ marginRight: '1rem' }}
                >
                  Save
                </Button>
                <Button
                  onClick={() => {
                    form.resetFields()
                    setFormOpen(false)
                  }}
                  disabled={loading}
                  htmlType="button"
                  size="large"
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          )}
          <Row gutter={[16, 16]}>
            {(formOpen || form.getFieldValue('date')) && (
              <Col xs={24} sm={12} md={5}>
                <Form.Item
                  label="Date"
                  name="date"
                  style={{ margin: '0 1rem 0 0' }}
                  required
                >
                  <DatePicker
                    format="DD/MM/YYYY"
                    size="large"
                    style={{ width: '100%' }}
                    disabled={fieldDisabled || form.getFieldValue('price')}
                    onChange={() => setFormUpdated(formUpdated + 1)}
                    disabledDate={date =>
                      date.isBefore(new Date(plan.createdAt)) ||
                      date.isAfter(addYears(new Date(plan.createdAt), 30))
                    }
                  />
                </Form.Item>
              </Col>
            )}
            {formOpen && (
              <Col
                xs={24}
                sm={2}
                md={1}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <b>Or</b>
              </Col>
            )}
            {(formOpen || form.getFieldValue('price')) && (
              <Col xs={24} sm={12} md={5}>
                <Form.Item
                  label="Price"
                  name="price"
                  style={{ margin: '0 1rem 0 0' }}
                  required
                  // rules={[
                  //   {
                  //     type: 'integer',
                  //     max: affordabilityMax,
                  //     message: `Target price cannot be more than ${currencyFormatter.format(
                  //       affordabilityMax,
                  //     )}`,
                  //   },
                  // ]}
                >
                  <DollarInput
                    disabled={fieldDisabled || form.getFieldValue('date')}
                    onChange={() => setFormUpdated(formUpdated + 1)}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={[16, 16]}>
            {(formOpen || plan.target) && (
              <Col xs={24} sm={12} md={4}>
                <Form.Item
                  label={
                    <HelpLabel tooltip={HelpText.LoanOffsetContributions}>
                      Offset Contributions
                    </HelpLabel>
                  }
                  name="offsetContributions"
                  style={{ margin: '0' }}
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Please input a percentage',
                    },
                    {
                      type: 'number',
                      max: 100,
                      message:
                        'Please input a percentage less than or equal to 100%',
                    },
                  ]}
                >
                  <PercentageInput disabled={fieldDisabled} />
                </Form.Item>
              </Col>
            )}
            {(!formOpen && !plan.target) ||
            plan.property.type !==
              PurchasePlanPropertyType.INVESTMENT ? null : (
              <>
                <Col xs={24} sm={12} md={6}>
                  <Form.Item
                    label="Move In Date"
                    name="moveInDate"
                    style={{ margin: '0 1rem 0 0' }}
                  >
                    <DatePicker
                      format="DD/MM/YYYY"
                      size="large"
                      style={{ width: '100%' }}
                      disabled={fieldDisabled}
                      onChange={() => setFormUpdated(formUpdated + 1)}
                      disabledDate={date =>
                        date.isBefore(form.getFieldValue('date'))
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <Form.Item
                    label={
                      <HelpLabel tooltip={HelpText.InterestOnlyYears}>
                        Interest-only loan years
                      </HelpLabel>
                    }
                    name="interestOnlyYears"
                    style={{ margin: '0 1rem 0 0' }}
                    rules={[
                      {
                        type: 'integer',
                        min: 0,
                        message: 'Please input whole years',
                      },
                      {
                        type: 'integer',
                        max: 30,
                        message:
                          'Please input years less than or equal to 30 years',
                      },
                    ]}
                  >
                    <InputNumber
                      size="large"
                      style={{ width: '100%' }}
                      disabled={fieldDisabled}
                    />
                  </Form.Item>
                </Col>
              </>
            )}
            {!formOpen && !plan.target ? null : (
              <>
                <Col xs={24}>
                  <Tooltip title={HelpText.PlanTargetDebits}>
                    <h3
                      style={{
                        margin: '0.5rem 0',
                        textDecorationStyle: 'dotted',
                        textDecorationLine: 'underline',
                      }}
                    >
                      Review Expenses
                    </h3>
                  </Tooltip>
                </Col>
                <Col xs={24}>
                  <Form.List name="debits">
                    {(debits, { add, remove }) => (
                      <>
                        <List
                          itemLayout="horizontal"
                          dataSource={debits}
                          renderItem={debit => (
                            <Space
                              key={debit.key}
                              direction="vertical"
                              size="small"
                              style={{ width: '100%', marginBottom: '0.5rem' }}
                            >
                              <Row gutter={[16, 16]}>
                                <Col xs={24} sm={12} md={5}>
                                  <Form.Item
                                    label="Name"
                                    name={[debit.name, 'name']}
                                    fieldKey={[debit.fieldKey, 'name']}
                                    style={{ margin: '0' }}
                                    required
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please input name',
                                      },
                                    ]}
                                  >
                                    <Input
                                      size="large"
                                      disabled={
                                        fieldDisabled ||
                                        form.getFieldValue([
                                          'debits',
                                          debit.name,
                                        ])?.debitID
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={3}>
                                  <Form.Item
                                    label={
                                      <HelpLabel
                                        tooltip={
                                          HelpText.PlanTargetDebitEnabled
                                        }
                                      >
                                        Applicable
                                      </HelpLabel>
                                    }
                                    name={[debit.name, 'enabled']}
                                    fieldKey={[debit.fieldKey, 'enabled']}
                                    valuePropName="checked"
                                    style={{ margin: '0' }}
                                    required
                                  >
                                    <Checkbox
                                      disabled={fieldDisabled}
                                      onChange={() =>
                                        setFormUpdated(formUpdated + 1)
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={5}>
                                  <Form.Item
                                    label="Amount"
                                    name={[debit.name, 'amount']}
                                    fieldKey={[debit.fieldKey, 'amount']}
                                    style={{ margin: '0' }}
                                    required
                                    rules={[
                                      ...DollarInputRules,
                                      {
                                        required: true,
                                        message: 'Please input an amount',
                                      },
                                    ]}
                                  >
                                    <DollarInput disabled={fieldDisabled} />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={4}>
                                  <Form.Item
                                    label="Frequency"
                                    name={[debit.name, 'frequency']}
                                    fieldKey={[debit.fieldKey, 'frequency']}
                                    style={{ margin: '0' }}
                                    required
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please select a frequency',
                                      },
                                    ]}
                                  >
                                    <Select
                                      size="large"
                                      disabled={
                                        fieldDisabled ||
                                        form.getFieldValue([
                                          'debits',
                                          debit.name,
                                        ])?.debitID
                                      }
                                      onChange={() =>
                                        setFormUpdated(formUpdated + 1)
                                      }
                                    >
                                      <Select.Option
                                        value={DebitFrequency.WEEKLY}
                                      >
                                        Weekly
                                      </Select.Option>
                                      <Select.Option
                                        value={DebitFrequency.FORTNIGHTLY}
                                      >
                                        Fortnightly
                                      </Select.Option>
                                      <Select.Option
                                        value={DebitFrequency.MONTHLY}
                                      >
                                        Monthly
                                      </Select.Option>
                                      <Select.Option
                                        value={DebitFrequency.YEARLY}
                                      >
                                        Yearly
                                      </Select.Option>
                                      <Select.Option
                                        value={DebitFrequency.ONCE_OFF}
                                      >
                                        Once Off
                                      </Select.Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={4}>
                                  <Form.Item
                                    label="End Date"
                                    name={[debit.name, 'endDate']}
                                    fieldKey={[debit.fieldKey, 'endDate']}
                                    style={{ margin: '0' }}
                                  >
                                    <DatePicker
                                      format="DD/MM/YYYY"
                                      size="large"
                                      style={{ width: '100%' }}
                                      disabled={
                                        fieldDisabled ||
                                        form.getFieldValue([
                                          'debits',
                                          debit.name,
                                        ])?.debitID
                                      }
                                      placeholder=""
                                      disabledDate={current => {
                                        const startDate =
                                          form.getFieldValue('date')

                                        return (
                                          current &&
                                          startDate &&
                                          current < startDate
                                        )
                                      }}
                                    />
                                  </Form.Item>
                                </Col>
                                {formOpen && (
                                  <Col
                                    xs={3}
                                    md={1}
                                    className="plan-debit-remove"
                                  >
                                    <Button
                                      shape="circle"
                                      size="small"
                                      icon={<MinusOutlined />}
                                      disabled={
                                        form.getFieldValue([
                                          'debits',
                                          debit.name,
                                        ])?.debitID
                                      }
                                      onClick={() => {
                                        remove(debit.name)
                                      }}
                                    />
                                  </Col>
                                )}
                              </Row>
                            </Space>
                          )}
                        />
                        {formOpen && (
                          <Button
                            type="primary"
                            size="large"
                            onClick={() => add({})}
                            icon={<PlusOutlined />}
                            style={{ marginTop: '1rem' }}
                          >
                            Add Expense
                          </Button>
                        )}
                      </>
                    )}
                  </Form.List>
                </Col>
              </>
            )}
          </Row>
        </Space>
      </Card>
    </Form>
  )
}

export default PurchasePlanTargetForm
