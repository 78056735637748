import { Space, Row, Col, Alert, Button, message, Popconfirm } from 'antd'
import { useEffect } from 'react'
import { Loan, LoanForecast } from '../../../shared/types/Loan'
import { Property } from '../../../shared/types/Property'
import { useLazyQuery } from '../hooks/useLazyQuery'
import { APIRoute } from '../network'
import ExtraRepaymentView from './ExtraRepaymentView'
import LoanForecastGraph from './LoanForecastGraph'
import LoanForecastTable from './LoanForecastTable'
import LoanVariantView from './LoanVariantView'
import PropertyLoanForm from './PropertyLoanForm'
import RentPeriodView from './RentPeriodView'

const LoanView: React.FC<{
  loan: Loan
  numberOfLoans: number
  onUpdate: (loan: Loan) => void
  onDelete: () => void
  onCopy: (loan: Loan) => void
}> = ({ loan, numberOfLoans, onUpdate, onDelete, onCopy }) => {
  const { makeQuery: getProperty, data: propertyData } = useLazyQuery<Property>(
    APIRoute.GetProperty,
  )
  const {
    makeQuery: getForecast,
    data: forecastData,
    loading: forecastLoading,
    error: forecastError,
  } = useLazyQuery<LoanForecast[]>(APIRoute.GetLoanForecast)
  const { makeQuery: deleteLoan, data: deleteData } = useLazyQuery<Loan>(
    APIRoute.DeleteLoan,
  )
  const { makeQuery: copyLoan, data: copyData } = useLazyQuery<Loan>(
    APIRoute.CopyLoan,
  )

  const fetchForecast = () => getForecast(undefined, loan.propertyID, loan.id)

  const handleUpdate = (data?: Loan) => {
    onUpdate(data || loan)
    fetchForecast()
  }

  useEffect(() => {
    fetchForecast()
    getProperty(undefined, loan.propertyID)
  }, [])

  useEffect(() => {
    if (deleteData) {
      message.success('Deleted')
      onDelete()
    }
  }, [deleteData])

  useEffect(() => {
    if (copyData) {
      message.success('Copied')
      onCopy(copyData)
    }
  }, [copyData])

  return (
    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
      <Row>
        <Col xs={12} sm={4}>
          <Popconfirm
            title="Are you sure you would like to copy this to a new loan?"
            onConfirm={() => copyLoan(undefined, loan.propertyID, loan.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button htmlType="button" size="large">
              Copy to New Loan
            </Button>
          </Popconfirm>
        </Col>
        <Col xs={12} sm={3}>
          <Popconfirm
            title="Are you sure you would like to delete this loan?"
            onConfirm={() => deleteLoan(undefined, loan.propertyID, loan.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button htmlType="button" size="large" danger>
              Delete
            </Button>
          </Popconfirm>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <PropertyLoanForm
            loan={loan}
            isPrimaryDisabled={numberOfLoans === 1}
            onUpdate={handleUpdate}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <LoanVariantView loan={loan} onUpdate={handleUpdate} />
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <ExtraRepaymentView loan={loan} onUpdate={handleUpdate} />
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <RentPeriodView
            property={propertyData}
            loan={loan}
            onUpdate={handleUpdate}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          {forecastError && (
            <Alert
              message="Error"
              description="There was an issue getting the forecast for your loan. Please try again later."
              type="error"
              showIcon
            />
          )}
          {!forecastError && (
            <>
              <LoanForecastGraph
                results={forecastData || []}
                loading={forecastLoading}
              />
              <LoanForecastTable
                results={forecastData || []}
                loading={forecastLoading}
              />
            </>
          )}
        </Col>
      </Row>
    </Space>
  )
}

export default LoanView
