import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Select,
  Space,
  DatePicker,
  InputNumber,
  Tooltip,
} from 'antd'
import { useEffect, useState } from 'react'
import DollarInput, { DollarInputRules } from './DollarInput'
import moment from 'moment'
import {
  LoanVariant,
  LoanVariantPortionPaymentFrequency,
  LoanVariantPortionType,
  LoanVariantType,
} from '../../../shared/types/LoanVariant'
import PercentageInput, { PercentageInputRules } from './PercentageInput'
import {
  isWithinInterval,
  Interval,
  isBefore,
  isAfter,
  addYears,
  endOfMonth,
} from 'date-fns'
import isSameDay from 'date-fns/isSameDay'
import { Loan, LoanRepaymentType } from '../../../shared/types/Loan'
import { HelpText } from '../HelpText'
import { HelpLabel } from './HelpLabel'

type LoanVariantFormValues = Pick<
  LoanVariant,
  'type' | 'refinanceAmount' | 'portions'
> & {
  startDate: moment.Moment
  endDate: moment.Moment
}

const PropertyLoanVariantCreateForm: React.FC<{
  loan: Loan
  onCreate: (variant: LoanVariantFormValues) => void
  onCancel: () => void
  invalidDateRanges: Interval[]
}> = ({ loan, onCreate, onCancel, invalidDateRanges }) => {
  const [form] = Form.useForm<LoanVariantFormValues>()
  const [typeUpdated, setTypeUpdated] = useState(0)
  const [formUpdated, setFormUpdated] = useState([0, 0])
  const [isUpdating, setIsUpdating] = useState(false)
  const [yearsTooltipsVisible, setYearsTooltipsVisible] = useState([
    false,
    false,
  ])

  useEffect(() => {
    const type = form.getFieldValue('type')

    if (type === LoanVariantType.SPLIT) {
      form.setFieldsValue({
        portions: [
          {
            type: LoanVariantPortionType.VARIABLE,
            repaymentType: LoanRepaymentType.PRINCIPAL_INTEREST,
            offsetContributions: 100,
            extraRepaymentPercentage: 0,
          },
          {
            type: LoanVariantPortionType.FIXED,
            repaymentType: LoanRepaymentType.PRINCIPAL_INTEREST,
            offsetContributions: 0,
            extraRepaymentPercentage: 0,
          },
        ],
      })
    }

    if (type === LoanVariantType.VARIABLE || type === LoanVariantType.FIXED) {
      form.setFieldsValue({
        portions: [
          {
            percentage: 100,
            type:
              type === LoanVariantType.VARIABLE
                ? LoanVariantPortionType.VARIABLE
                : LoanVariantPortionType.FIXED,
            repaymentType: LoanRepaymentType.PRINCIPAL_INTEREST,
            offsetContributions: 0,
            extraRepaymentPercentage: 0,
          },
        ],
      })
    }
  }, [typeUpdated])

  useEffect(() => {
    setYearsTooltipsVisible([
      parseInt(form.getFieldValue(['portions', 0, 'years'])) < 10,
      yearsTooltipsVisible[1],
    ])
  }, [formUpdated[0]])

  useEffect(() => {
    setYearsTooltipsVisible([
      yearsTooltipsVisible[0],
      parseInt(form.getFieldValue(['portions', 1, 'years'])) < 10,
    ])
  }, [formUpdated[1]])

  return (
    <Form
      form={form}
      layout="vertical"
      name="variant-create"
      autoComplete="off"
      requiredMark="optional"
      initialValues={{
        type: LoanVariantType.SPLIT,
        refinanceAmount: 0,
        portions: [
          {
            type: LoanVariantPortionType.VARIABLE,
            repaymentType: LoanRepaymentType.PRINCIPAL_INTEREST,
            offsetContributions: 100,
            extraRepaymentPercentage: 0,
          },
          {
            type: LoanVariantPortionType.FIXED,
            repaymentType: LoanRepaymentType.PRINCIPAL_INTEREST,
            offsetContributions: 0,
            extraRepaymentPercentage: 0,
          },
        ],
      }}
      onFinish={values => {
        setIsUpdating(true)
        onCreate(values)
      }}
    >
      <Card bordered={false} className="form-card">
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={4}>
              <Form.Item
                label={
                  <HelpLabel tooltip={HelpText.LoanVariantType}>Type</HelpLabel>
                }
                name="type"
                style={{ margin: '0' }}
                required
                rules={[
                  {
                    required: true,
                    message: 'Please select a type',
                  },
                ]}
              >
                <Select
                  size="large"
                  onChange={() => setTypeUpdated(typeUpdated + 1)}
                >
                  <Select.Option value={LoanVariantType.SPLIT}>
                    Split
                  </Select.Option>
                  <Select.Option value={LoanVariantType.VARIABLE}>
                    Variable
                  </Select.Option>
                  <Select.Option value={LoanVariantType.FIXED}>
                    Fixed
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={4}>
              <Form.Item
                label="Start"
                name="startDate"
                style={{ margin: '0' }}
                required
                rules={[
                  {
                    required: true,
                    message: 'Please input a date',
                  },
                ]}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  size="large"
                  style={{ width: '100%' }}
                  placeholder=""
                  disabledDate={current => {
                    if (
                      isBefore(current.toDate(), new Date(loan.startDate)) ||
                      isAfter(
                        current.toDate(),
                        addYears(new Date(loan.startDate), loan.years),
                      ) ||
                      invalidDateRanges.some(
                        range =>
                          isWithinInterval(current.toDate(), range) ||
                          isSameDay(current.toDate(), range.start) ||
                          isSameDay(current.toDate(), range.end),
                      )
                    ) {
                      return true
                    }

                    const endDate = form.getFieldValue(['endDate'])

                    return current && endDate && current > endDate
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={4}>
              <Form.Item
                label="End"
                name="endDate"
                style={{ margin: '0' }}
                required
                rules={[
                  {
                    required: true,
                    message: 'Please input a date',
                  },
                ]}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  size="large"
                  style={{ width: '100%' }}
                  placeholder=""
                  disabledDate={current => {
                    if (
                      isBefore(current.toDate(), new Date(loan.startDate)) ||
                      isAfter(
                        current.toDate(),
                        addYears(new Date(loan.startDate), loan.years),
                      ) ||
                      invalidDateRanges.some(
                        range =>
                          isWithinInterval(current.toDate(), range) ||
                          isSameDay(current.toDate(), range.start) ||
                          isSameDay(current.toDate(), range.end),
                      ) ||
                      !isSameDay(current.toDate(), endOfMonth(current.toDate()))
                    ) {
                      return true
                    }

                    const startDate = form.getFieldValue(['startDate'])

                    return current && startDate && current < startDate
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={5}>
              <Form.Item
                label={
                  <HelpLabel tooltip={HelpText.LoanVariantRefinanceAmount}>
                    Refinance Amount
                  </HelpLabel>
                }
                name="refinanceAmount"
                style={{ margin: '0' }}
                required
                rules={[
                  ...DollarInputRules,
                  {
                    required: true,
                    message: 'Please input an amount',
                  },
                ]}
              >
                <DollarInput />
              </Form.Item>
            </Col>
          </Row>
          <div style={{ border: '3px solid #f0f2f5', padding: '1rem' }}>
            <Form.List name="portions">
              {portions => (
                <Space
                  direction="vertical"
                  size="middle"
                  style={{ width: '100%' }}
                >
                  {portions.map(portion => (
                    <Row gutter={[16, 16]} key={portion.key}>
                      {form.getFieldValue('type') === LoanVariantType.SPLIT && (
                        <Col xs={24} sm={12} md={3}>
                          <Form.Item
                            {...portion}
                            label="% of Loan"
                            name={[portion.name, 'percentage']}
                            fieldKey={[portion.fieldKey, 'percentage']}
                            style={{ margin: '0' }}
                            required
                            rules={[
                              {
                                type: 'number',
                                min: 0,
                                message:
                                  'Please input a percentage greater than or equal to 0%',
                              },
                              {
                                required: true,
                                message: 'Please input a percentage',
                              },
                              ({ getFieldValue, setFields }) => ({
                                validator: () => {
                                  if (
                                    getFieldValue('type') !==
                                    LoanVariantType.SPLIT
                                  ) {
                                    return Promise.resolve()
                                  }

                                  const portions: LoanVariantFormValues['portions'] =
                                    getFieldValue('portions')
                                  const totalPercentage = portions.reduce(
                                    (acc, curr) => acc + curr.percentage,
                                    0,
                                  )

                                  if (totalPercentage !== 100) {
                                    return Promise.reject(
                                      'Percentages must total to 100%',
                                    )
                                  }

                                  setFields([
                                    {
                                      name: ['portions', 0, 'percentage'],
                                      errors: [],
                                    },
                                    {
                                      name: ['portions', 1, 'percentage'],
                                      errors: [],
                                    },
                                  ])

                                  return Promise.resolve()
                                },
                              }),
                            ]}
                          >
                            <PercentageInput />
                          </Form.Item>
                        </Col>
                      )}
                      {form.getFieldValue('type') === LoanVariantType.SPLIT && (
                        <Col xs={24} sm={12} md={3}>
                          <Form.Item
                            label="Type"
                            name={[portion.name, 'type']}
                            fieldKey={[portion.fieldKey, 'type']}
                            style={{ margin: '0' }}
                            required
                            rules={[
                              {
                                required: true,
                                message: 'Please select a type',
                              },
                            ]}
                          >
                            <Select size="large">
                              <Select.Option
                                value={LoanVariantPortionType.VARIABLE}
                              >
                                Variable
                              </Select.Option>
                              <Select.Option
                                value={LoanVariantPortionType.FIXED}
                              >
                                Fixed
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      )}
                      <Col xs={24} sm={12} md={5}>
                        <Form.Item
                          label="Repayment Type"
                          name={[portion.name, 'repaymentType']}
                          fieldKey={[portion.fieldKey, 'repaymentType']}
                          style={{ margin: '0' }}
                          required
                          rules={[
                            {
                              required: true,
                              message: 'Please select a repayment type',
                            },
                          ]}
                        >
                          <Select size="large">
                            <Select.Option
                              value={LoanRepaymentType.PRINCIPAL_INTEREST}
                            >
                              Principal and Interest
                            </Select.Option>
                            <Select.Option
                              value={LoanRepaymentType.INTEREST_ONLY}
                            >
                              Interest Only
                            </Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={4}>
                        <Form.Item
                          label="Payment Frequency"
                          name={[portion.name, 'paymentFrequency']}
                          fieldKey={[portion.fieldKey, 'paymentFrequency']}
                          style={{ margin: '0' }}
                          required
                          rules={[
                            {
                              required: true,
                              message: 'Please select a frequency',
                            },
                          ]}
                        >
                          <Select size="large">
                            <Select.Option
                              value={LoanVariantPortionPaymentFrequency.MONTHLY}
                            >
                              Monthly
                            </Select.Option>
                            <Select.Option
                              value={
                                LoanVariantPortionPaymentFrequency.FORTNIGHTLY
                              }
                            >
                              Fortnightly
                            </Select.Option>
                            {/* <Select.Option value={LoanVariantPortionPaymentFrequency.WEEKLY}>
                    Weekly
                  </Select.Option> */}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={2}>
                        <Tooltip
                          color="orange"
                          title={
                            <>
                              <p>
                                This looks a little low. Years represents the
                                length of time it will take to pay off the
                                principal of the loan with this variant. This is
                                commonly 30 years. The actual period of time
                                this variant is applicable is calculated based
                                on the dates above.
                              </p>
                              <a
                                href="#"
                                style={{
                                  color: 'white',
                                  textDecoration: 'underline',
                                }}
                                onClick={e => {
                                  e.preventDefault()
                                  const visible = yearsTooltipsVisible.slice()
                                  visible[portion.fieldKey] = false
                                  setYearsTooltipsVisible(visible)
                                }}
                              >
                                Close
                              </a>
                            </>
                          }
                          visible={yearsTooltipsVisible[portion.fieldKey]}
                        >
                          <Form.Item
                            label="Years"
                            name={[portion.name, 'years']}
                            fieldKey={[portion.fieldKey, 'years']}
                            style={{ margin: '0' }}
                            required
                            rules={[
                              {
                                type: 'integer',
                                min: 1,
                                message: 'Please input whole loan years',
                              },
                              {
                                type: 'integer',
                                max: 30,
                                message:
                                  'Please input loan years less than or equal to 30 years',
                              },
                              {
                                required: true,
                                message: 'Please input loan years',
                              },
                            ]}
                          >
                            <InputNumber
                              size="large"
                              style={{ width: '100%' }}
                              onChange={() => {
                                const update = formUpdated.slice()
                                update[portion.fieldKey] =
                                  formUpdated[portion.fieldKey] + 1
                                setFormUpdated(update)
                              }}
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col>
                      <Col xs={24} sm={12} md={3}>
                        <Form.Item
                          label="Interest Rate"
                          name={[portion.name, 'interestRate']}
                          fieldKey={[portion.fieldKey, 'interestRate']}
                          style={{ margin: '0' }}
                          required
                          rules={[
                            ...PercentageInputRules,
                            {
                              required: true,
                              message: 'Please input a percentage',
                            },
                            {
                              type: 'number',
                              max: 100,
                              message:
                                'Please input a percentage less than or equal to 100%',
                            },
                          ]}
                        >
                          <PercentageInput />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={4}>
                        <Form.Item
                          label={
                            <HelpLabel
                              tooltip={HelpText.LoanVariantOffsetContributions}
                            >
                              Offset Contributions
                            </HelpLabel>
                          }
                          name={[portion.name, 'offsetContributions']}
                          fieldKey={[portion.fieldKey, 'offsetContributions']}
                          style={{ margin: '0' }}
                          required
                          rules={[
                            {
                              required: true,
                              message: 'Please input a percentage',
                            },
                            {
                              type: 'number',
                              max: 100,
                              message:
                                'Please input a percentage less than or equal to 100%',
                            },
                          ]}
                        >
                          <PercentageInput />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={5}>
                        <Form.Item
                          label={
                            <HelpLabel
                              tooltip={
                                HelpText.LoanVariantPercentageOfExtraRepayments
                              }
                            >
                              % of Extra Repayments
                            </HelpLabel>
                          }
                          name={[portion.name, 'extraRepaymentPercentage']}
                          fieldKey={[
                            portion.fieldKey,
                            'extraRepaymentPercentage',
                          ]}
                          style={{ margin: '0' }}
                          required
                          rules={[
                            {
                              required: true,
                              message: 'Please input a percentage',
                            },
                            {
                              type: 'number',
                              max: 100,
                              message:
                                'Please input a percentage less than or equal to 100%',
                            },
                            ({ getFieldValue, setFields }) => ({
                              validator: () => {
                                if (
                                  getFieldValue('type') !==
                                  LoanVariantType.SPLIT
                                ) {
                                  return Promise.resolve()
                                }

                                const portions: LoanVariantFormValues['portions'] =
                                  getFieldValue('portions')
                                const totalPercentage = portions.reduce(
                                  (acc, curr) =>
                                    acc + curr.extraRepaymentPercentage,
                                  0,
                                )

                                if (totalPercentage === 0) {
                                  return Promise.resolve()
                                }

                                if (totalPercentage !== 100) {
                                  return Promise.reject(
                                    '% of Extra Repayments must total to 100%',
                                  )
                                }

                                setFields([
                                  {
                                    name: [
                                      'portions',
                                      0,
                                      'extraRepaymentPercentage',
                                    ],
                                    errors: [],
                                  },
                                  {
                                    name: [
                                      'portions',
                                      1,
                                      'extraRepaymentPercentage',
                                    ],
                                    errors: [],
                                  },
                                ])

                                return Promise.resolve()
                              },
                            }),
                          ]}
                        >
                          <PercentageInput />
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                </Space>
              )}
            </Form.List>
          </div>
          <Row>
            <Col xs={12} sm={12} md={3} style={{ display: 'flex' }}>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                loading={isUpdating}
                style={{ marginRight: '1rem' }}
              >
                Save
              </Button>
              <Button
                onClick={onCancel}
                disabled={isUpdating}
                htmlType="button"
                size="large"
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Space>
      </Card>
    </Form>
  )
}

export default PropertyLoanVariantCreateForm
