import { RouteComponentProps } from '@reach/router'
import { Alert, Col, Row, Skeleton, Space, message, Button } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Debit } from '../../../shared/types/Debit'
import DebitCreateForm from '../components/DebitCreateForm'
import DebitForm from '../components/DebitForm'
import { FinancesContext } from '../components/FinancesContext'
import { useLazyQuery } from '../hooks/useLazyQuery'
import { APIRoute } from '../network'

const DebitList: React.FC<RouteComponentProps> = () => {
  const financesContext = useContext(FinancesContext)
  const [debits, setDebits] = useState<Debit[] | undefined>(undefined)
  const [createOpen, setCreateOpen] = useState(false)

  const {
    makeQuery: getDebits,
    data: debitsData,
    loading: debitsLoading,
    error: debitsError,
  } = useLazyQuery<Debit[]>(APIRoute.GetDebits)
  const {
    makeQuery: createDebit,
    data: createData,
    error: createError,
  } = useLazyQuery<Debit>(APIRoute.CreateDebit)
  const {
    makeQuery: updateDebit,
    data: updateData,
    error: updateError,
  } = useLazyQuery<Debit>(APIRoute.UpdateDebit)
  const {
    makeQuery: deleteDebit,
    data: deleteData,
    error: deleteError,
  } = useLazyQuery<Debit>(APIRoute.DeleteDebit)

  useEffect(() => {
    getDebits()
  }, [])

  useEffect(() => {
    if (debitsData) {
      setDebits(debitsData)
    }
  }, [debitsData])

  useEffect(() => {
    if (createData) {
      financesContext.setFinancesUpdated(financesContext.financesUpdated + 1)
      message.success('Saved')
      setCreateOpen(false)

      setDebits([...(debits || []), createData])
    }
  }, [createData])

  useEffect(() => {
    if (updateData && debits) {
      financesContext.setFinancesUpdated(financesContext.financesUpdated + 1)
      message.success('Saved')

      const debitIndex = debits.findIndex(debit => debit.id === updateData.id)

      if (debitIndex !== -1) {
        setDebits([
          ...debits.slice(0, debitIndex),
          updateData,
          ...debits.slice(debitIndex + 1),
        ])
      }
    }
  }, [updateData])

  useEffect(() => {
    if (deleteData && debits) {
      financesContext.setFinancesUpdated(financesContext.financesUpdated + 1)
      message.success('Deleted')

      const debitIndex = debits.findIndex(debit => debit.id === deleteData.id)

      if (debitIndex !== -1) {
        setDebits([
          ...debits.slice(0, debitIndex),
          ...debits.slice(debitIndex + 1),
        ])
      }
    }
  }, [deleteData])

  return (
    <>
      <Helmet>
        <title>Debits - Doorstep</title>
      </Helmet>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Skeleton loading={debitsLoading} active />
        {debitsError && (
          <Alert
            message="Error"
            description="There was an issue getting your debits. Please try again later."
            type="error"
            showIcon
          />
        )}
        {createError && (
          <Alert
            message="Error"
            description="There was an issue creating your debit. Please try again later."
            type="error"
            showIcon
          />
        )}
        {updateError && (
          <Alert
            message="Error"
            description="There was an issue updating your debit. Please try again later."
            type="error"
            showIcon
          />
        )}
        {deleteError && (
          <Alert
            message="Error"
            description="There was an issue deleting your debit. Please try again later."
            type="error"
            showIcon
          />
        )}
        {debits && (
          <>
            {debits.map(debit => (
              <Row key={debit.id}>
                <Col xs={24}>
                  <DebitForm
                    debit={debit}
                    onUpdate={values =>
                      updateDebit(
                        {
                          ...values,
                          startDate: new Date(
                            values.startDate.format('yyyy-MM-DD'),
                          ).toISOString(),
                          endDate: values.endDate
                            ? new Date(
                                values.endDate.format('yyyy-MM-DD'),
                              ).toISOString()
                            : undefined,
                        },
                        debit.id,
                      )
                    }
                    onDelete={() => deleteDebit(undefined, debit.id)}
                  />
                </Col>
              </Row>
            ))}
          </>
        )}
        {!debitsLoading && !createOpen && (
          <Button
            size="large"
            type="primary"
            onClick={() => setCreateOpen(true)}
          >
            Add Expense
          </Button>
        )}
        {createOpen && (
          <DebitCreateForm
            onCreate={values =>
              createDebit({
                ...values,
                startDate: new Date(
                  values.startDate.format('yyyy-MM-DD'),
                ).toISOString(),
                endDate: values.endDate
                  ? new Date(values.endDate.format('yyyy-MM-DD')).toISOString()
                  : undefined,
              })
            }
            onCancel={() => setCreateOpen(false)}
          />
        )}
      </Space>
    </>
  )
}

export default DebitList
