import { InputNumber, InputNumberProps } from 'antd'
import { Rule } from 'antd/lib/form'

const DollarInput: React.FC<InputNumberProps> = props => {
  return (
    <InputNumber
      {...props}
      size="large"
      formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
      style={{ width: '100%' }}
    />
  )
}

export const DollarInputRules: Rule[] = [
  {
    type: 'number',
    min: 0,
    message: 'Please input an amount greater than or equal to $0',
  },
]

export default DollarInput
