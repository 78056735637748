import { RouteComponentProps } from '@reach/router'
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Skeleton,
  Space,
} from 'antd'
import { authenticatedRoute } from '../components/AuthenticatedRoute'
import PageHeader from '../components/PageHeader'
import { Helmet } from 'react-helmet'
import { useContext, useEffect, useState } from 'react'
import { UserContext } from '../components/UserContext'
import { EditOutlined } from '@ant-design/icons'
import Modal from 'antd/lib/modal/Modal'
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updateEmail,
  updatePassword,
  updateProfile,
} from 'firebase/auth'
import Text from 'antd/lib/typography/Text'
// import { getFunctions, httpsCallable } from 'firebase/functions'

interface FormValues {
  name: string
  email: string
}

interface ResetPasswordFormValues {
  password: string
}

interface ReauthenticateFormValues {
  password: string
}

const errorMessages: { [key: string]: string } = {
  'auth/invalid-email': 'Incorrect email address.',
  'auth/wrong-password': 'Incorrect password.',
  'auth/user-not-found': 'Email address does not exist.',
  'auth/email-already-in-use':
    'Email address already in use, please use another email.',
  'auth/too-many-requests':
    'This email address has had too many failed attempts. Please try reset your password or try again later.',
  'auth/weak-password':
    'Please input a password that is minimum 6 characters in length.',
}

const Profile: React.FC<RouteComponentProps> = () => {
  const [form] = Form.useForm<FormValues>()
  const [resetPasswordForm] = Form.useForm<ResetPasswordFormValues>()
  const [reauthenticateForm] = Form.useForm<ReauthenticateFormValues>()
  const user = useContext(UserContext)
  const [formOpen, setFormOpen] = useState(false)
  const [resetPasswordFormOpen, setResetPasswordFormOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false)
  const [reauthenticateLoading, setReauthenticateLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [reAuthenticated, setReAuthenticated] = useState(false)
  const [modalCallbackType, setModalCallbackType] = useState<string | null>(
    null,
  )
  const [error, setError] = useState<string | null>(null)
  const [resetPasswordError, setResetPasswordError] = useState<string | null>(
    null,
  )
  const [reauthenticateError, setReauthenticateError] = useState<string | null>(
    null,
  )
  // const [subscriptionLoading, setSubscriptionLoading] = useState(false)

  // const subscription = async () => {
  //   setSubscriptionLoading(true)

  //   const functions = getFunctions(undefined, 'australia-southeast1')
  //   const functionRef = httpsCallable(
  //     functions,
  //     'ext-firestore-stripe-payments-createPortalLink',
  //     {},
  //   )

  //   const { data } = (await functionRef({
  //     returnUrl: window.location.href,
  //   })) as any
  //   window.location.assign(data.url)
  // }

  useEffect(() => {
    if (reAuthenticated && modalCallbackType) {
      if (modalCallbackType === 'email') {
        ;(async () => {
          if (!user) {
            return
          }

          try {
            setLoading(true)
            await updateEmail(user, form.getFieldValue('email'))

            message.success('Updated')
            setFormOpen(false)
          } catch (e: any) {
            if ('code' in e) {
              setError(
                errorMessages[e.code] ||
                  'There was an issue. Please try again.',
              )
            } else {
              setError('There was an issue. Please try again.')
            }
          }

          setLoading(false)
          user.reload()
        })()
      }

      if (modalCallbackType === 'password') {
        ;(async () => {
          if (!user) {
            return
          }

          try {
            setResetPasswordLoading(true)
            await updatePassword(
              user,
              resetPasswordForm.getFieldValue('password'),
            )

            message.success('Password has been reset')
            setResetPasswordFormOpen(false)
          } catch (e: any) {
            if ('code' in e) {
              setResetPasswordError(
                errorMessages[e.code] ||
                  'There was an issue. Please try again.',
              )
            } else {
              setResetPasswordError('There was an issue. Please try again.')
            }
          }

          setResetPasswordLoading(false)
          user.reload()
        })()
      }

      setReAuthenticated(false)
    }
  }, [reAuthenticated])

  useEffect(() => {
    if (resetPasswordFormOpen) {
      resetPasswordForm.setFieldsValue({ password: '' })
    } else {
      resetPasswordForm.setFieldsValue({ password: 'password' })
    }
  }, [resetPasswordFormOpen])

  const fieldDisabled = loading || !formOpen
  const resetPasswordFieldDisabled =
    resetPasswordLoading || !resetPasswordFormOpen

  return (
    <>
      <Helmet>
        <title>Profile - Doorstep</title>
      </Helmet>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Breadcrumb>
          <Breadcrumb.Item>Profile</Breadcrumb.Item>
        </Breadcrumb>
        <PageHeader title="Profile" />
        {!user ? (
          <Skeleton />
        ) : (
          <>
            <Form
              form={form}
              layout="vertical"
              name="profile"
              autoComplete="off"
              requiredMark="optional"
              initialValues={{
                name: user?.displayName,
                email: user?.email,
              }}
              onFinish={async values => {
                if (!user) {
                  return
                }

                setLoading(true)

                if (values.name !== user.displayName) {
                  await updateProfile(user, {
                    displayName: values.name,
                  })
                }

                setLoading(false)

                if (values.email !== user?.email) {
                  setModalOpen(true)

                  setModalCallbackType('email')
                } else {
                  message.success('Updated')
                  setFormOpen(false)

                  user.reload()
                }
              }}
            >
              <Card
                title="Details"
                bordered={false}
                className={`form-card${formOpen ? '' : ' view-mode'}`}
                extra={
                  <Button
                    onClick={() => setFormOpen(true)}
                    shape="circle"
                    icon={<EditOutlined />}
                    htmlType="button"
                    disabled={formOpen}
                  />
                }
              >
                <Space
                  direction="vertical"
                  size="middle"
                  style={{ width: '100%' }}
                >
                  {error && !loading && formOpen && (
                    <Alert
                      message="Error"
                      description={error}
                      type="error"
                      showIcon
                    />
                  )}
                  <Row>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        label="Email"
                        name="email"
                        style={{ margin: '0' }}
                        required
                        rules={[
                          {
                            required: true,
                            message: 'Please input email',
                          },
                          {
                            type: 'email',
                            message: 'Please input a valid email',
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          type="email"
                          disabled={
                            fieldDisabled ||
                            user.providerData?.[0].providerId === 'google.com'
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        label="Name"
                        name="name"
                        style={{ margin: '0' }}
                        required
                        rules={[
                          {
                            required: true,
                            message: 'Please input name',
                          },
                        ]}
                      >
                        <Input size="large" disabled={fieldDisabled} />
                      </Form.Item>
                    </Col>
                  </Row>
                  {formOpen && (
                    <Row>
                      <Col xs={12} sm={12} md={3} style={{ display: 'flex' }}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          size="large"
                          loading={loading}
                          style={{ marginRight: '1rem' }}
                        >
                          Save
                        </Button>
                        <Button
                          onClick={() => {
                            form.resetFields()
                            setFormOpen(false)
                          }}
                          disabled={loading}
                          htmlType="button"
                          size="large"
                        >
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                  )}
                </Space>
              </Card>
            </Form>
            {user.providerData?.[0].providerId === 'google.com' ? null : (
              <Form
                form={resetPasswordForm}
                layout="vertical"
                name="reset-password"
                autoComplete="off"
                requiredMark="optional"
                initialValues={{
                  password: 'password',
                }}
                onFinish={async () => {
                  if (!user) {
                    return
                  }

                  setResetPasswordLoading(true)
                  setModalOpen(true)
                  setModalCallbackType('password')
                  setResetPasswordLoading(false)
                }}
              >
                <Card
                  title="Reset Password"
                  bordered={false}
                  className={`form-card${
                    resetPasswordFormOpen ? '' : ' view-mode'
                  }`}
                  extra={
                    <Button
                      onClick={() => setResetPasswordFormOpen(true)}
                      shape="circle"
                      icon={<EditOutlined />}
                      htmlType="button"
                      disabled={resetPasswordFormOpen}
                    />
                  }
                >
                  <Space
                    direction="vertical"
                    size="middle"
                    style={{ width: '100%' }}
                  >
                    {resetPasswordError &&
                      !resetPasswordLoading &&
                      resetPasswordFormOpen && (
                        <Alert
                          message="Error"
                          description={resetPasswordError}
                          type="error"
                          showIcon
                        />
                      )}
                    <Row>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          label="Password"
                          name="password"
                          style={{ margin: '0' }}
                          required
                          rules={[
                            {
                              required: true,
                              message: 'Please input password',
                            },
                            {
                              min: 6,
                              message:
                                'Please input a password with minimum length of 6 characters',
                            },
                          ]}
                        >
                          <Input.Password
                            size="large"
                            type="password"
                            disabled={resetPasswordFieldDisabled}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    {resetPasswordFormOpen && (
                      <Row>
                        <Col xs={12} sm={12} md={3} style={{ display: 'flex' }}>
                          <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            loading={resetPasswordLoading}
                            style={{ marginRight: '1rem' }}
                          >
                            Reset Password
                          </Button>
                          <Button
                            onClick={() => {
                              form.resetFields()
                              setResetPasswordFormOpen(false)
                            }}
                            disabled={resetPasswordLoading}
                            htmlType="button"
                            size="large"
                          >
                            Cancel
                          </Button>
                        </Col>
                      </Row>
                    )}
                  </Space>
                </Card>
              </Form>
            )}
          </>
        )}
      </Space>
      <Modal
        title="Enter Password"
        visible={modalOpen}
        okText="Submit"
        onOk={() => reauthenticateForm.submit()}
        onCancel={() => setModalOpen(false)}
        okButtonProps={{ disabled: reauthenticateLoading }}
        cancelButtonProps={{ disabled: reauthenticateLoading }}
      >
        {reauthenticateError && !reauthenticateLoading && (
          <Alert
            message="Error"
            description={reauthenticateError}
            type="error"
            showIcon
          />
        )}
        <Form
          form={reauthenticateForm}
          layout="vertical"
          name="reauthenticate"
          autoComplete="off"
          requiredMark="optional"
          initialValues={{}}
          onFinish={async values => {
            if (!user) {
              return
            }

            setReauthenticateLoading(true)

            try {
              await reauthenticateWithCredential(
                user,
                EmailAuthProvider.credential(user.email || '', values.password),
              )

              setReAuthenticated(true)
              setModalOpen(false)
            } catch (e: any) {
              if ('code' in e) {
                setReauthenticateError(
                  errorMessages[e.code] ||
                    'There was an issue. Please try again.',
                )
              } else {
                setReauthenticateError('There was an issue. Please try again.')
              }
            }

            setReauthenticateLoading(false)
          }}
        >
          <Space direction="vertical" size="middle" style={{ width: '100%' }}>
            <Row>
              <Col xs={24}>
                <Text>
                  To update your email, please provide your current password
                </Text>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Form.Item
                  label="Password"
                  name="password"
                  style={{ margin: '0' }}
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Please input password',
                    },
                  ]}
                >
                  <Input size="large" type="password" disabled={loading} />
                </Form.Item>
              </Col>
            </Row>
          </Space>
        </Form>
      </Modal>
    </>
  )
}

export default authenticatedRoute(Profile)
