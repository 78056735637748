import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Select,
  Space,
  DatePicker,
  Popconfirm,
  AutoComplete,
} from 'antd'
import { useEffect, useState } from 'react'
import DollarInput, { DollarInputRules } from './DollarInput'
import { Debit, DebitFrequency } from '../../../shared/types/Debit'
import moment from 'moment'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'

type DebitFormValues = Pick<Debit, 'name' | 'amount' | 'frequency'> & {
  startDate: moment.Moment
  endDate?: moment.Moment
}

const getFormValues = (data: Debit): DebitFormValues => ({
  name: data.name,
  amount: data.amount,
  frequency: data.frequency,
  startDate: moment(data.startDate),
  endDate: data.endDate ? moment(data.endDate) : undefined,
})

const DebitForm: React.FC<{
  debit: Debit
  onUpdate: (debit: DebitFormValues) => void
  onDelete: () => void
}> = ({ debit, onUpdate, onDelete }) => {
  const [form] = Form.useForm<DebitFormValues>()
  const [formOpen, setFormOpen] = useState(false)
  const [formUpdated, setFormUpdated] = useState(0)
  const [isUpdating, setIsUpdating] = useState(false)

  useEffect(() => {
    if (debit && isUpdating) {
      form.setFieldsValue(getFormValues(debit))
      setFormOpen(false)
      setIsUpdating(false)
    }
  }, [debit])

  useEffect(() => {
    setFormUpdated(1)
  }, [])

  const fieldDisabled = isUpdating || !formOpen

  return (
    <Form
      form={form}
      layout="vertical"
      name="debit-update"
      autoComplete="off"
      requiredMark="optional"
      initialValues={getFormValues(debit)}
      onFinish={values => {
        setIsUpdating(true)
        onUpdate(values)
      }}
    >
      <Card
        bordered={false}
        className={`form-card${formOpen ? '' : ' view-mode'}`}
      >
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={5}>
              <Form.Item
                label="Name"
                name="name"
                style={{ margin: '0' }}
                required
                rules={[
                  {
                    required: true,
                    message: 'Please input name',
                  },
                ]}
              >
                <AutoComplete
                  disabled={fieldDisabled}
                  size="large"
                  options={[
                    {
                      value: 'Food - Groceries',
                    },
                    {
                      value: 'Food - Eating out',
                    },
                    {
                      value: 'Rates',
                    },
                    {
                      value: 'Water',
                    },
                    {
                      value: 'Gas',
                    },
                    {
                      value: 'Electricity',
                    },
                    {
                      value: 'Body Corporate',
                    },
                    {
                      value: 'Clothing / Personal care',
                    },
                    {
                      value: 'Mobile phone / home phone',
                    },
                    {
                      value: 'Car registration',
                    },
                    {
                      value: 'Petrol and other car expenses',
                    },
                    {
                      value: 'Transport',
                    },
                    {
                      value: 'Education',
                    },
                    {
                      value: 'Health',
                    },
                    {
                      value: 'Insurance - home / content',
                    },
                    {
                      value: 'Insurance - car',
                    },
                    {
                      value: 'Insurance - health',
                    },
                    {
                      value: 'Insurance - pet',
                    },
                    {
                      value: 'Entertainment',
                    },
                    {
                      value: 'Internet',
                    },
                    {
                      value: 'Chilcare',
                    },
                    {
                      value: 'Memberships',
                    },
                    {
                      value: 'Subscriptions',
                    },
                    {
                      value: 'Rent',
                    },
                    {
                      value: 'Pet food and care',
                    },
                    {
                      value: 'Holidays',
                    },
                    {
                      value: 'Other',
                    },
                  ]}
                  filterOption={(inputValue, option) =>
                    option!.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={5}>
              <Form.Item
                label="Amount"
                name="amount"
                style={{ margin: '0' }}
                required
                rules={[
                  ...DollarInputRules,
                  {
                    required: true,
                    message: 'Please input an amount',
                  },
                ]}
              >
                <DollarInput disabled={fieldDisabled} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={4}>
              <Form.Item
                label="Frequency"
                name="frequency"
                style={{ margin: '0' }}
                required
                rules={[
                  {
                    required: true,
                    message: 'Please select a frequency',
                  },
                ]}
              >
                <Select
                  size="large"
                  disabled={fieldDisabled}
                  onChange={() => setFormUpdated(formUpdated + 1)}
                >
                  <Select.Option value={DebitFrequency.WEEKLY}>
                    Weekly
                  </Select.Option>
                  <Select.Option value={DebitFrequency.FORTNIGHTLY}>
                    Fortnightly
                  </Select.Option>
                  <Select.Option value={DebitFrequency.MONTHLY}>
                    Monthly
                  </Select.Option>
                  <Select.Option value={DebitFrequency.YEARLY}>
                    Yearly
                  </Select.Option>
                  <Select.Option value={DebitFrequency.ONCE_OFF}>
                    Once Off
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={4}>
              <Form.Item
                label={
                  form.getFieldValue('frequency') === DebitFrequency.ONCE_OFF
                    ? 'Date'
                    : 'Start Date'
                }
                name="startDate"
                style={{ margin: '0' }}
                required
                rules={[
                  {
                    required: true,
                    message: 'Please input a date',
                  },
                ]}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  size="large"
                  style={{ width: '100%' }}
                  disabled={fieldDisabled}
                  placeholder=""
                  disabledDate={current => {
                    const endDate = form.getFieldValue(['endDate'])

                    return current && endDate && current > endDate
                  }}
                />
              </Form.Item>
            </Col>
            {form.getFieldValue('frequency') !== DebitFrequency.ONCE_OFF && (
              <Col xs={24} sm={12} md={4}>
                <Form.Item
                  label="End Date"
                  name="endDate"
                  style={{ margin: '0' }}
                >
                  <DatePicker
                    format="DD/MM/YYYY"
                    size="large"
                    style={{ width: '100%' }}
                    disabled={fieldDisabled}
                    placeholder=""
                    disabledDate={current => {
                      const startDate = form.getFieldValue(['startDate'])

                      return current && startDate && current < startDate
                    }}
                  />
                </Form.Item>
              </Col>
            )}
            <Col
              xs={3}
              sm={1}
              style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: 'auto',
              }}
            >
              <Button
                onClick={() => setFormOpen(true)}
                shape="circle"
                icon={<EditOutlined />}
                htmlType="button"
                disabled={formOpen}
              />
            </Col>
            <Col
              xs={3}
              sm={1}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Popconfirm
                title="Are you sure you would like to delete this expense? Please note this will delete this expense from all of your purchase plans."
                onConfirm={onDelete}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  shape="circle"
                  icon={<DeleteOutlined />}
                  htmlType="button"
                  disabled={formOpen}
                />
              </Popconfirm>
            </Col>
          </Row>
          {formOpen && (
            <Row>
              <Col xs={12} sm={12} md={3} style={{ display: 'flex' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={isUpdating}
                  style={{ marginRight: '1rem' }}
                >
                  Save
                </Button>
                <Button
                  onClick={() => {
                    form.resetFields()
                    setFormOpen(false)
                  }}
                  disabled={isUpdating}
                  htmlType="button"
                  size="large"
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          )}
        </Space>
      </Card>
    </Form>
  )
}

export default DebitForm
