import { DebitFrequency } from "./Debit";

export class PurchasePlan {
  constructor(
    public userID: string,
    public id: string,
    public name: string,
    public finance: PurchasePlanFinance,
    public property: PurchasePlanProperty,
    public loan: PurchasePlanLoan,
    public createdAt: Date,
    public cashReserve?: number,
    public equityReserve?: number,
    public target?: PurchasePlanTarget,
    public existingPropertyUsages?: PurchasePlanExistingPropertyUsage[],
    public moveInDate?: Date,
    public debits?: PurchasePlanDebit[],
    public interestOnlyYears?: number,
    public offsetContributions?: number
  ) {}
}

export enum PurchasePlanFinanceSource {
  CASH = "CASH",
  EQUITY = "EQUITY",
  SALE = "SALE",
  EQUITY_CASH = "EQUITY_CASH",
}

export enum PurchasePlanPropertyState {
  VIC = "VIC",
  NSW = "NSW",
  ACT = "ACT",
  QLD = "QLD",
  SA = "SA",
  NT = "NT",
  WA = "WA",
  TAS = "TAS",
}

export enum PurchasePlanPropertyStatus {
  ESTABLISHED_HOME = "ESTABLISHED_HOME",
  NEW_HOME = "NEW_HOME",
  VACANT_LAND = "VACANT_LAND",
}

export enum PurchasePlanPropertyType {
  PRIMARY_RESIDENCE = "PRIMARY_RESIDENCE",
  INVESTMENT = "INVESTMENT",
}

export enum PurchasePlanExistingPropertyUsageType {
  LIVE = "LIVE",
  LEASE = "LEASE",
}

export enum PurchasePlanStructuralType {
  HOUSE = "HOUSE",
  UNIT = "UNIT",
}

export interface PurchasePlanFinance {
  source: PurchasePlanFinanceSource;
  propertyIDs?: string[];
  fees?: PurchasePlanFinanceFees;
}

export interface PurchasePlanFinanceFees {
  // This is a percentage of the sale cost
  agent: number;
  conveyancing: number;
  marketing: number;
  lender: number;
  staging: number;
  auction: number;
  other: number;
}

export interface PurchasePlanProperty {
  state: PurchasePlanPropertyState;
  status: PurchasePlanPropertyStatus;
  firstHomeBuyer: boolean;
  type: PurchasePlanPropertyType;
  minimumDeposit: number;
  inflationRatePerAnnum: number;
  rentalYield?: number;
  market?: {
    suburb: string;
    bedrooms: number;
    structuralType: PurchasePlanStructuralType;
    targetPrice: number;
  };
}

export interface PurchasePlanLoan {
  interestRate: number;
  bufferSavings?: number;
}

export interface PurchasePlanTarget {
  price?: number;
  date?: Date;
}

export interface PurchasePlanExistingPropertyUsage {
  id: string;
  type: PurchasePlanExistingPropertyUsageType;
  rentPerWeek?: number;
}

export interface PurchasePlanDebitUpdatePayload {
  // A debit can be an existing debit, referenced by ID, or a new debit, where an ID does not yet exist
  debitID?: string;
  // If it is a new debit, ID should not be provided. If it is an existing debit, ID should be provided.
  id?: string;
  enabled: boolean;
  amount: number;
  name?: string;
  frequency?: DebitFrequency;
  endDate?: Date;
}

export interface PurchasePlanDebit {
  id: string;
  debitID?: string;
  enabled: boolean;
  amount: number;
  // If it is an adhoc debit then name is required, otherwise it is not required
  name?: string;
  frequency?: DebitFrequency;
  endDate?: Date;
}

export interface PurchasePlanCreatePayload {
  name: string;
  finance: PurchasePlanFinance;
  cashReserve?: number;
  equityReserve?: number;
  property: Omit<PurchasePlanProperty, "inflationRatePerAnnum"> & {
    inflationRatePerAnnum?: number;
  };
  loan: PurchasePlanLoan;
  existingPropertyUsages?: PurchasePlanExistingPropertyUsage[];
}

export interface PurchasePlanUpdatePayload {
  id: string;
  name?: string;
  finance?: PurchasePlanFinance;
  property?: Omit<PurchasePlanProperty, "market"> & {
    market?: PurchasePlanProperty["market"] | null;
  };
  loan?: PurchasePlanLoan;
  cashReserve?: number;
  equityReserve?: number;
  target?: Partial<PurchasePlanTarget>;
  existingPropertyUsages?: PurchasePlanExistingPropertyUsage[];
  moveInDate?: Date;
  debits?: PurchasePlanDebitUpdatePayload[];
  interestOnlyYears?: number;
  offsetContributions?: number;
}

export interface PurchasePlanDeletePayload {
  id: string;
}

export interface PurchasePlanGetPayload {
  id: string;
}

export interface PurchasePlanAffordabilityPayload {
  id: string;
}

export interface PurchasePlanAffordability {
  startDate: Date;
  endDate: Date;
  maximumAffordablePrice: number;
  deposit: number;
  cashDeposit: number;
  equityDeposit: number;
  purchasePriceToday: number;
  repayments: number;
  higherInterestRepayments: number;
  fees: number;
  saleFees: number;
  stampDuty: number;
  lmi: number;
  netAfterRepayments: number;
  credit: number;
  debit: number;
  rent: number;
  otherRepayments: number;
}
