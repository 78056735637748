export enum Routes {
  LOGIN = '/login',
  LOGOUT = '/logout',
  HOME = '/',
  FINANCES = '/finances',
  CASH_ACCOUNTS = '/cash-accounts',
  CREDITS = '/credits',
  DEBITS = '/debits',
  PROPERTIES = '/properties',
  PROPERTY_CREATE = '/properties/create',
  LOANS = '/loans',
  COMPARE_LOANS = '/loans/compare',
  PURCHASE_PLANS = '/purchase-plans',
  PURCHASE_PLAN_CREATE = '/purchase-plans/create',
  PLANS = '/plans',
  SIGNUP = '/signup',
  VERIFY_EMAIL = '/verify-email',
  PROFILE = '/profile',
  RESET_PASSWORD = '/reset-password',
}
