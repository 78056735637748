export interface LoanForecast {
  startDate: Date;
  endDate: Date;
  principal: number;
  repaymentTotal: number;
  interestTotal: number;
  savings: number;
  creditTotal: number;
  debitTotal: number;
  netGainOrLoss: number;
  extraRepayment: number;
  equity: number;
  estimatedEquityPurchasePrice: number;
  propertyValue: number;
  rentIncome: number;
  otherLoanRepayments: number;
}

export class Loan {
  constructor(
    public userID: string,
    public propertyID: string,
    public id: string,
    public isPrimary: boolean,
    public name: string,
    public type: LoanType,
    public amount: number,
    public startDate: Date,
    public years: number,
    public interestRate: number,
    public offsetContributions: number,
    public paymentFrequency: LoanPaymentFrequency,
    public principalAdjustments?: LoanPrincipalAdjustment[]
  ) {}
}

export interface LoanPrincipalAdjustment {
  id: string;
  date: Date;
  amount: number;
}

export enum LoanType {
  VARIABLE = "VARIABLE",
  FIXED = "FIXED",
}

export enum LoanRepaymentType {
  PRINCIPAL_INTEREST = "PRINCIPAL_INTEREST",
  INTEREST_ONLY = "INTEREST_ONLY",
}

export enum LoanPaymentFrequency {
  WEEKLY = "WEEKLY",
  FORTNIGHTLY = "FORTNIGHTLY",
  MONTHLY = "MONTHLY",
}

export interface LoanComparison {
  id: string;
  name: string;
  interestPaid: number;
  interestSaved: number;
  principalRemaining: number;
  principalPaid: number;
  extraPrincipalPaid: number;
  savingsBalance: number;
  extraSavings: number;
}
