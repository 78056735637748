import { RouteComponentProps, Link, navigate } from '@reach/router'
import {
  Alert,
  Col,
  Row,
  Space,
  Breadcrumb,
  Form,
  Input,
  message,
  Button,
  Card,
  DatePicker,
  Spin,
  Select,
  Modal,
} from 'antd'
import { createRef, useEffect, useState } from 'react'
import { Property, PropertyType } from '../../../shared/types/Property'
import PageHeader from '../components/PageHeader'
import { useLazyQuery } from '../hooks/useLazyQuery'
import { APIRoute } from '../network'
import { Routes } from '../routes'
import { authenticatedRoute } from '../components/AuthenticatedRoute'
import { Helmet } from 'react-helmet'
import moment from 'moment'
import PercentageInput from '../components/PercentageInput'
import { HelpLabel } from '../components/HelpLabel'
import { HelpText } from '../HelpText'
import DollarInput from '../components/DollarInput'
import Title from 'antd/lib/typography/Title'

type PropertyFormValues = Pick<
  Property,
  | 'address'
  | 'price'
  | 'estimatedRentPerWeek'
  | 'estimatedGrowthPerAnnum'
  | 'type'
> & {
  purchaseDate: moment.Moment
}

const PropertyCreate: React.FC<RouteComponentProps> = () => {
  const [form] = Form.useForm<PropertyFormValues>()
  const [formUpdated, setFormUpdated] = useState(0)
  const [autocomplete, setAutocomplete] = useState(undefined)
  const addressRef = createRef<Input>()
  const [marketDisclaimerOpen, setMarketDisclaimerOpen] = useState(false)

  const { makeQuery, data, loading, error } = useLazyQuery<Property>(
    APIRoute.CreateProperty,
  )
  const {
    makeQuery: getEstimates,
    data: estimatesData,
    loading: estimatesLoading,
  } = useLazyQuery<{
    price?: number
    estimatedRentPerWeek?: number
    estimatedGrowthPerAnnum?: number
    suburb?: string
    propertyCategory?: string
    beds?: number
    dateOfPurchase?: Date
  }>(APIRoute.GetAddressEstimates)

  useEffect(() => {
    if (data) {
      message.success('Saved')
      navigate(`${Routes.PROPERTIES}/${data.id}`)
    }
  }, [data])

  useEffect(() => {
    setFormUpdated(formUpdated + 1)
  }, [error])

  useEffect(() => {
    if (addressRef?.current?.input && !autocomplete) {
      const address = document.getElementById('address')

      if (!address) {
        return
      }

      const a = new (window as any).google.maps.places.Autocomplete(address, {
        componentRestrictions: { country: 'au' },
      })

      a.addListener('place_changed', () => {
        const address = a.getPlace().formatted_address

        form.setFieldsValue({
          address,
        })
        getEstimates(undefined, address)
      })

      setAutocomplete(a)
    }
  }, [addressRef])

  useEffect(() => {
    if (estimatesData) {
      form.setFieldsValue({
        purchaseDate: estimatesData.dateOfPurchase
          ? moment(estimatesData.dateOfPurchase)
          : undefined,
        price: estimatesData.price,
        estimatedRentPerWeek: estimatesData.estimatedRentPerWeek || 0,
        estimatedGrowthPerAnnum: estimatesData.estimatedGrowthPerAnnum || 0,
      })
    }
  }, [estimatesData])

  const fieldDisabled = loading || estimatesLoading

  return (
    <>
      <Helmet>
        <title>Add a Property - Doorstep</title>
      </Helmet>
      <Form
        form={form}
        layout="vertical"
        name="property-create"
        autoComplete="off"
        requiredMark="optional"
        onFinish={async values => {
          await makeQuery({
            type: values.type,
            purchaseDate: new Date(
              values.purchaseDate.format('yyyy-MM-DD'),
            ).toISOString(),
            address: values.address,
            price: values.price,
            estimatedRentPerWeek: values.estimatedRentPerWeek,
            estimatedGrowthPerAnnum:
              typeof values.estimatedGrowthPerAnnum === 'number'
                ? values.estimatedGrowthPerAnnum * 0.01
                : null,
          })
        }}
      >
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={Routes.PROPERTIES}>My Properties</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Add</Breadcrumb.Item>
          </Breadcrumb>
          <PageHeader title="Add a Property" />
          {error && (
            <Alert
              message="Error"
              description="There was an issue creating this property. Please try again later."
              type="error"
              showIcon
            />
          )}
          <Card title="Overview" bordered={false} className="form-card">
            <Spin
              spinning={estimatesLoading}
              tip="Fetching data on your property. This might take a few seconds."
            >
              <Space
                direction="vertical"
                size="middle"
                style={{ width: '100%' }}
              >
                <Row>
                  <Col xs={24} sm={12} md={12}>
                    <Form.Item
                      label="Address"
                      name="address"
                      style={{ margin: '0 1rem 0 0' }}
                      required
                      rules={[
                        {
                          required: true,
                          message: 'Please input address',
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        ref={addressRef}
                        id="address"
                        disabled={fieldDisabled}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                {estimatesData && (
                  <Row>
                    <Col xs={24}>
                      <Alert
                        message={
                          <>
                            The following fields have been pre populated based
                            on data retrieved about your property address.{' '}
                            {estimatesData.estimatedRentPerWeek &&
                            estimatesData.estimatedGrowthPerAnnum
                              ? `The rent per week and growth per annum are based on a ${
                                  estimatesData.beds
                                } bedroom ${estimatesData.propertyCategory?.toLowerCase()} in ${
                                  estimatesData.suburb
                                }.`
                              : ''}{' '}
                            <Link
                              to="#"
                              onClick={() => setMarketDisclaimerOpen(true)}
                            >
                              How are these calculated?
                            </Link>
                          </>
                        }
                        type="info"
                        showIcon
                      />
                    </Col>
                  </Row>
                )}
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={12} md={4}>
                    <Form.Item
                      label="Type"
                      name="type"
                      style={{ margin: '0' }}
                      required
                      rules={[
                        {
                          required: true,
                          message: 'Please select a type',
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        onChange={() => setFormUpdated(formUpdated + 1)}
                        disabled={fieldDisabled}
                      >
                        <Select.Option value={PropertyType.PRIMARY_RESIDENCE}>
                          Primary Residence
                        </Select.Option>
                        <Select.Option value={PropertyType.INVESTMENT}>
                          Investment
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={4}>
                    <Form.Item
                      label="Purchase Date"
                      name="purchaseDate"
                      style={{ margin: '0' }}
                      required
                      rules={[
                        {
                          required: true,
                          message: 'Please input a date',
                        },
                      ]}
                    >
                      <DatePicker
                        format="DD/MM/YYYY"
                        size="large"
                        style={{ width: '100%' }}
                        placeholder=""
                        disabled={fieldDisabled}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={4}>
                    <Form.Item
                      label={
                        <HelpLabel tooltip={HelpText.PropertyPurchasePrice}>
                          Purchase Price
                        </HelpLabel>
                      }
                      name="price"
                      style={{ margin: '0' }}
                      required
                      rules={[
                        {
                          required: true,
                          message: 'Please input a price',
                        },
                      ]}
                    >
                      <DollarInput disabled={fieldDisabled} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={5}>
                    <Form.Item
                      label={
                        <HelpLabel tooltip={HelpText.PropertyEstimatedRent}>
                          Estimated Rent Per Week
                        </HelpLabel>
                      }
                      name="estimatedRentPerWeek"
                      style={{ margin: '0' }}
                      required
                      rules={[
                        {
                          required: true,
                          message: 'Please input an amount',
                        },
                      ]}
                    >
                      <DollarInput disabled={fieldDisabled} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={5}>
                    <Form.Item
                      label={
                        <HelpLabel tooltip={HelpText.PropertyEstimatedGrowth}>
                          Estimated Growth Per Annum
                        </HelpLabel>
                      }
                      name="estimatedGrowthPerAnnum"
                      style={{ margin: '0' }}
                      required
                      rules={[
                        {
                          required: true,
                          message: 'Please input a percentage',
                        },
                        {
                          type: 'number',
                          min: 0,
                          message: 'Please input a percentage',
                        },
                      ]}
                    >
                      <PercentageInput disabled={fieldDisabled} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={12} md={3} style={{ display: 'flex' }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      size="large"
                      loading={loading}
                      style={{ marginRight: '1rem' }}
                    >
                      Save
                    </Button>
                    <Button
                      onClick={() => {
                        navigate(Routes.PROPERTIES)
                      }}
                      disabled={loading}
                      htmlType="button"
                      size="large"
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Space>
            </Spin>
          </Card>
        </Space>
      </Form>
      <Modal
        title="How are the property fields calculated?"
        visible={marketDisclaimerOpen}
        okText="OK"
        closable={false}
        onCancel={() => setMarketDisclaimerOpen(false)}
        onOk={() => setMarketDisclaimerOpen(false)}
      >
        <p>
          Doorstep cannot and does not contain finance advice. The finance
          information is provided for general informational and educational
          purposes only and is not a substitute for professional advice.
          Accordingly, before taking any actions based upon such information, we
          encourage you to consult with the appropriate professionals. We do not
          provide any kind of finance advice. THE USE OR RELIANCE OF ANY
          INFORMATION CONTAINED ON THE SITE IS SOLELY AT YOUR OWN RISK.
        </p>
        <Title level={4}>
          How is the purchase date, purchase price, estimated rent per week, and
          estimated growth per annum calculated?
        </Title>
        <ul>
          <li>
            <b>Purchase date: </b>Your property address is searched and if
            market data is found, the latest purchase date is selected and
            populated in Doorstep. Please note this might not be the date you
            actually purchased the property.
          </li>
          <li>
            <b>Purchase price: </b>Your property address is searched and if
            market data is found, the latest purchase price is selected and
            populated in Doorstep. Please note this might not be the price you
            actually purchased the property at.
          </li>
          <li>
            <b>Estimated rent per week: </b>Your property address is searched
            and if market data is found, the suburb, bedrooms, property type are
            searched to find the profile of your property. If this returns a
            match, the latest rental median price available is populated in
            Doorstep. For an example, please see:{' '}
            <a
              href="https://www.domain.com.au/suburb-profile/fitzroy-vic-3065"
              target="_blank"
            >
              https://www.domain.com.au/suburb-profile/fitzroy-vic-3065
            </a>
            . This data is kept as up to date as possible, but is not guaranteed
            to be completely up to date, nor completely accurate.
          </li>
          <li>
            <b>Estimated growth per annum: </b>Your property address is searched
            and if market data is found, the suburb, bedrooms, property type are
            searched to find the profile of your property. If this returns a
            match, the last 5 year's median sale prices are averaged and
            populated in Doorstep. For an example, please see:{' '}
            <a
              href="https://www.domain.com.au/suburb-profile/fitzroy-vic-3065"
              target="_blank"
            >
              https://www.domain.com.au/suburb-profile/fitzroy-vic-3065
            </a>
            . This data is kept as up to date as possible, but is not guaranteed
            to be completely up to date, nor completely accurate.
          </li>
        </ul>
      </Modal>
    </>
  )
}

export default authenticatedRoute(PropertyCreate)
