import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Link, RouteComponentProps } from '@reach/router'
import {
  Alert,
  Skeleton,
  List,
  Card,
  Badge,
  Button,
  Space,
  Breadcrumb,
  Popconfirm,
  message,
  Empty,
} from 'antd'
import { startCase } from 'lodash'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import {
  PurchasePlan,
  PurchasePlanFinanceSource,
} from '../../../shared/types/PurchasePlan'
import { authenticatedRoute } from '../components/AuthenticatedRoute'
import PageHeader from '../components/PageHeader'
import { useLazyQuery } from '../hooks/useLazyQuery'
import { APIRoute } from '../network'
import { Routes } from '../routes'

const PurchasePlanList: React.FC<RouteComponentProps> = () => {
  const {
    makeQuery: getPlans,
    data: plansData,
    loading: plansLoading,
    error: plansError,
  } = useLazyQuery<PurchasePlan[]>(APIRoute.GetPurchasePlans)
  const {
    makeQuery: deletePlan,
    data: deleteData,
    error: deleteError,
  } = useLazyQuery<PurchasePlan>(APIRoute.DeletePurchasePlan)

  useEffect(() => {
    getPlans()
  }, [])

  useEffect(() => {
    if (deleteData) {
      message.success('Deleted')
      getPlans()
    }
  }, [deleteData])

  return (
    <>
      <Helmet>
        <title>Purchase Plans - Doorstep</title>
      </Helmet>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Breadcrumb>
          <Breadcrumb.Item>Purchase Plans</Breadcrumb.Item>
        </Breadcrumb>
        <PageHeader title="Purchase Plans" />
        <Skeleton loading={plansLoading} active />
        {!plansLoading && (
          <Link to={`${Routes.PURCHASE_PLANS}/create`}>
            <Button size="large" type="primary">
              Create Purchase Plan
            </Button>
          </Link>
        )}
        {plansError && (
          <Alert
            message="Error"
            description="There was an issue getting your purchase plans. Please try again later."
            type="error"
            showIcon
          />
        )}
        {deleteError && (
          <Alert
            message="Error"
            description="There was an issue deleting the purchase plan. Please try again later."
            type="error"
            showIcon
          />
        )}
        {plansData &&
          (plansData.length > 0 ? (
            <List
              grid={{ gutter: 16, xs: 1, sm: 2, md: 4, lg: 4, xl: 4, xxl: 4 }}
              dataSource={plansData}
              locale={{
                emptyText: ' ',
              }}
              renderItem={plan => (
                <List.Item>
                  <Card
                    actions={[
                      <Link to={`${Routes.PURCHASE_PLANS}/${plan.id}`}>
                        <EditOutlined />
                      </Link>,
                      <Popconfirm
                        title="Are you sure you would like to delete this purchase plan?"
                        onConfirm={() => deletePlan(undefined, plan.id)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <DeleteOutlined />
                      </Popconfirm>,
                    ]}
                  >
                    <h3>{plan.name}</h3>
                    <Badge
                      color={
                        plan.finance.source === PurchasePlanFinanceSource.CASH
                          ? 'blue'
                          : plan.finance.source ===
                            PurchasePlanFinanceSource.EQUITY
                          ? 'gold'
                          : 'green'
                      }
                      text={startCase(plan.finance.source.toLowerCase())}
                    />
                    {plan.property.market && (
                      <p>
                        {plan.property.market.bedrooms} bedroom{' '}
                        {plan.property.market.structuralType.toLowerCase()} in{' '}
                        {plan.property.market.suburb}
                      </p>
                    )}
                  </Card>
                </List.Item>
              )}
            />
          ) : (
            <Empty
              description="You have no purchase plans. Please create a purchase plan to get started."
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          ))}
      </Space>
    </>
  )
}

export default authenticatedRoute(PurchasePlanList)
