import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Space,
  DatePicker,
  Popconfirm,
} from 'antd'
import { useEffect, useState } from 'react'
import DollarInput, { DollarInputRules } from './DollarInput'
import { LoanRentPeriod } from '../../../shared/types/LoanRentPeriod'
import moment from 'moment'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { addYears, isAfter, isBefore, isWithinInterval } from 'date-fns'
import isSameDay from 'date-fns/isSameDay'
import { Loan } from '../../../shared/types/Loan'

type PropertyLoanLoanRentPeriodFormValues = Pick<
  LoanRentPeriod,
  'amountPerWeek'
> & {
  startDate: moment.Moment
  endDate: moment.Moment
}

const getFormValues = (
  data: LoanRentPeriod,
): PropertyLoanLoanRentPeriodFormValues => ({
  amountPerWeek: data.amountPerWeek,
  startDate: moment(data.startDate),
  endDate: moment(data.endDate),
})

const PropertyLoanLoanRentPeriodForm: React.FC<{
  loan: Loan
  rentPeriod: LoanRentPeriod
  onUpdate: (rentPeriod: PropertyLoanLoanRentPeriodFormValues) => void
  onDelete: () => void
  invalidDateRanges: Interval[]
}> = ({ loan, rentPeriod, onUpdate, onDelete, invalidDateRanges }) => {
  const [form] = Form.useForm<PropertyLoanLoanRentPeriodFormValues>()
  const [formOpen, setFormOpen] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)

  useEffect(() => {
    if (rentPeriod && isUpdating) {
      form.setFieldsValue(getFormValues(rentPeriod))
      setFormOpen(false)
      setIsUpdating(false)
    }
  }, [rentPeriod])

  const fieldDisabled = isUpdating || !formOpen

  return (
    <Form
      form={form}
      layout="vertical"
      name="extra-repayment-update"
      autoComplete="off"
      requiredMark="optional"
      initialValues={getFormValues(rentPeriod)}
      onFinish={values => {
        setIsUpdating(true)
        onUpdate(values)
      }}
    >
      <Card
        bordered={false}
        className={`form-card${formOpen ? '' : ' view-mode'}`}
      >
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={5}>
              <Form.Item
                label="Amount Per Week"
                name="amountPerWeek"
                style={{ margin: '0' }}
                required
                rules={[
                  ...DollarInputRules,
                  {
                    required: true,
                    message: 'Please input an amount',
                  },
                ]}
              >
                <DollarInput disabled={fieldDisabled} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={4}>
              <Form.Item
                label="Start Date"
                name="startDate"
                style={{ margin: '0' }}
                required
                rules={[
                  {
                    required: true,
                    message: 'Please input a date',
                  },
                ]}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  size="large"
                  style={{ width: '100%' }}
                  disabled={fieldDisabled}
                  placeholder=""
                  disabledDate={current => {
                    if (
                      isBefore(current.toDate(), new Date(loan.startDate)) ||
                      isAfter(
                        current.toDate(),
                        addYears(new Date(loan.startDate), loan.years),
                      ) ||
                      invalidDateRanges.some(
                        range =>
                          isWithinInterval(current.toDate(), range) ||
                          isSameDay(current.toDate(), range.start) ||
                          isSameDay(current.toDate(), range.end),
                      )
                    ) {
                      return true
                    }

                    const endDate = form.getFieldValue(['endDate'])

                    return current && endDate && current > endDate
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={13}>
              <Form.Item
                label="End Date"
                name="endDate"
                style={{ margin: '0' }}
                required
                rules={[
                  {
                    required: true,
                    message: 'Please input a date',
                  },
                ]}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  size="large"
                  disabled={fieldDisabled}
                  placeholder=""
                  disabledDate={current => {
                    if (
                      isBefore(current.toDate(), new Date(loan.startDate)) ||
                      isAfter(
                        current.toDate(),
                        addYears(new Date(loan.startDate), loan.years),
                      ) ||
                      invalidDateRanges.some(
                        range =>
                          isWithinInterval(current.toDate(), range) ||
                          isSameDay(current.toDate(), range.start) ||
                          isSameDay(current.toDate(), range.end),
                      )
                    ) {
                      return true
                    }

                    const startDate = form.getFieldValue(['startDate'])

                    return current && startDate && current < startDate
                  }}
                />
              </Form.Item>
            </Col>
            <Col
              xs={3}
              sm={1}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Button
                onClick={() => setFormOpen(true)}
                shape="circle"
                icon={<EditOutlined />}
                htmlType="button"
                disabled={formOpen}
              />
            </Col>
            <Col
              xs={3}
              sm={1}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Popconfirm
                title="Are you sure you would like to delete this rent period?"
                onConfirm={onDelete}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  shape="circle"
                  icon={<DeleteOutlined />}
                  htmlType="button"
                  disabled={formOpen}
                />
              </Popconfirm>
            </Col>
          </Row>
          {formOpen && (
            <Row>
              <Col xs={12} sm={12} md={3} style={{ display: 'flex' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={isUpdating}
                  style={{ marginRight: '1rem' }}
                >
                  Save
                </Button>
                <Button
                  onClick={() => {
                    form.resetFields()
                    setFormOpen(false)
                  }}
                  disabled={isUpdating}
                  htmlType="button"
                  size="large"
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          )}
        </Space>
      </Card>
    </Form>
  )
}

export default PropertyLoanLoanRentPeriodForm
