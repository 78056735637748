import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Space,
  DatePicker,
  Popconfirm,
} from 'antd'
import { useEffect, useState } from 'react'
import DollarInput, { DollarInputRules } from './DollarInput'
import { CashAccount } from '../../../shared/types/CashAccount'
import moment from 'moment'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { HelpLabel } from './HelpLabel'
import { HelpText } from '../HelpText'

type CashAccountFormValues = Pick<CashAccount, 'name' | 'amount'> & {
  date: moment.Moment
}

const getFormValues = (data: CashAccount): CashAccountFormValues => ({
  name: data.name,
  amount: data.amount,
  date: moment(data.date),
})

const CashAccountForm: React.FC<{
  cashAccount: CashAccount
  onUpdate: (cashAccount: CashAccountFormValues) => void
  onDelete: () => void
}> = ({ cashAccount, onUpdate, onDelete }) => {
  const [form] = Form.useForm<CashAccountFormValues>()
  const [formOpen, setFormOpen] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)

  useEffect(() => {
    if (cashAccount && isUpdating) {
      form.setFieldsValue(getFormValues(cashAccount))
      setFormOpen(false)
      setIsUpdating(false)
    }
  }, [cashAccount])

  const fieldDisabled = isUpdating || !formOpen

  return (
    <Form
      form={form}
      layout="vertical"
      name="cash-account-update"
      autoComplete="off"
      requiredMark="optional"
      initialValues={getFormValues(cashAccount)}
      onFinish={values => {
        setIsUpdating(true)
        onUpdate(values)
      }}
    >
      <Card
        bordered={false}
        className={`form-card${formOpen ? '' : ' view-mode'}`}
      >
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={7}>
              <Form.Item
                label="Name"
                name="name"
                style={{ margin: '0' }}
                required
                rules={[
                  {
                    required: true,
                    message: 'Please input name',
                  },
                ]}
              >
                <Input size="large" disabled={fieldDisabled} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                label="Amount"
                name="amount"
                style={{ margin: '0' }}
                required
                rules={[
                  ...DollarInputRules,
                  {
                    required: true,
                    message: 'Please input an amount',
                  },
                ]}
              >
                <DollarInput disabled={fieldDisabled} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={7}>
              <Form.Item
                label={
                  <HelpLabel tooltip={HelpText.CashAccountDateAsAt}>
                    Date as at
                  </HelpLabel>
                }
                name="date"
                style={{ margin: '0' }}
                required
                rules={[
                  {
                    required: true,
                    message: 'Please input a date',
                  },
                ]}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  size="large"
                  style={{ width: '100%' }}
                  disabled={fieldDisabled}
                  placeholder=""
                  disabledDate={current => {
                    return current > moment()
                  }}
                />
              </Form.Item>
            </Col>
            <Col
              xs={3}
              sm={1}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Button
                onClick={() => setFormOpen(true)}
                shape="circle"
                icon={<EditOutlined />}
                htmlType="button"
                disabled={formOpen}
              />
            </Col>
            <Col
              xs={3}
              sm={1}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Popconfirm
                title="Are you sure you would like to delete your savings?"
                onConfirm={onDelete}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  shape="circle"
                  icon={<DeleteOutlined />}
                  htmlType="button"
                  disabled={formOpen}
                />
              </Popconfirm>
            </Col>
          </Row>
          {formOpen && (
            <Row>
              <Col xs={12} sm={12} md={3} style={{ display: 'flex' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={isUpdating}
                  style={{ marginRight: '1rem' }}
                >
                  Save
                </Button>
                <Button
                  onClick={() => {
                    form.resetFields()
                    setFormOpen(false)
                  }}
                  disabled={isUpdating}
                  htmlType="button"
                  size="large"
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          )}
        </Space>
      </Card>
    </Form>
  )
}

export default CashAccountForm
