import { useContext, useEffect, useState } from 'react'
import { Link, navigate, RouteComponentProps } from '@reach/router'
import { AuthContext } from '../components/AuthContext'
import {
  Alert,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Layout,
  Row,
  Space,
} from 'antd'
import { Helmet } from 'react-helmet'
import {
  createUserWithEmailAndPassword,
  updateProfile,
  GoogleAuthProvider,
  signInWithRedirect,
} from 'firebase/auth'
import Title from 'antd/lib/typography/Title'
import Text from 'antd/lib/typography/Text'
import { Routes } from '../routes'
import { UserContext } from '../components/UserContext'

const provider = new GoogleAuthProvider()

interface FormValues {
  email: string
  password: string
  name: string
}

const errorMessages: { [key: string]: string } = {
  'auth/email-already-in-use': 'Email address is already in use.',
  'auth/weak-password':
    'Please input a password that is minimum 6 characters in length.',
}

const Signup: React.FC<RouteComponentProps> = () => {
  const [form] = Form.useForm<FormValues>()
  const authContext = useContext(AuthContext)
  const user = useContext(UserContext)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    if (user) {
      if (user.emailVerified) {
        navigate('/')
      } else {
        navigate(Routes.HOME)
      }
    }
  }, [user])

  return (
    <>
      <Helmet>
        <title>Sign Up - Doorstep</title>
      </Helmet>
      <Layout>
        <Layout.Header style={{ background: 'none' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              marginRight: '3rem',
            }}
          >
            <img src="/logo-dark.png" height="24px" />
          </div>
        </Layout.Header>
        <Layout.Content>
          <Card
            style={{
              maxWidth: '500px',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: '10vh',
            }}
          >
            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
              <Title level={2} style={{ margin: 0 }}>
                Sign up
              </Title>
              {error && !loading && (
                <Alert
                  message="Error"
                  description={error}
                  type="error"
                  showIcon
                />
              )}
              <Form
                form={form}
                layout="vertical"
                name="debit-update"
                autoComplete="off"
                requiredMark="optional"
                initialValues={{}}
                onFinish={async values => {
                  if (!authContext.auth) {
                    setError('There was an issue. Please try again.')
                    return
                  }

                  try {
                    setLoading(true)

                    const user = await createUserWithEmailAndPassword(
                      authContext.auth,
                      values.email,
                      values.password,
                    )

                    await updateProfile(user.user, {
                      displayName: values.name,
                    })

                    navigate(Routes.HOME)
                  } catch (e: any) {
                    if ('code' in e) {
                      setError(
                        errorMessages[e.code] ||
                          'There was an issue. Please try again.',
                      )
                    } else {
                      setError('There was an issue. Please try again.')
                    }
                  }

                  setLoading(false)
                }}
              >
                <Space
                  direction="vertical"
                  size="middle"
                  style={{ width: '100%' }}
                >
                  <Row>
                    <Col xs={24}>
                      <Form.Item
                        label="Email"
                        name="email"
                        style={{ margin: '0' }}
                        required
                        rules={[
                          {
                            required: true,
                            message: 'Please input email',
                          },
                          {
                            type: 'email',
                            message: 'Please input a valid email',
                          },
                        ]}
                      >
                        <Input size="large" type="email" disabled={loading} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24}>
                      <Form.Item
                        label="Name"
                        name="name"
                        style={{ margin: '0' }}
                        required
                        rules={[
                          {
                            required: true,
                            message: 'Please input name',
                          },
                        ]}
                      >
                        <Input size="large" disabled={loading} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24}>
                      <Form.Item
                        label="Password"
                        name="password"
                        style={{ margin: '0' }}
                        required
                        rules={[
                          {
                            required: true,
                            message: 'Please input password',
                          },
                          {
                            min: 6,
                            message:
                              'Please input a password with minimum length of 6 characters',
                          },
                        ]}
                      >
                        <Input.Password
                          size="large"
                          type="password"
                          disabled={loading}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24}>
                      <Text type="secondary">
                        By clicking 'Sign up', you agree to our{' '}
                        <a href="https://doorstepapp.com.au/terms-and-conditions">
                          terms and conditions
                        </a>
                        , and have read and understand our{' '}
                        <a href="https://doorstepapp.com.au/privacy-policy">
                          privacy policy
                        </a>{' '}
                        and{' '}
                        <a href="https://doorstepapp.com.au/disclaimer">
                          disclaimer
                        </a>
                        .
                      </Text>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        loading={loading}
                        style={{ width: '100%' }}
                      >
                        Sign up
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24}>
                      <Divider plain style={{ margin: 0 }}>
                        or
                      </Divider>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24}>
                      <Button
                        type="ghost"
                        size="large"
                        style={{ width: '100%' }}
                        onClick={() =>
                          authContext.auth &&
                          signInWithRedirect(authContext.auth, provider)
                        }
                      >
                        <img
                          src="/google.png"
                          height="20px"
                          style={{ marginRight: '0.5rem' }}
                        />
                        Sign up with Google
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24}>
                      <Text>
                        Already have an account?{' '}
                        <Link to={Routes.LOGIN}>Login</Link>
                      </Text>
                    </Col>
                  </Row>
                </Space>
              </Form>
            </Space>
          </Card>
        </Layout.Content>
      </Layout>
    </>
  )
}

export default Signup
