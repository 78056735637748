import { Button, Col, DatePicker, Form, Row } from 'antd'
import moment, { Moment } from 'moment'
import { useEffect } from 'react'
import { Loan } from '../../../shared/types/Loan'

interface CompareLoansFormValues {
  startDate: Moment
  endDate: Moment
}

const CompareLoansForm: React.FC<{
  loan?: Loan
  onUpdate: ({
    startDate,
    endDate,
  }: {
    startDate: string
    endDate: string
  }) => void
}> = ({ loan, onUpdate }) => {
  const [form] = Form.useForm()

  useEffect(() => {
    const localStart = localStorage.getItem('compareStartDate')
    const localEnd = localStorage.getItem('compareEndDate')

    form.setFieldsValue({
      startDate: localStart
        ? moment(localStart)
        : loan
        ? moment(loan.startDate)
        : '',
      endDate: localEnd
        ? moment(localEnd)
        : loan
        ? moment(loan.startDate).add(loan.years, 'year')
        : '',
    })
  }, [])

  const onFinish = (values: CompareLoansFormValues) => {
    const startDate = new Date(
      values.startDate.format('yyyy-MM-DD'),
    ).toISOString()
    const endDate = new Date(values.endDate.format('yyyy-MM-DD')).toISOString()

    localStorage.setItem('compareStartDate', startDate)
    localStorage.setItem('compareEndDate', endDate)

    onUpdate({
      startDate,
      endDate,
    })
  }

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        name="compareLoans"
        autoComplete="off"
        requiredMark="optional"
        onFinish={onFinish}
      >
        <div>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={4}>
              <Form.Item
                label="Start Date"
                name="startDate"
                style={{ margin: '0' }}
                required
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  size="large"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={4}>
              <Form.Item
                label="End Date"
                name="endDate"
                style={{ margin: '0' }}
                required
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  size="large"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col
              xs={24}
              md={12}
              sm={4}
              style={{
                display: 'flex',
                alignItems: 'flex-end',
              }}
            >
              <Form.Item
                name="submit"
                style={{
                  margin: '0 1rem 0 0',
                }}
              >
                <Button type="primary" size="large" htmlType="submit">
                  Filter
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </>
  )
}

export default CompareLoansForm
