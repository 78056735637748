import { LocationProvider, Redirect, Router } from '@reach/router'
import { AuthenticationProvider } from './components/AuthContext'
import { UserProvider } from './components/UserContext'
import CompareLoans from './pages/CompareLoans'
import Finances from './pages/Finances'
import CreditList from './pages/CreditList'
import PurchasePlanCreate from './pages/PurchasePlanCreate'
import PurchasePlanList from './pages/PurchasePlanList'
import PurchasePlanView from './pages/PurchasePlanView'
import { Routes } from './routes'
import DebitList from './pages/DebitList'
import CashAccountList from './pages/CashAccountList'
import PropertyList from './pages/PropertyList'
import PropertyView from './pages/PropertyView'
import SignIn from './pages/SignIn'
import Logout from './pages/Logout'
import PropertyCreate from './pages/PropertyCreate'
import LoanCreate from './pages/LoanCreate'
import Home from './pages/Home'
import { FinancesProvider } from './components/FinancesContext'
import PlanSelect from './pages/PlanSelect'
import Signup from './pages/Signup'
import Profile from './pages/Profile'
import ResetPassword from './pages/ResetPassword'

const App: React.FC = () => (
  <LocationProvider>
    <AuthenticationProvider>
      <UserProvider>
        <FinancesProvider>
          <Router>
            <PlanSelect path={Routes.PLANS} />
            <SignIn path={Routes.LOGIN} />
            <ResetPassword path={Routes.RESET_PASSWORD} />
            <Signup path={Routes.SIGNUP} />
            {/*<VerifyEmail path={Routes.VERIFY_EMAIL} />*/}
            <Logout path={Routes.LOGOUT} />
            <Profile path={Routes.PROFILE} />
            <Home path={Routes.HOME} />
            <Finances path={Routes.FINANCES}>
              <CashAccountList path={Routes.CASH_ACCOUNTS} />
              <CreditList path={Routes.CREDITS} />
              <DebitList path={Routes.DEBITS} />
              <Redirect
                from="/"
                to={`${Routes.FINANCES}${Routes.CASH_ACCOUNTS}`}
                noThrow
              />
            </Finances>
            <PropertyList path={Routes.PROPERTIES} />
            <PropertyCreate path={Routes.PROPERTY_CREATE} />
            <LoanCreate
              path={`${Routes.PROPERTIES}/:propertyID/loans/create`}
            />
            <CompareLoans
              path={`${Routes.PROPERTIES}/:propertyID/loans/compare`}
            />
            <PropertyView path={`${Routes.PROPERTIES}/:id/*`} />
            <CompareLoans path={Routes.COMPARE_LOANS} />
            <PurchasePlanList path={Routes.PURCHASE_PLANS} />
            <PurchasePlanCreate path={Routes.PURCHASE_PLAN_CREATE} />
            <PurchasePlanView path={`${Routes.PURCHASE_PLANS}/:id/*`} />
            <Redirect from="/" to={Routes.FINANCES} noThrow />
          </Router>
        </FinancesProvider>
      </UserProvider>
    </AuthenticationProvider>
  </LocationProvider>
)

export default App
