import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Link, RouteComponentProps } from '@reach/router'
import {
  Alert,
  Skeleton,
  List,
  Card,
  Button,
  Space,
  Breadcrumb,
  Popconfirm,
  message,
  Empty,
  Result,
} from 'antd'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Property } from '../../../shared/types/Property'
import { authenticatedRoute } from '../components/AuthenticatedRoute'
import PageHeader from '../components/PageHeader'
import { useLazyQuery } from '../hooks/useLazyQuery'
import { APIRoute } from '../network'
import { Routes } from '../routes'

const PropertyList: React.FC<RouteComponentProps> = () => {
  const [loadingUpgrade, setLoadingUpgrade] = useState(false)

  const {
    makeQuery: getProperties,
    data: propertiesData,
    loading: propertiesLoading,
    error: propertiesError,
  } = useLazyQuery<Property[]>(APIRoute.GetProperties)
  const {
    makeQuery: deleteProperty,
    data: deleteData,
    error: deleteError,
  } = useLazyQuery<Property>(APIRoute.DeleteProperty)

  useEffect(() => {
    getProperties()
  }, [])

  useEffect(() => {
    if (deleteData) {
      message.success('Deleted')
      getProperties()
    }
  }, [deleteData])

  const upgrade = async () => {
    setLoadingUpgrade(true)

    const functions = getFunctions(undefined, 'australia-southeast1')
    const functionRef = httpsCallable(
      functions,
      'ext-firestore-stripe-payments-createPortalLink',
      {},
    )

    const { data } = (await functionRef({
      returnUrl: window.location.href,
    })) as any
    window.location.assign(data.url)
  }

  return (
    <>
      <Helmet>
        <title>My Properties - Doorstep</title>
      </Helmet>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Breadcrumb>
          <Breadcrumb.Item>My Properties</Breadcrumb.Item>
        </Breadcrumb>
        <PageHeader title="My Properties" />
        <Skeleton loading={propertiesLoading} active />
        {propertiesError && propertiesError.name === 'RequiresUpgradeError' ? (
          <Result
            title="Unauthorised"
            subTitle="To create and manage your property portfolio, you'll need to be subscribed to the home owner plan. To upgrade, click below."
            extra={
              <Button type="primary" onClick={upgrade} loading={loadingUpgrade}>
                Manage my subscription
              </Button>
            }
          />
        ) : (
          <>
            {!propertiesLoading && (
              <Link to={`${Routes.PROPERTIES}/create`}>
                <Button size="large" type="primary">
                  Add a Property
                </Button>
              </Link>
            )}

            {propertiesError && (
              <Alert
                message="Error"
                description="There was an issue getting your properties. Please try again later."
                type="error"
                showIcon
              />
            )}
            {deleteError && (
              <Alert
                message="Error"
                description="There was an issue deleting the property. Please try again later."
                type="error"
                showIcon
              />
            )}
            {propertiesData &&
              (propertiesData.length > 0 ? (
                <List
                  grid={{
                    gutter: 16,
                    xs: 1,
                    sm: 2,
                    md: 4,
                    lg: 4,
                    xl: 4,
                    xxl: 4,
                  }}
                  dataSource={propertiesData}
                  renderItem={property => (
                    <List.Item>
                      <Card
                        actions={[
                          <Link to={`${Routes.PROPERTIES}/${property.id}`}>
                            <EditOutlined />
                          </Link>,
                          <Popconfirm
                            title="Are you sure you would like to delete this property? Deleting this property will delete all loans and any purchase plans that are financed by the sale of or equity in this property."
                            onConfirm={() =>
                              deleteProperty(undefined, property.id)
                            }
                            okText="Yes"
                            cancelText="No"
                          >
                            <DeleteOutlined />
                          </Popconfirm>,
                        ]}
                      >
                        <h3>{property.address}</h3>
                      </Card>
                    </List.Item>
                  )}
                />
              ) : (
                <Empty
                  description="You have no properties. Please create a property to get started."
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              ))}
          </>
        )}
      </Space>
    </>
  )
}

export default authenticatedRoute(PropertyList)
