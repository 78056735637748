import { Link, navigate, RouteComponentProps } from '@reach/router'
import {
  Alert,
  Form,
  Card,
  Select,
  Button,
  Space,
  message,
  Row,
  Col,
  Input,
  Checkbox,
  Breadcrumb,
  Modal,
  List,
  Skeleton,
} from 'antd'
import { Helmet } from 'react-helmet'
import { createRef, useEffect, useState } from 'react'
import { Property } from '../../../shared/types/Property'
import {
  PurchasePlan,
  PurchasePlanExistingPropertyUsageType,
  PurchasePlanFinance,
  PurchasePlanFinanceFees,
  PurchasePlanFinanceSource,
  PurchasePlanLoan,
  PurchasePlanProperty,
  PurchasePlanPropertyStatus,
  PurchasePlanPropertyType,
  PurchasePlanStructuralType,
} from '../../../shared/types/PurchasePlan'
import { authenticatedRoute } from '../components/AuthenticatedRoute'
import DollarInput, { DollarInputRules } from '../components/DollarInput'
import { HelpLabel } from '../components/HelpLabel'
import PageHeader from '../components/PageHeader'
import PercentageInput, {
  PercentageInputRules,
} from '../components/PercentageInput'
import { HelpText } from '../HelpText'
import { useLazyQuery } from '../hooks/useLazyQuery'
import { APIRoute } from '../network'
import { Routes } from '../routes'
import Title from 'antd/lib/typography/Title'

type PurchasePlanFormValues = Pick<
  PurchasePlan,
  | 'name'
  | 'cashReserve'
  | 'equityReserve'
  | 'offsetContributions'
  | 'interestOnlyYears'
> &
  Omit<PurchasePlanFinance, 'fees'> &
  PurchasePlanFinanceFees &
  Omit<PurchasePlanProperty, 'market'> &
  PurchasePlanLoan &
  PurchasePlanProperty['market'] & {
    suburb?: string
    properties?: {
      id: string
      leased: boolean
      rentPerWeek?: number
    }[]
  }

const PurchasePlanCreate: React.FC<RouteComponentProps> = () => {
  const [form] = Form.useForm<PurchasePlanFormValues>()
  const [formUpdated, setFormUpdated] = useState(0)
  const [autocomplete, setAutocomplete] = useState<any>(undefined)
  const addressRef = createRef<Input>()
  const [suburbValue, setSuburbValue] = useState('')
  const [saleFeesOpen, setSaleFeesOpen] = useState(false)

  const handlePlaceChanged = () => {
    if (!autocomplete) {
      return
    }

    const address = autocomplete.getPlace().formatted_address

    form.setFieldsValue({
      suburb: address,
    })
    setSuburbValue(address)

    setFormUpdated(formUpdated + 1)
  }

  const { makeQuery, data, loading, error } = useLazyQuery<PurchasePlan>(
    APIRoute.CreatePurchasePlan,
  )

  const {
    makeQuery: getProperties,
    data: propertiesData,
    loading: propertiesLoading,
    error: propertiesError,
  } = useLazyQuery<Property[]>(APIRoute.GetProperties)

  useEffect(() => {
    getProperties()
  }, [])

  useEffect(() => {
    if (data) {
      message.success('Saved')
      navigate(`${Routes.PURCHASE_PLANS}/${data.id}`)
    }
  }, [data])

  useEffect(() => {
    setFormUpdated(formUpdated + 1)
  }, [error])

  useEffect(() => {
    if (addressRef?.current?.input && !autocomplete) {
      const address = document.getElementById('address')

      if (!address) {
        return
      }

      const a = new (window as any).google.maps.places.Autocomplete(address, {
        componentRestrictions: { country: 'au' },
        types: ['(cities)'],
      })

      setAutocomplete(a)
    }
  }, [addressRef])

  useEffect(() => {
    if (!propertiesData) {
      return
    }

    if (form.getFieldValue('properties')?.length === propertiesData.length) {
      return
    }

    form.setFieldsValue({
      properties: propertiesData.map(property => ({
        id: property.id,
        leased: false,
        rentPerWeek: property?.estimatedRentPerWeek || 0,
      })),
    })
  }, [propertiesData])

  useEffect(() => {
    if (autocomplete) {
      autocomplete.addListener('place_changed', handlePlaceChanged)
    }
  }, [autocomplete])

  if (propertiesLoading) {
    return <Skeleton />
  }

  return (
    <>
      <Helmet>
        <title>Create Purchase Plan - Doorstep</title>
      </Helmet>
      <Form
        form={form}
        layout="vertical"
        name="purchase-plan-create"
        autoComplete="off"
        requiredMark="optional"
        initialValues={{
          agent: 2.5,
          conveyancing: 1400,
          marketing: 5500,
          lender: 825,
          staging: 3000,
          auction: 700,
          other: 0,
          rentalYield: 3,
          equityReserve: 0,
          cashReserve: 0,
          minimumDeposit: 20,
          firstHomeBuyer: propertiesData?.length === 0,
        }}
        onFinish={async values => {
          const market = suburbValue && values.bedrooms && values.structuralType

          await makeQuery({
            name: values.name,
            finance: {
              source: values.source,
              propertyIDs:
                values.source !== PurchasePlanFinanceSource.CASH
                  ? values.propertyIDs
                  : undefined,
              fees:
                values.source === PurchasePlanFinanceSource.SALE
                  ? {
                      agent: values.agent * 0.01,
                      conveyancing: values.conveyancing,
                      marketing: values.marketing,
                      lender: values.lender,
                      staging: values.staging,
                      auction: values.auction,
                      other: values.other,
                    }
                  : undefined,
            },
            property: {
              state: values.state,
              status: values.status,
              firstHomeBuyer: values.firstHomeBuyer,
              type: values.type,
              minimumDeposit: values.minimumDeposit * 0.01,
              inflationRatePerAnnum: market
                ? undefined
                : values.inflationRatePerAnnum * 0.01,
              rentalYield:
                values.type === PurchasePlanPropertyType.INVESTMENT &&
                values.rentalYield
                  ? values.rentalYield * 0.01
                  : undefined,
              market: market
                ? {
                    suburb: suburbValue,
                    bedrooms: Number(values.bedrooms),
                    structuralType: values.structuralType,
                  }
                : undefined,
            },
            loan: {
              interestRate: values.interestRate * 0.01,
              bufferSavings: values.bufferSavings,
            },
            cashReserve:
              values.source !== PurchasePlanFinanceSource.EQUITY
                ? values.cashReserve
                : undefined,
            equityReserve:
              values.source === PurchasePlanFinanceSource.EQUITY ||
              values.source === PurchasePlanFinanceSource.EQUITY_CASH
                ? (values.equityReserve || 0) * 0.01
                : undefined,
            interestOnlyYears: values.interestOnlyYears,
            offsetContributions: values.offsetContributions
              ? values.offsetContributions * 0.01
              : undefined,
            existingPropertyUsages: values.properties?.map(
              (property, index) => ({
                id: (propertiesData || [])[index]?.id,
                type: property.leased
                  ? PurchasePlanExistingPropertyUsageType.LEASE
                  : PurchasePlanExistingPropertyUsageType.LIVE,
                rentPerWeek: property.leased ? property.rentPerWeek : undefined,
              }),
            ),
          })
        }}
      >
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={Routes.PURCHASE_PLANS}>Purchase Plans</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Create</Breadcrumb.Item>
          </Breadcrumb>
          <PageHeader title="Create Purchase Plan" />
          {error && (
            <Alert
              message="Error"
              description="There was an issue creating this purchase plan. Please try again later."
              type="error"
              showIcon
            />
          )}
          <Card title="Overview" bordered={false} className="form-card">
            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={6}>
                  <Form.Item
                    label="Name"
                    name="name"
                    style={{ margin: '0' }}
                    required
                    rules={[
                      {
                        required: true,
                        message: 'Please input name',
                      },
                    ]}
                  >
                    <Input size="large" disabled={loading} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={6}>
                  <Form.Item
                    label={
                      <HelpLabel tooltip={HelpText.PlanFinanceSource}>
                        Finance Source
                      </HelpLabel>
                    }
                    name="source"
                    style={{ margin: '0' }}
                    required
                    rules={[
                      {
                        required: true,
                        message: 'Please select a finance source',
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      onChange={() => setFormUpdated(formUpdated + 1)}
                      disabled={loading}
                    >
                      <Select.Option value={PurchasePlanFinanceSource.CASH}>
                        Cash
                      </Select.Option>
                      {propertiesData && propertiesData.length > 0 && (
                        <>
                          <Select.Option
                            value={PurchasePlanFinanceSource.EQUITY}
                          >
                            Equity
                          </Select.Option>
                          <Select.Option
                            value={PurchasePlanFinanceSource.EQUITY_CASH}
                          >
                            Equity and Cash
                          </Select.Option>
                          <Select.Option value={PurchasePlanFinanceSource.SALE}>
                            Sale
                          </Select.Option>
                        </>
                      )}
                    </Select>
                  </Form.Item>
                </Col>
                {form.getFieldValue('source') !==
                  PurchasePlanFinanceSource.CASH &&
                  (!propertiesError ||
                    propertiesError.name !== 'RequiresUpgradeError') && (
                    <Col xs={24} sm={12} md={10}>
                      {propertiesError &&
                        propertiesError.name !== 'RequiresUpgradeError' && (
                          <Alert
                            message="There was an issue retrieving your properties."
                            type="error"
                          />
                        )}
                      <Form.Item
                        label="Properties"
                        name="propertyIDs"
                        style={{ margin: '0 1rem 0 0' }}
                        required
                        rules={[
                          {
                            required: true,
                            message: 'Please select at least one property',
                          },
                        ]}
                      >
                        <Select
                          mode="multiple"
                          allowClear
                          size="large"
                          loading={propertiesLoading}
                          disabled={loading}
                        >
                          {(propertiesData || []).map(property => (
                            <Select.Option
                              key={property.id}
                              value={property.id}
                            >
                              {property.address}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                {form.getFieldValue('source') !==
                  PurchasePlanFinanceSource.EQUITY && (
                  <Col xs={24} sm={12} md={6}>
                    <Form.Item
                      label={
                        <HelpLabel tooltip={HelpText.CashReserve}>
                          Cash Buffer
                        </HelpLabel>
                      }
                      name="cashReserve"
                      style={{ margin: '0 1rem 0 0' }}
                      required
                      rules={[
                        {
                          type: 'number',
                          min: 0,
                          message:
                            'Please input an amount greater than or equal to 0',
                        },
                        {
                          required: true,
                          message: 'Please input a cash reserve amount',
                        },
                      ]}
                    >
                      <DollarInput disabled={loading} />
                    </Form.Item>
                  </Col>
                )}
                {(form.getFieldValue('source') ===
                  PurchasePlanFinanceSource.EQUITY ||
                  form.getFieldValue('source') ===
                    PurchasePlanFinanceSource.EQUITY_CASH) && (
                  <Col xs={24} sm={12} md={6}>
                    <Form.Item
                      label={
                        <HelpLabel tooltip={HelpText.EquityReserve}>
                          Equity Buffer
                        </HelpLabel>
                      }
                      name="equityReserve"
                      style={{ margin: '0 1rem 0 0' }}
                      required
                      rules={[
                        {
                          type: 'number',
                          min: 0,
                          message:
                            'Please input a percentage greater than or equal to 0%',
                        },
                        {
                          type: 'number',
                          max: 100,
                          message:
                            'Please input a percentage less than or equal to 100%',
                        },
                      ]}
                    >
                      <PercentageInput disabled={loading} />
                    </Form.Item>
                  </Col>
                )}
              </Row>
              {form.getFieldValue('source') ===
                PurchasePlanFinanceSource.SALE && (
                <>
                  <Row gutter={[16, 16]}>
                    <h3>
                      <HelpLabel tooltip={HelpText.PurchaseSaleFees}>
                        Sale Fees
                      </HelpLabel>
                    </h3>
                  </Row>
                  <Row>
                    <Col xs={24}>
                      <Link to="#" onClick={() => setSaleFeesOpen(true)}>
                        How are sale fee defaults calculated?
                      </Link>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={12} md={3}>
                      <Form.Item
                        label="Agent Fees"
                        name="agent"
                        style={{ margin: '0 1rem 0 0' }}
                        rules={[
                          {
                            type: 'number',
                            min: 0,
                            message: 'Please input a percentage',
                          },
                        ]}
                        required
                      >
                        <PercentageInput disabled={loading} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={3}>
                      <Form.Item
                        label="Conveyancing Fees"
                        name="conveyancing"
                        style={{ margin: '0 1rem 0 0' }}
                        required
                        rules={[
                          ...DollarInputRules,
                          {
                            required: true,
                            message: 'Please input an amount',
                          },
                        ]}
                      >
                        <DollarInput disabled={loading} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={3}>
                      <Form.Item
                        label="Marketing Fees"
                        name="marketing"
                        style={{ margin: '0 1rem 0 0' }}
                        required
                        rules={[
                          ...DollarInputRules,
                          {
                            required: true,
                            message: 'Please input an amount',
                          },
                        ]}
                      >
                        <DollarInput disabled={loading} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={3}>
                      <Form.Item
                        label="Lender Fees"
                        name="lender"
                        style={{ margin: '0 1rem 0 0' }}
                        required
                        rules={[
                          ...DollarInputRules,
                          {
                            required: true,
                            message: 'Please input an amount',
                          },
                        ]}
                      >
                        <DollarInput disabled={loading} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={3}>
                      <Form.Item
                        label="Staging Fees"
                        name="staging"
                        style={{ margin: '0 1rem 0 0' }}
                        required
                        rules={[
                          ...DollarInputRules,
                          {
                            required: true,
                            message: 'Please input an amount',
                          },
                        ]}
                      >
                        <DollarInput disabled={loading} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={3}>
                      <Form.Item
                        label="Auction Fees"
                        name="auction"
                        style={{ margin: '0 1rem 0 0' }}
                        required
                        rules={[
                          ...DollarInputRules,
                          {
                            required: true,
                            message: 'Please input an amount',
                          },
                        ]}
                      >
                        <DollarInput disabled={loading} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={3}>
                      <Form.Item
                        label="Other Fees"
                        name="other"
                        style={{ margin: '0 1rem 0 0' }}
                        required
                        rules={[
                          ...DollarInputRules,
                          {
                            required: true,
                            message: 'Please input an amount',
                          },
                        ]}
                      >
                        <DollarInput disabled={loading} />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
            </Space>
          </Card>
          <Card title="Market" bordered={false} className="form-card">
            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    label="Suburb"
                    name="suburb"
                    style={{ margin: '0 1rem 0 0' }}
                  >
                    <Input
                      size="large"
                      ref={addressRef}
                      id="address"
                      onChange={() => {
                        if (!form.getFieldValue('suburb')) {
                          form.setFieldsValue({
                            suburb: '',
                            bedrooms: undefined,
                            structuralType: undefined,
                          })
                          setSuburbValue('')
                          setFormUpdated(formUpdated + 1)
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={3}>
                  <Form.Item
                    label="Beds"
                    name="bedrooms"
                    required={form.getFieldValue('suburb')}
                    style={{ margin: '0' }}
                  >
                    <Select
                      size="large"
                      disabled={loading || !form.getFieldValue('suburb')}
                    >
                      <Select.Option value="1">1</Select.Option>
                      <Select.Option value="2">2</Select.Option>
                      <Select.Option value="3">3</Select.Option>
                      <Select.Option value="4">4</Select.Option>
                      <Select.Option value="5">5</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={5}>
                  <Form.Item
                    label="Type"
                    name="structuralType"
                    required={form.getFieldValue('suburb')}
                    style={{ margin: '0' }}
                  >
                    <Select
                      size="large"
                      disabled={loading || !form.getFieldValue('suburb')}
                    >
                      <Select.Option value={PurchasePlanStructuralType.HOUSE}>
                        House
                      </Select.Option>
                      <Select.Option value={PurchasePlanStructuralType.UNIT}>
                        Unit
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Space>
          </Card>
          <Card title="Property" bordered={false} className="form-card">
            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={5}>
                  <Form.Item
                    label="State"
                    name="state"
                    style={{ margin: '0' }}
                    required
                    rules={[
                      {
                        required: true,
                        message: 'Please select a state',
                      },
                    ]}
                  >
                    <Select size="large" disabled={loading}>
                      <Select.Option value="VIC">Victoria</Select.Option>
                      <Select.Option value="NSW">New South Wales</Select.Option>
                      <Select.Option value="ACT">ACT</Select.Option>
                      <Select.Option value="QLD">Queensland</Select.Option>
                      <Select.Option value="SA">South Australia</Select.Option>
                      <Select.Option value="NT">
                        Northern Territory
                      </Select.Option>
                      <Select.Option value="WA">
                        Western Australia
                      </Select.Option>
                      <Select.Option value="TAS">Tasmania</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={5}>
                  <Form.Item
                    label={
                      <HelpLabel tooltip={HelpText.PlanStatus}>
                        Status
                      </HelpLabel>
                    }
                    name="status"
                    style={{ margin: '0' }}
                    required
                    rules={[
                      {
                        required: true,
                        message: 'Please select a status',
                      },
                    ]}
                  >
                    <Select size="large" disabled={loading}>
                      <Select.Option
                        value={PurchasePlanPropertyStatus.ESTABLISHED_HOME}
                      >
                        Established Home
                      </Select.Option>
                      <Select.Option
                        value={PurchasePlanPropertyStatus.NEW_HOME}
                      >
                        New Home
                      </Select.Option>
                      <Select.Option
                        value={PurchasePlanPropertyStatus.VACANT_LAND}
                      >
                        Land
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={3}>
                  <Form.Item
                    label="First Home Buyer"
                    name="firstHomeBuyer"
                    valuePropName="checked"
                    style={{ margin: '0' }}
                    required
                  >
                    <Checkbox disabled={loading} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={5}>
                  <Form.Item
                    label={
                      <HelpLabel tooltip={HelpText.PlanPropertyType}>
                        Property Type
                      </HelpLabel>
                    }
                    name="type"
                    style={{ margin: '0' }}
                    required
                    rules={[
                      {
                        required: true,
                        message: 'Please select a type',
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      disabled={loading}
                      onChange={() => setFormUpdated(formUpdated + 1)}
                    >
                      <Select.Option
                        value={PurchasePlanPropertyType.PRIMARY_RESIDENCE}
                      >
                        Primary Residence
                      </Select.Option>
                      <Select.Option
                        value={PurchasePlanPropertyType.INVESTMENT}
                      >
                        Investment
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                {form.getFieldValue('type') ===
                  PurchasePlanPropertyType.INVESTMENT && (
                  <Col xs={24} sm={12} md={5}>
                    <Form.Item
                      label={
                        <HelpLabel tooltip={HelpText.RentalYield}>
                          Gross Rental Yield
                        </HelpLabel>
                      }
                      name="rentalYield"
                      style={{ margin: '0 1rem 0 0' }}
                      required
                      rules={[
                        {
                          required: true,
                          message: 'Please input a percentage',
                        },
                        {
                          type: 'number',
                          min: 0,
                          message:
                            'Please input a percentage greater than or equal to 0%',
                        },
                        {
                          type: 'number',
                          max: 100,
                          message:
                            'Please input a percentage less than or equal to 100%',
                        },
                      ]}
                    >
                      <PercentageInput disabled={loading} />
                    </Form.Item>
                  </Col>
                )}
              </Row>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={5}>
                  <Form.Item
                    label={
                      <HelpLabel tooltip={HelpText.PlanMinimumDeposit}>
                        Minimum Deposit
                      </HelpLabel>
                    }
                    name="minimumDeposit"
                    style={{ margin: '0' }}
                    required
                    rules={[
                      {
                        type: 'number',
                        min: 5,
                        message:
                          'Please input a percentage greater than or equal to 5%',
                      },
                      {
                        required: true,
                        message: 'Please input a percentage',
                      },
                    ]}
                  >
                    <PercentageInput disabled={loading} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={5}>
                  <Form.Item
                    tooltip={
                      form.getFieldValue('suburb')
                        ? 'This field will be calculated based on the suburb, bedrooms, and property type you have selected above once you submit this form.'
                        : null
                    }
                    label={
                      <HelpLabel tooltip={HelpText.PlanGrowthRate}>
                        Growth Per Annum
                      </HelpLabel>
                    }
                    name="inflationRatePerAnnum"
                    style={{ margin: '0' }}
                    required={!form.getFieldValue('suburb')}
                    rules={[
                      ...PercentageInputRules,
                      form.getFieldValue('suburb')
                        ? {}
                        : {
                            required: true,
                            message: 'Please input a percentage',
                          },
                    ]}
                  >
                    <PercentageInput
                      disabled={loading || form.getFieldValue('suburb')}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Space>
          </Card>
          <Card title="Loan" bordered={false} className="form-card">
            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={3}>
                  <Form.Item
                    label="Interest Rate"
                    name="interestRate"
                    style={{ margin: '0' }}
                    required
                    rules={[
                      ...PercentageInputRules,
                      {
                        required: true,
                        message: 'Please input a percentage',
                      },
                    ]}
                  >
                    <PercentageInput disabled={loading} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={7}>
                  <Form.Item
                    label={
                      <HelpLabel tooltip={HelpText.PlanBufferSavings}>
                        Monthly Savings Buffer
                      </HelpLabel>
                    }
                    name="bufferSavings"
                    style={{ margin: '0' }}
                    rules={[
                      {
                        type: 'number',
                        min: 0,
                        message: 'Please input an amount',
                      },
                    ]}
                  >
                    <DollarInput disabled={loading} />
                  </Form.Item>
                </Col>
              </Row>
            </Space>
          </Card>
          {propertiesData && propertiesData.length > 0 && (
            <Card
              title={
                <HelpLabel tooltip={HelpText.PlanTargetExistingProperties}>
                  Existing Properties
                </HelpLabel>
              }
              bordered={false}
              className="form-card"
            >
              <Space
                direction="vertical"
                size="middle"
                style={{ width: '100%' }}
              >
                <Form.List name="properties">
                  {properties => (
                    <Col xs={24}>
                      <List
                        itemLayout="horizontal"
                        dataSource={properties}
                        renderItem={usage => (
                          <Space
                            key={usage.key}
                            direction="vertical"
                            size="small"
                            style={{ width: '100%', marginBottom: '0.5rem' }}
                          >
                            <Row gutter={[16, 16]}>
                              <Col xs={24} sm={12} md={4}>
                                <b style={{ lineHeight: 2.2 }}>
                                  {
                                    propertiesData?.find(
                                      property =>
                                        property.id ===
                                        form.getFieldValue([
                                          'properties',
                                          usage.name,
                                          'id',
                                        ]),
                                    )?.address
                                  }
                                </b>
                              </Col>
                              <Col xs={24} sm={12} md={2}>
                                <Form.Item
                                  label="Lease"
                                  name={[usage.name, 'leased']}
                                  fieldKey={[usage.fieldKey, 'leased']}
                                  valuePropName="checked"
                                  style={{ margin: '0 1rem 0 0' }}
                                  required
                                >
                                  <Checkbox
                                    disabled={loading}
                                    onChange={() =>
                                      setFormUpdated(formUpdated + 1)
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              {form.getFieldValue([
                                'properties',
                                usage.fieldKey,
                                'leased',
                              ]) && (
                                <Col xs={24} sm={12} md={5}>
                                  <Form.Item
                                    label="Rent Per Week"
                                    name={[usage.name, 'rentPerWeek']}
                                    fieldKey={[usage.fieldKey, 'rentPerWeek']}
                                    style={{ margin: '0' }}
                                    required={form.getFieldValue([
                                      'properties',
                                      usage.name,
                                      'leased',
                                    ])}
                                    rules={
                                      form.getFieldValue([
                                        'properties',
                                        usage.name,
                                        'leased',
                                      ])
                                        ? [
                                            {
                                              type: 'number',
                                              min: 0.01,
                                              message:
                                                'Please input an amount greater than $0',
                                            },
                                            {
                                              required: true,
                                              message: 'Please input an amount',
                                            },
                                          ]
                                        : []
                                    }
                                  >
                                    <DollarInput
                                      disabled={
                                        !form.getFieldValue([
                                          'properties',
                                          usage.name,
                                          'leased',
                                        ])
                                      }
                                      onChange={() =>
                                        setFormUpdated(formUpdated + 1)
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                              )}
                            </Row>
                          </Space>
                        )}
                      />
                    </Col>
                  )}
                </Form.List>
              </Space>
            </Card>
          )}
          <Row>
            <Col xs={12} sm={12} md={3} style={{ display: 'flex' }}>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                loading={loading}
                style={{ marginRight: '1rem' }}
              >
                Create
              </Button>
              <Button
                onClick={() => {
                  navigate(Routes.PURCHASE_PLANS)
                }}
                disabled={loading}
                htmlType="button"
                size="large"
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Space>
      </Form>
      <Modal
        title="Sale fee defaults calculations"
        visible={saleFeesOpen}
        okText="OK"
        closable={false}
        onCancel={() => setSaleFeesOpen(false)}
        onOk={() => setSaleFeesOpen(false)}
      >
        <p>
          Doorstep cannot and does not contain finance advice. The finance
          information is provided for general informational and educational
          purposes only and is not a substitute for professional advice.
          Accordingly, before taking any actions based upon such information, we
          encourage you to consult with the appropriate professionals. We do not
          provide any kind of finance advice. THE USE OR RELIANCE OF ANY
          INFORMATION CONTAINED ON THE SITE IS SOLELY AT YOUR OWN RISK.
        </p>
        <Title level={4}>How are these sale fee defaults calculated?</Title>
        <p>
          When selling a property, there are many fees to consider before
          calculating the profit you could make.
        </p>
        <p>The default fees provided by Doorstep are as follows:</p>
        <ul>
          <li>
            <b>Agent Fees: 2.5%</b> - Commission that the real estate agent
            takes, usually as a percentage of the sale price.
          </li>
          <li>
            <b>Conveyancing Fees: $1,400</b> - Costs of hiring a licensed
            conveyancer or solicitor to transfer legal ownership of the property
            to the buyer.
          </li>
          <li>
            <b>Marketing Fees: $5,500</b> - Costs of placing advertising boards
            and online listings etc. to market your property.
          </li>
          <li>
            <b>Lender Fees: $825</b> - Mortgage discharge cost with your current
            lender. Only applies if you are exiting out of your existing
            mortgage.
          </li>
          <li>
            <b>Staging Fees: $3,000</b> - Costs to style and place furniture in
            your property while it is on the market.
          </li>
          <li>
            <b>Auction Fees: $700</b> - Costs for real estate agents to host an
            auction for your property. Only applies if your property goes to
            auction.
          </li>
          <li>
            <b>Other Fees: $0</b> - No other costs are considered by Doorstep,
            but you may have additional costs you'd like to add.
          </li>
        </ul>
        <p>
          For more information on these costs and how they vary state to state,
          please see:{' '}
          <a
            href="https://www.realestate.com.au/advice/how-much-does-it-cost-to-sell-a-house/"
            target="_blank"
          >
            https://www.realestate.com.au/advice/how-much-does-it-cost-to-sell-a-house/
          </a>
        </p>
        <p>
          Doorstep's defaults are an average of cost ranges across all states
          listed in the article linked above. There is no differentiation
          between states, please consider this when applying your sale costs for
          a purchase plan.
        </p>
      </Modal>
    </>
  )
}

export default authenticatedRoute(PurchasePlanCreate)
