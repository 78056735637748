import { Link, navigate, RouteComponentProps } from '@reach/router'
import {
  Alert,
  Form,
  Card,
  Select,
  Button,
  Space,
  message,
  Row,
  Col,
  Input,
  Checkbox,
  Breadcrumb,
  InputNumber,
  DatePicker,
} from 'antd'
import { useEffect, useState } from 'react'
import { Property } from '../../../shared/types/Property'
import {
  Loan,
  LoanPaymentFrequency,
  LoanType,
} from '../../../shared/types/Loan'
import { authenticatedRoute } from '../components/AuthenticatedRoute'
import DollarInput from '../components/DollarInput'
import PageHeader from '../components/PageHeader'
import PercentageInput, {
  PercentageInputRules,
} from '../components/PercentageInput'
import { useLazyQuery } from '../hooks/useLazyQuery'
import { APIRoute } from '../network'
import { Routes } from '../routes'
import { HelpText } from '../HelpText'
import { HelpLabel } from '../components/HelpLabel'
import { Helmet } from 'react-helmet'

type LoanFormValues = Omit<
  Loan,
  'userID' | 'propertyID' | 'id' | 'startDate'
> & { startDate: moment.Moment }

const LoanCreate: React.FC<RouteComponentProps<{ propertyID: string }>> = ({
  propertyID,
}) => {
  const [form] = Form.useForm<LoanFormValues>()
  const [formUpdated, setFormUpdated] = useState(0)

  const { makeQuery, data, loading, error } = useLazyQuery<Loan>(
    APIRoute.CreateLoan,
  )
  const { makeQuery: getProperty, data: propertyData } = useLazyQuery<Property>(
    APIRoute.GetProperty,
  )
  const { makeQuery: getLoans, data: loansData } = useLazyQuery<Loan[]>(
    APIRoute.GetLoans,
  )

  useEffect(() => {
    getProperty(undefined, propertyID)
    getLoans(undefined, propertyID)
  }, [])

  useEffect(() => {
    if (data) {
      message.success('Saved')
      navigate(
        `${Routes.PROPERTIES}/${data.propertyID}${Routes.LOANS}/${data.id}`,
      )
    }
  }, [data])

  useEffect(() => {
    setFormUpdated(formUpdated + 1)
  }, [error])

  useEffect(() => {
    if (loansData?.length === 0) {
      form.setFieldsValue({
        isPrimary: true,
      })
    }
  }, [loansData])

  return (
    <>
      <Helmet>
        <title>Create Loan - Doorstep</title>
      </Helmet>
      <Form
        form={form}
        layout="vertical"
        name="loan-create"
        autoComplete="off"
        requiredMark="optional"
        onFinish={async values => {
          await makeQuery(
            {
              ...values,
              startDate: new Date(
                values.startDate.format('yyyy-MM-DD'),
              ).toISOString(),
              isPrimary: values.isPrimary || false,
              interestRate: values.interestRate * 0.01,
              offsetContributions: values.offsetContributions * 0.01,
            },
            propertyID,
          )
        }}
      >
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={Routes.PROPERTIES}>My Properties</Link>
            </Breadcrumb.Item>
            {propertyData && (
              <Breadcrumb.Item>{propertyData.address}</Breadcrumb.Item>
            )}
            <Breadcrumb.Item>
              <Link to={`${Routes.PROPERTIES}/${propertyID}/loans`}>Loans</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Create</Breadcrumb.Item>
          </Breadcrumb>
          <PageHeader title="Create Loan" />
          {error && (
            <Alert
              message="Error"
              description="There was an issue creating this loan. Please try again later."
              type="error"
              showIcon
            />
          )}
          <Card title="Loan" bordered={false} className="form-card">
            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
              <Row>
                <Col xs={24}>
                  <Alert
                    message="This represents the base loan for the entirety of the term. If you have temporary changes to the loan (eg. refinancing, changing the long structure for 1-5 years), you can add these changes once the loan is created. Commonly this should reflect a 30 year loan."
                    type="info"
                    showIcon
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={12} md={4}>
                  <Form.Item
                    label="Name"
                    name="name"
                    style={{ margin: '0 1rem 0 0' }}
                    required
                    rules={[
                      {
                        required: true,
                        message: 'Please input a name',
                      },
                    ]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={3}>
                  <Form.Item
                    label={
                      <HelpLabel tooltip={HelpText.PrimaryLoan}>
                        Primary Loan
                      </HelpLabel>
                    }
                    name="isPrimary"
                    valuePropName="checked"
                    style={{ margin: '0 1rem 0 0' }}
                    required
                  >
                    <Checkbox disabled={loansData?.length === 0} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={4}>
                  <Form.Item
                    label={
                      <HelpLabel tooltip={HelpText.LoanType}>Type</HelpLabel>
                    }
                    name="type"
                    style={{ margin: '0 1rem 0 0' }}
                    required
                    rules={[
                      {
                        required: true,
                        message: 'Please select a type',
                      },
                    ]}
                  >
                    <Select size="large">
                      <Select.Option value={LoanType.VARIABLE}>
                        Variable
                      </Select.Option>
                      <Select.Option value={LoanType.FIXED}>
                        Fixed
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={12} md={4}>
                  <Form.Item
                    label={
                      <HelpLabel tooltip={HelpText.LoanPaymentFrequency}>
                        Payment Frequency
                      </HelpLabel>
                    }
                    name="paymentFrequency"
                    style={{ margin: '0 1rem 0 0' }}
                    required
                    rules={[
                      {
                        required: true,
                        message: 'Please select a frequency',
                      },
                    ]}
                  >
                    <Select size="large">
                      <Select.Option value={LoanPaymentFrequency.MONTHLY}>
                        Monthly
                      </Select.Option>
                      <Select.Option value={LoanPaymentFrequency.FORTNIGHTLY}>
                        Fortnightly
                      </Select.Option>
                      {/* <Select.Option value={LoanPaymentFrequency.WEEKLY}>
                    Weekly
                  </Select.Option> */}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={4}>
                  <Form.Item
                    label={
                      <HelpLabel tooltip={HelpText.LoanStartingPrincipal}>
                        Starting Principal
                      </HelpLabel>
                    }
                    name="amount"
                    style={{ margin: '0 1rem 0 0' }}
                    required
                    rules={[
                      {
                        type: 'number',
                        min: 0.01,
                        message: 'Please input an amount greater than $0',
                      },
                      {
                        required: true,
                        message: 'Please input an amount',
                      },
                    ]}
                  >
                    <DollarInput />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={3}>
                  <Form.Item
                    label="Start Date"
                    name="startDate"
                    style={{ margin: '0 1rem 0 0' }}
                    required
                    rules={[
                      {
                        required: true,
                        message: 'Please input a date',
                      },
                    ]}
                  >
                    <DatePicker
                      format="DD/MM/YYYY"
                      size="large"
                      style={{ width: '100%' }}
                      onChange={() => setFormUpdated(formUpdated + 1)}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={2}>
                  <Form.Item
                    label="Years"
                    name="years"
                    style={{ margin: '0 1rem 0 0' }}
                    required
                    rules={[
                      {
                        type: 'integer',
                        min: 1,
                        message: 'Please input whole loan years',
                      },
                      {
                        type: 'integer',
                        max: 30,
                        message:
                          'Please input loan years less than or equal to 30 years',
                      },
                      {
                        required: true,
                        message: 'Please input loan years',
                      },
                    ]}
                  >
                    <InputNumber size="large" style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={3}>
                  <Form.Item
                    label="Interest Rate"
                    name="interestRate"
                    style={{ margin: '0 1rem 0 0' }}
                    required
                    rules={[
                      ...PercentageInputRules,
                      {
                        required: true,
                        message: 'Please input a percentage',
                      },
                      {
                        type: 'number',
                        max: 100,
                        message:
                          'Please input a percentage less than or equal to 100%',
                      },
                    ]}
                  >
                    <PercentageInput />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={4}>
                  <Form.Item
                    label={
                      <HelpLabel tooltip={HelpText.LoanOffsetContributions}>
                        Offset Contributions
                      </HelpLabel>
                    }
                    name="offsetContributions"
                    style={{ margin: '0 1rem 0 0' }}
                    required
                    rules={[
                      {
                        required: true,
                        message: 'Please input a percentage',
                      },
                      {
                        type: 'number',
                        max: 100,
                        message:
                          'Please input a percentage less than or equal to 100%',
                      },
                    ]}
                  >
                    <PercentageInput />
                  </Form.Item>
                </Col>
              </Row>
            </Space>
          </Card>
          <Row>
            <Col xs={12} sm={12} md={3} style={{ display: 'flex' }}>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                loading={loading}
                style={{ marginRight: '1rem' }}
              >
                Create Loan
              </Button>
              <Button
                onClick={() => {
                  navigate(`${Routes.PROPERTIES}/${propertyID}/loans`)
                }}
                disabled={loading}
                htmlType="button"
                size="large"
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Space>
      </Form>
    </>
  )
}

export default authenticatedRoute(LoanCreate)
