export interface PropertyValueAdjustment {
  id: string;
  date: Date;
  amount: number;
}

export class Property {
  constructor(
    public userID: string,
    public id: string,
    public type: PropertyType,
    public address: string,
    public purchaseDate: Date,
    public price: number,
    public estimatedRentPerWeek: number,
    public estimatedGrowthPerAnnum: number,
    public valueAdjustments?: PropertyValueAdjustment[]
  ) {}
}

export enum PropertyType {
  PRIMARY_RESIDENCE = "PRIMARY_RESIDENCE",
  INVESTMENT = "INVESTMENT",
}
