import { Card, Row, Col, Space, Skeleton } from 'antd'
import { format } from 'date-fns'
import Title from 'antd/lib/typography/Title'
import { LoanForecast } from '../../../shared/types/Loan'
import { currencyFormatter } from '../util'
import { Line } from '@ant-design/charts'
import { HelpText } from '../HelpText'
import { HelpLabel } from './HelpLabel'

const LoanForecastGraph: React.FC<{
  results: LoanForecast[]
  loading: boolean
}> = ({ results, loading }) => {
  const loanConfig = {
    data: results,
    xField: 'endDate',
    yField: 'principal',
    xAxis: {
      tickCount: 10,
      label: {
        formatter: (date: string) => format(new Date(date), 'yyyy'),
      },
      title: {
        text: 'Date',
      },
    },
    yAxis: {
      label: {
        formatter: (price: any) => currencyFormatter.format(price),
      },
    },
    legend: { position: 'top' as any },
    animation: {
      appear: {
        animation: 'path-in',
        duration: 500,
      },
    },
    slider: {
      start: 0,
      end: 1,
      formatter: (date: string) => format(new Date(date), 'yyyy'),
    },
    tooltip: {
      customContent: (date: any) => {
        const result = results.find(({ endDate }) => endDate === date)

        if (!result) {
          return ''
        }

        return `
          <div class="graph-tooltip-row">
            <b>${format(new Date(result.endDate), 'MMM yyyy')}</b>
          </div>

          <div class="graph-tooltip-row">
            <div class="graph-tooltip-row-label">Principal:</div>
            <div>${currencyFormatter.format(result.principal)}</div>
          </div>
        `
      },
    },
  }

  const statistics = {
    paidOffDate: results.find(month => month.principal === 0)?.endDate,
    noInterestDate: results.find(month => month.interestTotal === 0)?.endDate,
    interestPaid:
      results.reduce((acc, curr) => acc + curr.interestTotal, 0) || 0,
  }

  if (results.length === 0 && loading) {
    return <Skeleton loading active />
  }

  return (
    <Card
      title={<HelpLabel tooltip={HelpText.LoanResults}>Results</HelpLabel>}
      bordered={false}
      className="form-card"
      loading={loading}
    >
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Row gutter={[16, 16]}>
          {statistics.paidOffDate && (
            <Col xs={12} md={8}>
              <p>
                <HelpLabel tooltip={HelpText.LoanResultFullyPaidOff}>
                  Will be fully paid off in
                </HelpLabel>
              </p>
              <Title level={4}>
                {format(new Date(statistics.paidOffDate), 'LLL yyyy')}
              </Title>
            </Col>
          )}
          {statistics.noInterestDate && (
            <Col xs={12} md={8}>
              <p>
                <HelpLabel tooltip={HelpText.LoanResultZeroInterest}>
                  Will pay $0 interest from
                </HelpLabel>
              </p>
              <Title level={4}>
                {format(new Date(statistics.noInterestDate), 'LLL yyyy')}
              </Title>
            </Col>
          )}
          {statistics.interestPaid && (
            <Col xs={12} md={8}>
              <p>
                <HelpLabel tooltip={HelpText.LoanResultInterestTotalling}>
                  Will pay interest totalling
                </HelpLabel>
              </p>
              <Title level={4}>
                {currencyFormatter.format(statistics.interestPaid)}
              </Title>
            </Col>
          )}
        </Row>
        <Line {...loanConfig} />
      </Space>
    </Card>
  )
}

export default LoanForecastGraph
