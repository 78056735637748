import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Select,
  Space,
  DatePicker,
  Popconfirm,
  AutoComplete,
} from 'antd'
import { useEffect, useState } from 'react'
import DollarInput, { DollarInputRules } from './DollarInput'
import {
  Credit,
  CreditFrequency,
  CreditType,
} from '../../../shared/types/Credit'
import moment from 'moment'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { HelpLabel } from './HelpLabel'
import { HelpText } from '../HelpText'
import PercentageInput from './PercentageInput'

type CreditFormValues = Pick<
  Credit,
  | 'name'
  | 'amount'
  | 'frequency'
  | 'annualGrowthPercentage'
  | 'maximumAmount'
  | 'type'
> & {
  startDate: moment.Moment
  endDate?: moment.Moment
}

const getFormValues = (data: Credit): CreditFormValues => ({
  name: data.name,
  type: data.type,
  amount: data.amount,
  frequency: data.frequency,
  startDate: moment(data.startDate),
  endDate: data.endDate ? moment(data.endDate) : undefined,
  annualGrowthPercentage:
    typeof data.annualGrowthPercentage === 'number'
      ? Number((data.annualGrowthPercentage * 100).toFixed(0))
      : undefined,
  maximumAmount: data.maximumAmount,
})

const CreditForm: React.FC<{
  credit: Credit
  onUpdate: (credit: CreditFormValues) => void
  onDelete: () => void
}> = ({ credit, onUpdate, onDelete }) => {
  const [form] = Form.useForm<CreditFormValues>()
  const [formOpen, setFormOpen] = useState(false)
  const [formUpdated, setFormUpdated] = useState(0)
  const [isUpdating, setIsUpdating] = useState(false)

  useEffect(() => {
    if (credit && isUpdating) {
      form.setFieldsValue(getFormValues(credit))
      setFormOpen(false)
      setIsUpdating(false)
    }
  }, [credit])

  useEffect(() => {
    setFormUpdated(1)
  }, [])

  const fieldDisabled = isUpdating || !formOpen

  return (
    <Form
      form={form}
      layout="vertical"
      name="credit-update"
      autoComplete="off"
      requiredMark="optional"
      initialValues={getFormValues(credit)}
      onFinish={values => {
        setIsUpdating(true)
        onUpdate({
          ...values,
          endDate:
            values.frequency === CreditFrequency.ONCE_OFF
              ? undefined
              : values.endDate,
          annualGrowthPercentage:
            typeof values.annualGrowthPercentage === 'number'
              ? values.annualGrowthPercentage * 0.01
              : undefined,
        })
      }}
    >
      <Card
        bordered={false}
        className={`form-card${formOpen ? '' : ' view-mode'}`}
      >
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={4}>
              <Form.Item
                label="Type"
                name="type"
                style={{ margin: '0' }}
                required
                rules={[
                  {
                    required: true,
                    message: 'Please select a type',
                  },
                ]}
              >
                <Select
                  size="large"
                  disabled={fieldDisabled}
                  onChange={() => setFormUpdated(formUpdated + 1)}
                >
                  <Select.Option value={CreditType.ANNUAL_SALARY}>
                    Annual Salary
                  </Select.Option>
                  {/* <Select.Option value={CreditType.WAGES}>Wages</Select.Option> */}
                  <Select.Option value={CreditType.OTHER_TAXABLE_INCOME}>
                    Other Taxable Income
                  </Select.Option>
                  <Select.Option value={CreditType.NON_TAXABLE_INCOME}>
                    Non Taxable Income
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={5}>
              <Form.Item
                label="Name"
                name="name"
                style={{ margin: '0' }}
                required
                rules={[
                  {
                    required: true,
                    message: 'Please input name',
                  },
                ]}
              >
                <AutoComplete
                  disabled={fieldDisabled}
                  size="large"
                  options={[
                    {
                      value: 'Salary',
                    },
                    {
                      value: 'Dividends',
                    },
                    {
                      value: 'Inheritance',
                    },
                    {
                      value: 'Gift',
                    },
                    {
                      value: 'Government payments',
                    },
                    {
                      value: 'Other',
                    },
                  ]}
                  filterOption={(inputValue, option) =>
                    option!.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={5}>
              <Form.Item
                label={`Amount${
                  form.getFieldValue('type') === CreditType.ANNUAL_SALARY
                    ? ' (per year, before tax)'
                    : ' (per frequency)'
                }`}
                name="amount"
                style={{ margin: '0' }}
                required
                rules={[
                  ...DollarInputRules,
                  {
                    required: true,
                    message: 'Please input an amount',
                  },
                ]}
              >
                <DollarInput
                  disabled={fieldDisabled}
                  onChange={() => setFormUpdated(formUpdated + 1)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={4}>
              <Form.Item
                label={`${
                  form.getFieldValue('type') === CreditType.ANNUAL_SALARY
                    ? 'Payment frequency'
                    : 'Frequency'
                }`}
                name="frequency"
                style={{ margin: '0' }}
                required
                rules={[
                  {
                    required: true,
                    message: 'Please select a frequency',
                  },
                ]}
              >
                <Select
                  size="large"
                  disabled={fieldDisabled}
                  onChange={() => setFormUpdated(formUpdated + 1)}
                >
                  <Select.Option value={CreditFrequency.WEEKLY}>
                    Weekly
                  </Select.Option>
                  <Select.Option value={CreditFrequency.FORTNIGHTLY}>
                    Fortnightly
                  </Select.Option>
                  <Select.Option value={CreditFrequency.MONTHLY}>
                    Monthly
                  </Select.Option>
                  <Select.Option value={CreditFrequency.YEARLY}>
                    Yearly
                  </Select.Option>
                  {form.getFieldValue('type') !== CreditType.ANNUAL_SALARY && (
                    <Select.Option value={CreditFrequency.ONCE_OFF}>
                      Once Off
                    </Select.Option>
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={4}>
              <Form.Item
                label={
                  form.getFieldValue('frequency') === CreditFrequency.ONCE_OFF
                    ? 'Date'
                    : 'Starts'
                }
                name="startDate"
                style={{ margin: '0' }}
                required
                rules={[
                  {
                    required: true,
                    message: 'Please input a date',
                  },
                ]}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  size="large"
                  style={{ width: '100%' }}
                  disabled={fieldDisabled}
                  placeholder=""
                  disabledDate={current => {
                    const endDate = form.getFieldValue(['endDate'])

                    return current && endDate && current > endDate
                  }}
                />
              </Form.Item>
            </Col>
            {form.getFieldValue('frequency') !== CreditFrequency.ONCE_OFF && (
              <Col xs={24} sm={12} md={4}>
                <Form.Item label="Ends" name="endDate" style={{ margin: '0' }}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    size="large"
                    style={{ width: '100%' }}
                    disabled={fieldDisabled}
                    placeholder=""
                    disabledDate={current => {
                      const startDate = form.getFieldValue(['startDate'])

                      return current && startDate && current < startDate
                    }}
                  />
                </Form.Item>
              </Col>
            )}
            <Col xs={24} sm={12} md={5}>
              <Form.Item
                label={
                  <HelpLabel tooltip={HelpText.CreditGrowthPerAnnum}>
                    Growth Per Annum
                  </HelpLabel>
                }
                name="annualGrowthPercentage"
                fieldKey="annualGrowthPercentage"
                style={{ margin: '0' }}
                rules={[
                  {
                    type: 'number',
                    min: 0,
                    message:
                      'Please input a percentage greater than or equal to 0%',
                  },
                  {
                    type: 'number',
                    max: 100,
                    message:
                      'Please input a percentage less than or equal to 100%',
                  },
                ]}
              >
                <PercentageInput disabled={fieldDisabled} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={5}>
              <Form.Item
                label={
                  <HelpLabel tooltip={HelpText.CreditMaximumAmount}>
                    Maximum amount
                  </HelpLabel>
                }
                name="maximumAmount"
                style={{ margin: '0' }}
                rules={[
                  {
                    type: 'number',
                    min: form.getFieldValue('amount') + 0.01,
                    message:
                      'Maximum amount cannot be less than the base amount',
                  },
                ]}
              >
                <DollarInput disabled={fieldDisabled} />
              </Form.Item>
            </Col>
            <Col
              xs={3}
              sm={1}
              style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: 'auto',
              }}
            >
              <Button
                onClick={() => setFormOpen(true)}
                shape="circle"
                icon={<EditOutlined />}
                htmlType="button"
                disabled={formOpen}
              />
            </Col>
            <Col
              xs={3}
              sm={1}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Popconfirm
                title="Are you sure you would like to delete this income?"
                onConfirm={onDelete}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  shape="circle"
                  icon={<DeleteOutlined />}
                  htmlType="button"
                  disabled={formOpen}
                />
              </Popconfirm>
            </Col>
          </Row>
          {formOpen && (
            <Row>
              <Col xs={12} sm={12} md={3} style={{ display: 'flex' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={isUpdating}
                  style={{ marginRight: '1rem' }}
                >
                  Save
                </Button>
                <Button
                  onClick={() => {
                    form.resetFields()
                    setFormOpen(false)
                  }}
                  disabled={isUpdating}
                  htmlType="button"
                  size="large"
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          )}
        </Space>
      </Card>
    </Form>
  )
}

export default CreditForm
