export class Debit {
  constructor(
    public userID: string,
    public id: string,
    public name: string,
    public amount: number,
    public frequency: DebitFrequency,
    public startDate: Date,
    public endDate?: Date
  ) {}
}

export enum DebitFrequency {
  WEEKLY = "WEEKLY",
  FORTNIGHTLY = "FORTNIGHTLY",
  MONTHLY = "MONTHLY",
  YEARLY = "YEARLY",
  ONCE_OFF = "ONCE_OFF",
}
