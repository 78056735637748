import { Button, Card, Col, Form, Input, Row, Space, DatePicker } from 'antd'
import { useState } from 'react'
import DollarInput, { DollarInputRules } from './DollarInput'
import { CashAccount } from '../../../shared/types/CashAccount'
import moment from 'moment'
import { HelpLabel } from '../components/HelpLabel'
import { HelpText } from '../HelpText'

type CashAccountCreateValues = Pick<CashAccount, 'name' | 'amount'> & {
  date: moment.Moment
}

const CashAccountCreateForm: React.FC<{
  onCreate: (cashAccount: CashAccountCreateValues) => void
  onCancel: () => void
}> = ({ onCreate, onCancel }) => {
  const [form] = Form.useForm<CashAccountCreateValues>()
  const [isUpdating, setIsUpdating] = useState(false)

  return (
    <Form
      form={form}
      layout="vertical"
      name="cash-account-create"
      autoComplete="off"
      requiredMark="optional"
      onFinish={values => {
        setIsUpdating(true)
        onCreate(values)
      }}
    >
      <Card bordered={false} className="form-card">
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={5}>
              <Form.Item
                label="Name"
                name="name"
                style={{ margin: '0' }}
                required
                rules={[
                  {
                    required: true,
                    message: 'Please input name',
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={5}>
              <Form.Item
                label="Amount"
                name="amount"
                style={{ margin: '0' }}
                required
                rules={[
                  ...DollarInputRules,
                  {
                    required: true,
                    message: 'Please input an amount',
                  },
                ]}
              >
                <DollarInput />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={4}>
              <Form.Item
                label={
                  <HelpLabel tooltip={HelpText.CashAccountDateAsAt}>
                    Date as at
                  </HelpLabel>
                }
                name="date"
                style={{ margin: '0' }}
                required
                rules={[
                  {
                    required: true,
                    message: 'Please input a date',
                  },
                ]}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  size="large"
                  style={{ width: '100%' }}
                  placeholder=""
                  disabledDate={current => {
                    return current > moment()
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={3} style={{ display: 'flex' }}>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                loading={isUpdating}
                style={{ marginRight: '1rem' }}
              >
                Save
              </Button>
              <Button
                onClick={onCancel}
                disabled={isUpdating}
                htmlType="button"
                size="large"
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Space>
      </Card>
    </Form>
  )
}

export default CashAccountCreateForm
