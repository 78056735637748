import { createContext, useContext, useEffect, useState } from 'react'
import * as auth from 'firebase/auth'
import { AuthContext } from './AuthContext'
import { Spin } from 'antd'
import { navigate } from '@reach/router'
import { Routes } from '../routes'

export const UserContext = createContext<auth.User | null>(null)

export const UserProvider: React.FC = ({ children }) => {
  const [checkedUser, setCheckedUser] = useState(false)
  const [user, setUser] = useState<auth.User | null>(null)
  const authContext = useContext(AuthContext)

  useEffect(() => {
    if (authContext.auth) {
      auth.onAuthStateChanged(authContext.auth, user => {
        setCheckedUser(true)
        setUser(user)

        if (!user) {
          navigate(Routes.LOGIN)
        }
      })

      auth.onIdTokenChanged(authContext.auth, user => {
        setCheckedUser(true)
        setUser(user)

        if (!user) {
          navigate(Routes.LOGIN)
        }
      })
    }
  }, [authContext.auth])

  useEffect(() => {
    if (!user) {
      return
    }
  }, [user, window.location.href])

  return (
    <UserContext.Provider value={user}>
      {checkedUser && children}
      {!checkedUser && (
        <Spin style={{ marginLeft: '50vw', marginTop: '50vh' }} />
      )}
    </UserContext.Provider>
  )
}
