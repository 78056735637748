import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Space,
  DatePicker,
  Popconfirm,
  Select,
} from 'antd'
import { useEffect, useState } from 'react'
import DollarInput, { DollarInputRules } from './DollarInput'
import {
  ExtraRepayment,
  ExtraRepaymentFrequency,
} from '../../../shared/types/ExtraRepayment'
import moment from 'moment'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Loan } from '../../../shared/types/Loan'
import { HelpText } from '../HelpText'
import { HelpLabel } from './HelpLabel'

type PropertyLoanExtraRepaymentFormValues = Pick<
  ExtraRepayment,
  'amount' | 'frequency'
> & {
  startDate: moment.Moment
  endDate?: moment.Moment
}

const getFormValues = (
  data: ExtraRepayment,
): PropertyLoanExtraRepaymentFormValues => ({
  amount: data.amount,
  frequency: data.frequency,
  startDate: moment(data.startDate),
  endDate: data.endDate && moment(data.endDate),
})

const PropertyLoanExtraRepaymentForm: React.FC<{
  loan: Loan
  extraRepayment: ExtraRepayment
  onUpdate: (extraRepayment: PropertyLoanExtraRepaymentFormValues) => void
  onDelete: () => void
  invalidDateRanges: Interval[]
}> = ({ extraRepayment, onUpdate, onDelete }) => {
  const [form] = Form.useForm<PropertyLoanExtraRepaymentFormValues>()
  const [formOpen, setFormOpen] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [formUpdated, setFormUpdated] = useState(0)

  useEffect(() => {
    if (extraRepayment && isUpdating) {
      form.setFieldsValue(getFormValues(extraRepayment))
      setFormOpen(false)
      setIsUpdating(false)
    }
  }, [extraRepayment])

  const fieldDisabled = isUpdating || !formOpen

  return (
    <Form
      form={form}
      layout="vertical"
      name="extra-repayment-update"
      autoComplete="off"
      requiredMark="optional"
      initialValues={getFormValues(extraRepayment)}
      onFinish={values => {
        setIsUpdating(true)
        onUpdate(values)
      }}
    >
      <Card
        bordered={false}
        className={`form-card${formOpen ? '' : ' view-mode'}`}
      >
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={5}>
              <Form.Item
                label={
                  <HelpLabel tooltip={HelpText.ExtraRepaymentAmount}>
                    Amount
                  </HelpLabel>
                }
                name="amount"
                style={{ margin: '0' }}
                required
                rules={[
                  ...DollarInputRules,
                  {
                    required: true,
                    message: 'Please input an amount',
                  },
                ]}
              >
                <DollarInput disabled={fieldDisabled} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={4}>
              <Form.Item
                label="Frequency"
                name="frequency"
                style={{ margin: '0' }}
                required
                rules={[
                  {
                    required: true,
                    message: 'Please select a frequency',
                  },
                ]}
              >
                <Select
                  size="large"
                  disabled={fieldDisabled}
                  onChange={() => setFormUpdated(formUpdated + 1)}
                >
                  <Select.Option value={ExtraRepaymentFrequency.WEEKLY}>
                    Weekly
                  </Select.Option>
                  <Select.Option value={ExtraRepaymentFrequency.FORTNIGHTLY}>
                    Fortnightly
                  </Select.Option>
                  <Select.Option value={ExtraRepaymentFrequency.MONTHLY}>
                    Monthly
                  </Select.Option>
                  <Select.Option value={ExtraRepaymentFrequency.YEARLY}>
                    Yearly
                  </Select.Option>
                  <Select.Option value={ExtraRepaymentFrequency.ONCE_OFF}>
                    Once Off
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={4}>
              <Form.Item
                label={
                  form.getFieldValue('frequency') ===
                  ExtraRepaymentFrequency.ONCE_OFF
                    ? 'Date'
                    : 'Starts'
                }
                name="startDate"
                style={{ margin: '0' }}
                required
                rules={[
                  {
                    required: true,
                    message: 'Please input a date',
                  },
                ]}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  size="large"
                  style={{ width: '100%' }}
                  placeholder=""
                  disabled={fieldDisabled}
                  disabledDate={current => {
                    const endDate = form.getFieldValue(['endDate'])

                    return current && endDate && current > endDate
                  }}
                />
              </Form.Item>
            </Col>
            {form.getFieldValue('frequency') !==
              ExtraRepaymentFrequency.ONCE_OFF && (
              <Col xs={24} sm={12} md={4}>
                <Form.Item
                  label="Ends"
                  name="endDate"
                  style={{ margin: '0' }}
                  required
                >
                  <DatePicker
                    format="DD/MM/YYYY"
                    size="large"
                    style={{ width: '100%' }}
                    placeholder=""
                    disabled={fieldDisabled}
                    disabledDate={current => {
                      const startDate = form.getFieldValue(['startDate'])

                      return current && startDate && current < startDate
                    }}
                  />
                </Form.Item>
              </Col>
            )}
            <Col
              xs={24}
              md={
                form.getFieldValue('frequency') !==
                ExtraRepaymentFrequency.ONCE_OFF
                  ? 4
                  : 8
              }
            ></Col>
            <Col
              xs={3}
              sm={1}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Button
                onClick={() => setFormOpen(true)}
                shape="circle"
                icon={<EditOutlined />}
                htmlType="button"
                disabled={formOpen}
              />
            </Col>
            <Col
              xs={3}
              sm={1}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Popconfirm
                title="Are you sure you would like to delete this extra repayment?"
                onConfirm={onDelete}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  shape="circle"
                  icon={<DeleteOutlined />}
                  htmlType="button"
                  disabled={formOpen}
                />
              </Popconfirm>
            </Col>
          </Row>
          {formOpen && (
            <Row>
              <Col xs={12} sm={12} md={3} style={{ display: 'flex' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={isUpdating}
                  style={{ marginRight: '1rem' }}
                >
                  Save
                </Button>
                <Button
                  onClick={() => {
                    form.resetFields()
                    setFormOpen(false)
                  }}
                  disabled={isUpdating}
                  htmlType="button"
                  size="large"
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          )}
        </Space>
      </Card>
    </Form>
  )
}

export default PropertyLoanExtraRepaymentForm
