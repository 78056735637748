import { HomeOutlined } from '@ant-design/icons'
import { navigate, useLocation } from '@reach/router'
import { Menu as AntMenu } from 'antd'

const Menu: React.FC<{ mobile?: boolean }> = ({ mobile }) => {
  const location = useLocation()

  return (
    <>
      <AntMenu
        theme={mobile ? 'light' : 'dark'}
        mode={mobile ? 'vertical' : 'horizontal'}
        style={mobile ? { border: 'none' } : { flexGrow: 1 }}
        onClick={e => navigate(`/${e.key === 'home' ? '' : e.key}`)}
        selectedKeys={[
          location.pathname === '/' ? 'home' : '',
          location.pathname.includes('finances') ? 'finances' : '',
          location.pathname.includes('properties') ? 'properties' : '',
          location.pathname.includes('purchase-plans') ? 'purchase-plans' : '',
        ]}
      >
        <AntMenu.Item key="home">
          <HomeOutlined />
        </AntMenu.Item>
        <AntMenu.Item key="finances">Finances</AntMenu.Item>
        <AntMenu.Item key="purchase-plans">Purchase Plans</AntMenu.Item>
        <AntMenu.Item key="properties">My Properties</AntMenu.Item>
      </AntMenu>
    </>
  )
}

export default Menu
