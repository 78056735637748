export const HelpText = {
  CashReserve:
    'The amount of cash you would like to keep aside when calculating how much of a deposit you have. Typically, keeping aside cash is good practice to ensure that you have liquid funds after purchasing a property, especially if there are unexpected repairs you need to make to the property.',
  CashAccount:
    "Record your primary savings account's balance at a certain point in time.",
  CashAccountDateAsAt:
    'The date of recording the balance of your cash account. On this date, your loans forecasts and/or purchase plan affordability schedules/forecasts will adjust your savings to match this balance.',
  Todo: 'TODO.',
  Credits:
    'Sources of capital gains, including income, gifts, investments, dividends etc.',
  CreditGrowthPerAnnum:
    'Set a percentage of growth per annum to reflect regular increases of credits, for example, pay rises.',
  CreditMaximumAmount:
    "Set the maximum amount this income can grow to. Use this to make sure your income doesn't unrealistically continue to grow linearly forever.",
  Debits:
    'Source of capital losses, including expenses, one off payments, and any other outgoings.',
  AddLoan:
    'Add multiple loans to your property with different parameters so you can compare repayment strategies.',
  CompareLoans:
    'Compare all of your loans against the property and the interest saved, principal paid, and the savings you retain over a period of time.',
  PrimaryLoan:
    'Whether this loan is the actual loan recorded against the property, used for calculation of equity or sale price when using this property in a purchase plan.',
  LoanType:
    'Whether the interest rate against this loan changes relative to the Reserve Bank of Australia interest rate (variable) or does not change (fixed).',
  LoanPaymentFrequency:
    'You can repay principal and interest on your loan at certain intervals. Paying fortnightly, for instance, allows you to have more repayments within a calendar year and therefore pay your loan off faster.',
  LoanStartingPrincipal:
    'The total amount borrowed from the bank at the beginning of your loan, including LMI.',
  LoanOffsetContributions:
    "The percentage of your cash account's balance that is deducted from the outstanding principal against your loan when calculating your interest for the payment period",
  LoanPrincipalAdjustments:
    'Set the actual principal of your loan at a certain point in time to get a more accurate reflection of your loan forecast',
  LoanVariants:
    'Any changes to your loan or loan structure, for example splitting or fixing your loan for a certain period of time, or changing loan providers and refinancing.',
  LoanVariantType:
    'Whether the interest rate against this loan variant changes relative to the Reserve Bank of Australia interest rate (variable) or does not change (fixed).',
  LoanVariantRefinanceAmount:
    'The cash incentive when changing loan providers.',
  LoanVariantOffsetContributions:
    "The percentage of your cash account's balance that is deducted from the outstanding principal against your loan variant when calculating your interest for the payment period",
  LoanVariantPercentageOfExtraRepayments:
    'The percentage of an extra repayment for a repayment period that is contributed to this loan variant portion. Eg. if you have a $1,000 extra repayment against a split loan, you can allocate 40% ($400) to the fixed loan and 60% ($600) to the variable loan.',
  ExtraRepayments:
    'Additional contributions to the reduction of the principal against your loan, contributed once per the payment frequency.',
  ExtraRepaymentAmount:
    'The amount of additional contribution to the reduction of the principal against your loan, contributed once per the payment frequency.',
  LoanRentPeriods: 'Periods of time where the property is leased.',
  LoanResults:
    'The projected outcomes of your loan structure for this property.',
  LoanResultFullyPaidOff: 'The date at which the principal on your loan is $0.',
  LoanResultZeroInterest:
    'The date at which the amount of interest you pay is $0. If you have an offset account, it will be the date when the amount in your offset account is the same as the amount of principal remaining.',
  LoanResultInterestTotalling:
    'The total amount of interest you pay against the loan.',
  LoanForecast: 'The projected month-to-month financials against this loan.',
  LoanForecastPrincipal:
    'The amount of borrowed funds that is still outstanding.',
  LoanForecastInterest:
    'The amount of interest to pay your financial institution. Please note that this calculation does not involve assessing your offset balance each day within a month and does not consider if a loan or loan variant ends within a month, in both cases it will take the balance at the start of the month and calculate the amount of days within the month to charge interest.',
  LoanForecastSavings: 'The amount of cash you have in your cash account.',
  LoanForecastCredits: 'The amount of income received, minus rent income.',
  LoanForecastRentIncome:
    'The amount of income received from leasing properties in your portfolio.',
  LoanForecastDebits:
    'The amount of expenses paid, minus other loan repayments.',
  LoanForecastOtherLoanRepayments:
    'The amount of loan repayments made to other properties you own.',
  LoanForecastNetGainLoss:
    'The total amount of cash received after taking into account credits, rent income, expenses, repayment and other loan repayments. This amount is added to your savings.',
  LoanForecastExtraRepayments:
    'The amount of additional contributions made to the loan. This is added to the repayment amount and is deducted from the principal.',
  LoanForecastEquity:
    '80% of the market value of the property, minus the outstanding principal. This amount can be used as a deposit against another property.',
  LoanForecastEquityPurchasePrice:
    'The equity in the property multipled by 4, estimating the purchase price of a new property using equity as a 20% deposit, after taking into account upfront fees (eg. stamp duty).',
  LoanForecastPropertyValue:
    'The fair market value of your property, calculated by taking the property purchase price and compounding the estimated growth rate per annum from the start of the loan.',
  PropertyPurchasePrice:
    'The amount at which this property sold. This is used to determine the amount of equity you have in this property.',
  PropertyEstimatedRent:
    'The estimated amount per week that could be received if this property were to be leased.',
  PropertyEstimatedGrowth:
    "The estimated percentage at which this property's value grows every year",
  PropertyValueAdjustments:
    "Set dates where you know the value of your property for more accurate forecasting of your property's value",
  PlanFinanceSource:
    'How the deposit for this property will be funded. Cash: using liquid funds. Equity: using 80% of the value of your property minus the amount owed on it as a deposit. Sale: selling your property and using the funds made from the sale as a deposit.',
  PlanStatus:
    'The status of the property you are planning on buying can impact the amount of stamp duty you have to pay.',
  PlanPropertyType:
    'Whether you are planning to live or lease your property. This can impact the amount of stamp duty you have to pay.',
  PlanMinimumDeposit:
    "The minimum % deposit you are willing to pay against the property. A deposit below 20% will incur Lenders' Mortgage Insurance",
  PlanGrowthRate:
    'The estimated percentage at which properties with the characteristics chosen increase in price per year. This is used to estimate what the price of the property you are aiming to purchase is today. This field may be auto calculated based on the suburb, bedrooms, and property type selected above.',
  PlanAffordability:
    'The maximum purchase price you can afford at any time given the property characteristics and the funds you have for a deposit.',
  PlanAffordabilityLMI:
    "Lenders' Mortgage Insurance, a premium added to the loan when the deposit you have is less than 20%",
  PlanAffordabilityTotalUpfrontFees:
    'The total amount of fees, inclusive of stamp duty, transfer fees, and registration fees to be paid against the property. This value increases as the property price increases and varies based on the characteristics of the property.',
  PlanAffordabilitySaleFees:
    'The amount of fees to be deducted from the funds available for a deposit when selling an existing property. This increases as the property-to-sell value increases, due to the agent fee being a percentage of the property value.',
  PlanAffordabilityPurchasePriceToday:
    'The estimated price of the property you are aiming to purchase today.',
  PlanAffordabilityHigherInterestRepayments:
    'This value indicates your repayments per month if the interest were 3% higher. When calculating interest with regards to affordability, it is recommended to calculate repayments at a higher interest rate to see if they are still affordable if the interest does reach a higher amount than what it is currently.',
  PlanTarget:
    'Based on the affordability table, you can set a a price at which you aim to purchase a property, or a date at which you aim to purchase. This drives your loan forecast below.',
  PlanTargetExistingProperties:
    "Determine what you would like to do with your existing properties - whether or not you would like to lease them when you purchase this property, and at what rate you'd like to lease them for",
  PlanTargetDebits:
    'Determine what expenses are still applicable when purchasing this property, and add any new expenses that you may incur as a result of purchasing this property (eg. rates on this new property).',
  PlanTargetDebitEnabled:
    'Whether this expense should still be incurred after the purchase of this property.',
  PlanForecast:
    'The projected month-to-month finances against the loan taken out against the property to be purchased at the target price or date.',
  PlanAverage:
    'The amount of cash you would save every month after accounting for taking out a 30 year loan on this date and making monthly loan repayments.',
  PlanBufferSavings:
    "The amount of cash you'd like to have left over each month after loan repayments. This will be factored into your loan repayment affordability calculations.",
  EquityReserve:
    'The percentage of equity to leave unused in your properties when purchasing a new property with equity. Typically a buffer is left in order to allow for accessing this equity in the future if needed.',
  InterestOnlyYears:
    'The amount of years at the start of the loan that the repayments are interest-only. A common maximum is 5 years but could be extended by refinancing. Applying an interest-only loan is a common strategy to keep loan repayments low on an investment property while the value increases, allowing you to build equity in the property, or sell without having to lose cash flow to principal repayments. See https://moneysmart.gov.au/home-loans/interest-only-home-loans for more information.',
  RentalYield:
    'The percentage of rent you receive in a year, relative to the value of the property, not considering any outgoings. Typically this can range between 3-5% as a guide. See https://www.mortgagechoice.com.au/guides/property-investment/what-is-rental-yield/ for more information.',
  PurchaseSaleFees:
    'The total fees involved in selling the property, or properties. Please note, if selling multiple properties, the fees below will be applied to each property individually.',
}
