import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Select,
  Space,
  DatePicker,
  AutoComplete,
} from 'antd'
import { useState } from 'react'
import DollarInput, { DollarInputRules } from './DollarInput'
import { Debit, DebitFrequency } from '../../../shared/types/Debit'
import moment from 'moment'

type DebitCreateValues = Pick<Debit, 'name' | 'amount' | 'frequency'> & {
  startDate: moment.Moment
  endDate?: moment.Moment
}

const DebitCreateForm: React.FC<{
  onCreate: (debit: DebitCreateValues) => void
  onCancel: () => void
}> = ({ onCreate, onCancel }) => {
  const [form] = Form.useForm<DebitCreateValues>()
  const [formUpdated, setFormUpdated] = useState(0)
  const [isUpdating, setIsUpdating] = useState(false)

  return (
    <Form
      form={form}
      layout="vertical"
      name="debit-create"
      autoComplete="off"
      requiredMark="optional"
      onFinish={values => {
        setIsUpdating(true)
        onCreate(values)
      }}
    >
      <Card bordered={false} className="form-card">
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={5}>
              <Form.Item
                label="Name"
                name="name"
                style={{ margin: '0' }}
                required
                rules={[
                  {
                    required: true,
                    message: 'Please input name',
                  },
                ]}
              >
                <AutoComplete
                  size="large"
                  options={[
                    {
                      value: 'Food - Groceries',
                    },
                    {
                      value: 'Food - Eating out',
                    },
                    {
                      value: 'Rates',
                    },
                    {
                      value: 'Water',
                    },
                    {
                      value: 'Gas',
                    },
                    {
                      value: 'Electricity',
                    },
                    {
                      value: 'Body Corporate',
                    },
                    {
                      value: 'Clothing / Personal care',
                    },
                    {
                      value: 'Mobile phone / home phone',
                    },
                    {
                      value: 'Car registration',
                    },
                    {
                      value: 'Petrol and other car expenses',
                    },
                    {
                      value: 'Transport',
                    },
                    {
                      value: 'Education',
                    },
                    {
                      value: 'Health',
                    },
                    {
                      value: 'Insurance - home / content',
                    },
                    {
                      value: 'Insurance - car',
                    },
                    {
                      value: 'Insurance - health',
                    },
                    {
                      value: 'Insurance - pet',
                    },
                    {
                      value: 'Entertainment',
                    },
                    {
                      value: 'Internet',
                    },
                    {
                      value: 'Chilcare',
                    },
                    {
                      value: 'Memberships',
                    },
                    {
                      value: 'Subscriptions',
                    },
                    {
                      value: 'Rent',
                    },
                    {
                      value: 'Pet food and care',
                    },
                    {
                      value: 'Holidays',
                    },
                    {
                      value: 'Other',
                    },
                  ]}
                  filterOption={(inputValue, option) =>
                    option!.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={5}>
              <Form.Item
                label="Amount"
                name="amount"
                style={{ margin: '0' }}
                required
                rules={[
                  ...DollarInputRules,
                  {
                    required: true,
                    message: 'Please input an amount',
                  },
                ]}
              >
                <DollarInput />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={4}>
              <Form.Item
                label="Frequency"
                name="frequency"
                style={{ margin: '0' }}
                required
                rules={[
                  {
                    required: true,
                    message: 'Please select a frequency',
                  },
                ]}
              >
                <Select
                  size="large"
                  onChange={() => setFormUpdated(formUpdated + 1)}
                >
                  <Select.Option value={DebitFrequency.WEEKLY}>
                    Weekly
                  </Select.Option>
                  <Select.Option value={DebitFrequency.FORTNIGHTLY}>
                    Fortnightly
                  </Select.Option>
                  <Select.Option value={DebitFrequency.MONTHLY}>
                    Monthly
                  </Select.Option>
                  <Select.Option value={DebitFrequency.YEARLY}>
                    Yearly
                  </Select.Option>
                  <Select.Option value={DebitFrequency.ONCE_OFF}>
                    Once Off
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={4}>
              <Form.Item
                label={
                  form.getFieldValue('frequency') === DebitFrequency.ONCE_OFF
                    ? 'Date'
                    : 'Start Date'
                }
                name="startDate"
                style={{ margin: '0' }}
                required
                rules={[
                  {
                    required: true,
                    message: 'Please input a date',
                  },
                ]}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  size="large"
                  style={{ width: '100%' }}
                  placeholder=""
                  disabledDate={current => {
                    const endDate = form.getFieldValue(['endDate'])

                    return current && endDate && current > endDate
                  }}
                />
              </Form.Item>
            </Col>
            {form.getFieldValue('frequency') !== DebitFrequency.ONCE_OFF && (
              <Col xs={24} sm={12} md={4}>
                <Form.Item
                  label="End Date"
                  name="endDate"
                  style={{ margin: '0' }}
                  required
                >
                  <DatePicker
                    format="DD/MM/YYYY"
                    size="large"
                    style={{ width: '100%' }}
                    placeholder=""
                    disabledDate={current => {
                      const startDate = form.getFieldValue(['startDate'])

                      return current && startDate && current < startDate
                    }}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>

          <Row>
            <Col xs={12} sm={12} md={3} style={{ display: 'flex' }}>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                loading={isUpdating}
                style={{ marginRight: '1rem' }}
              >
                Save
              </Button>
              <Button
                onClick={onCancel}
                disabled={isUpdating}
                htmlType="button"
                size="large"
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Space>
      </Card>
    </Form>
  )
}

export default DebitCreateForm
