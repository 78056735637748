import { Alert, Col, Row, Space } from 'antd'
import { useEffect } from 'react'
import { PurchasePlan } from '../../../shared/types/PurchasePlan'
import { useLazyQuery } from '../hooks/useLazyQuery'
import { APIRoute } from '../network'
import PurchasePlanTargetForm from '../components/PurchasePlanTargetForm'
import LoanForecastTable from '../components/LoanForecastTable'
import { LoanForecast } from '../../../shared/types/Loan'
import { currencyFormatter } from '../util'
import { HelpLabel } from '../components/HelpLabel'
import { HelpText } from '../HelpText'

interface Props {
  plan: PurchasePlan
  onUpdate: (plan: PurchasePlan) => void
}

const PurchasePlanTargetView: React.FC<Props> = ({ plan, onUpdate }) => {
  const {
    makeQuery: getForecast,
    data: forecastData,
    loading: forecastLoading,
    error: forecastError,
  } = useLazyQuery<LoanForecast[]>(APIRoute.GetPurchasePlanForecast)

  const fetchForecast = () => getForecast(undefined, plan.id)

  useEffect(() => {
    if (plan.target) {
      fetchForecast()
    }
  }, [])

  const lowest = forecastData?.reduce(
    (acc, curr) => (curr.netGainOrLoss < acc ? curr.netGainOrLoss : acc),
    forecastData[0].netGainOrLoss,
  )
  const highest = forecastData?.reduce(
    (acc, curr) => (curr.netGainOrLoss > acc ? curr.netGainOrLoss : acc),
    forecastData[0].netGainOrLoss,
  )

  const statistics =
    lowest !== undefined &&
    highest !== undefined &&
    forecastData &&
    forecastData.length > 0
      ? [
          {
            label: 'Average monthly net gain/loss',
            value: currencyFormatter.format(
              forecastData.reduce((acc, curr) => acc + curr.netGainOrLoss, 0) /
                forecastData.length,
            ),
          },
          {
            label:
              lowest >= 0
                ? 'Lowest net gain in a month'
                : 'Greatest net loss in a month',
            value: currencyFormatter.format(lowest),
          },
          {
            label:
              highest >= 0
                ? 'Highest net gain in a month'
                : 'Least net loss in a month',
            value: currencyFormatter.format(highest),
          },
          {
            label: (
              <HelpLabel tooltip={HelpText.LoanResultInterestTotalling}>
                Will pay interest totalling
              </HelpLabel>
            ),
            value: currencyFormatter.format(
              forecastData.reduce((acc, curr) => acc + curr.interestTotal, 0) ||
                0,
            ),
          },
        ]
      : []

  return (
    <>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Row>
          <Col xs={24}>
            <PurchasePlanTargetForm
              purchasePlan={plan}
              onUpdate={updatedPlan => {
                fetchForecast()
                onUpdate(updatedPlan)
              }}
            />
          </Col>
        </Row>
        {plan && plan.target && (
          <Row>
            <Col xs={24}>
              {forecastError && (
                <Alert
                  message="Error"
                  description={forecastError.message}
                  type="error"
                  showIcon
                />
              )}
              {!forecastError && (
                <Row>
                  <Col xs={24}>
                    <LoanForecastTable
                      purchasePlan
                      loading={forecastLoading}
                      results={forecastData || []}
                      statistics={statistics}
                    />
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        )}
      </Space>
    </>
  )
}

export default PurchasePlanTargetView
