import { LoanRepaymentType } from "./Loan";

export class LoanVariant {
  constructor(
    public userID: string,
    public loanID: string,
    public id: string,
    public type: LoanVariantType,
    public refinanceAmount: number,
    public startDate: Date,
    public endDate: Date,
    public portions: LoanVariantPortion[]
  ) {}
}

export enum LoanVariantType {
  SPLIT = "SPLIT",
  VARIABLE = "VARIABLE",
  FIXED = "FIXED",
}

export enum LoanVariantPortionType {
  VARIABLE = "VARIABLE",
  FIXED = "FIXED",
}

export enum LoanVariantPortionPaymentFrequency {
  WEEKLY = "WEEKLY",
  FORTNIGHTLY = "FORTNIGHTLY",
  MONTHLY = "MONTHLY",
}

export interface LoanVariantPortion {
  type: LoanVariantPortionType;
  repaymentType: LoanRepaymentType;
  percentage: number;
  years: number;
  interestRate: number;
  offsetContributions: number;
  paymentFrequency: LoanVariantPortionPaymentFrequency;
  extraRepaymentPercentage: number;
}
