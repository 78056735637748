import { navigate, RouteComponentProps } from '@reach/router'
import { Spin } from 'antd'
import { useContext, useEffect } from 'react'
import { AuthContext } from '../components/AuthContext'

const Logout: React.FC<RouteComponentProps> = () => {
  const authContext = useContext(AuthContext)

  useEffect(() => {
    ;(async () => {
      await authContext.auth?.signOut()
      navigate('/login')
    })()
  }, [])

  return <Spin style={{ marginLeft: '50vw', marginTop: '50vh' }} />
}

export default Logout
