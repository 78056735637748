import { Link, RouteComponentProps } from '@reach/router'
import { Breadcrumb, Skeleton, Space, Table } from 'antd'
import PageHeader from '../components/PageHeader'
import { APIRoute } from '../network'
import { useLazyQuery } from '../hooks/useLazyQuery'
import { useEffect } from 'react'
import { currencyFormatter } from '../util'
import { Routes } from '../routes'
import CompareLoansForm from '../components/CompareLoansForm'
import Text from 'antd/lib/typography/Text'
import { Loan, LoanComparison } from '../../../shared/types/Loan'
import { authenticatedRoute } from '../components/AuthenticatedRoute'
import { Property } from '../../../shared/types/Property'
import { Helmet } from 'react-helmet'

const CompareLoans: React.FC<RouteComponentProps<{ propertyID: string }>> = ({
  propertyID,
}) => {
  const { makeQuery: getProperty, data: propertyData } = useLazyQuery<Property>(
    APIRoute.GetProperty,
  )
  const {
    makeQuery: getLoans,
    loading,
    data,
  } = useLazyQuery<Loan[] | null>(APIRoute.GetLoans)
  const {
    makeQuery: getComparisons,
    loading: compareLoading,
    data: compareData,
  } = useLazyQuery<LoanComparison[]>(APIRoute.CompareResults)

  useEffect(() => {
    const startDate = localStorage.getItem('compareStartDate')
    const endDate = localStorage.getItem('compareEndDate')

    getComparisons(
      undefined,
      propertyID,
      startDate && endDate ? { startDate, endDate } : undefined,
    )
    getProperty(undefined, propertyID)
    getLoans(undefined, propertyID)
  }, [])

  const loans = data || []
  const isLoading = loading || compareLoading

  return (
    <>
      <Helmet>
        <title>Compare Loans - Doorstep</title>
      </Helmet>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={Routes.PROPERTIES}>My Properties</Link>
          </Breadcrumb.Item>
          {propertyData && (
            <Breadcrumb.Item>{propertyData.address}</Breadcrumb.Item>
          )}
          <Breadcrumb.Item>
            <Link to={`${Routes.PROPERTIES}/${propertyID}/loans`}>Loans</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Compare</Breadcrumb.Item>
        </Breadcrumb>
        <PageHeader title="Compare Loans" />
        <CompareLoansForm
          loan={loans[0]}
          onUpdate={values => getComparisons(undefined, propertyID, values)}
        />
        {isLoading ? (
          <Skeleton loading active />
        ) : (
          <Table
            style={{ margin: '32px 0' }}
            columns={[
              {
                title: 'Value',
                key: 'value',
                dataIndex: 'value',
                fixed: true,
              },
              ...loans.map((loan, i) => ({
                title: loan.name,
                key: `loan${i + 1}`,
                dataIndex: `loan${i + 1}`,
              })),
            ]}
            pagination={false}
            dataSource={[
              {
                value: 'Interest Paid',
                ...loans.reduce(
                  (acc, loan, i) => ({
                    ...acc,
                    [`loan${i + 1}`]: currencyFormatter.format(
                      (compareData &&
                        compareData.find(({ id }) => id === loan.id)
                          ?.interestPaid) ||
                        0,
                    ),
                  }),
                  {},
                ),
              },
              {
                value: 'Interest Saved',
                ...loans.reduce((acc, loan, i) => {
                  const value =
                    (compareData &&
                      compareData.find(({ id }) => id === loan.id)
                        ?.interestSaved) ||
                    0
                  const isHighest = !(compareData || []).some(
                    ({ interestSaved }) => interestSaved > value,
                  )

                  return {
                    ...acc,
                    [`loan${i + 1}`]: (
                      <Text
                        type={
                          isHighest
                            ? 'success'
                            : value > 0
                            ? 'warning'
                            : 'danger'
                        }
                      >
                        {currencyFormatter.format(value)}
                      </Text>
                    ),
                  }
                }, {}),
              },
              {
                value: 'Principal Remaining',
                ...loans.reduce(
                  (acc, loan, i) => ({
                    ...acc,
                    [`loan${i + 1}`]: currencyFormatter.format(
                      (compareData &&
                        compareData.find(({ id }) => id === loan.id)
                          ?.principalRemaining) ||
                        0,
                    ),
                  }),
                  {},
                ),
              },
              {
                value: 'Principal Paid',
                ...loans.reduce(
                  (acc, loan, i) => ({
                    ...acc,
                    [`loan${i + 1}`]: currencyFormatter.format(
                      (compareData &&
                        compareData.find(({ id }) => id === loan.id)
                          ?.principalPaid) ||
                        0,
                    ),
                  }),
                  {},
                ),
              },
              {
                value: 'Extra Principal Paid',
                ...loans.reduce((acc, loan, i) => {
                  const value =
                    (compareData &&
                      compareData.find(({ id }) => id === loan.id)
                        ?.extraPrincipalPaid) ||
                    0
                  const isHighest = !(compareData || []).some(
                    ({ extraPrincipalPaid }) => extraPrincipalPaid > value,
                  )

                  return {
                    ...acc,
                    [`loan${i + 1}`]: (
                      <Text
                        type={
                          isHighest
                            ? 'success'
                            : value > 0
                            ? 'warning'
                            : 'danger'
                        }
                      >
                        {currencyFormatter.format(value)}
                      </Text>
                    ),
                  }
                }, {}),
              },
              {
                value: 'Savings Balance',
                ...loans.reduce(
                  (acc, loan, i) => ({
                    ...acc,
                    [`loan${i + 1}`]: currencyFormatter.format(
                      (compareData &&
                        compareData.find(({ id }) => id === loan.id)
                          ?.savingsBalance) ||
                        0,
                    ),
                  }),
                  {},
                ),
              },
              {
                value: 'Extra Savings',
                ...loans.reduce((acc, loan, i) => {
                  const value =
                    (compareData &&
                      compareData.find(({ id }) => id === loan.id)
                        ?.extraSavings) ||
                    0
                  const isHighest = !(compareData || []).some(
                    ({ extraSavings }) => extraSavings > value,
                  )

                  return {
                    ...acc,
                    [`loan${i + 1}`]: (
                      <Text
                        type={
                          isHighest
                            ? 'success'
                            : value > 0
                            ? 'warning'
                            : 'danger'
                        }
                      >
                        {currencyFormatter.format(value)}
                      </Text>
                    ),
                  }
                }, {}),
              },
            ]}
          />
        )}
      </Space>
    </>
  )
}

export default authenticatedRoute(CompareLoans)
