import { useContext, useState } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'
import { AuthContext } from '../components/AuthContext'
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  message,
  Row,
  Space,
} from 'antd'
import { Helmet } from 'react-helmet'
import { sendPasswordResetEmail } from 'firebase/auth'
import Title from 'antd/lib/typography/Title'
import { Routes } from '../routes'

interface FormValues {
  email: string
}

const errorMessages: { [key: string]: string } = {
  'auth/invalid-email': 'Incorrect email address.',
  'auth/wrong-password': 'Incorrect password.',
  'auth/user-not-found': 'Email address does not exist.',
  'auth/too-many-requests':
    'This email address has had too many failed attempts. Please try reset your password or try again later.',
}

const ResetPassword: React.FC<RouteComponentProps> = () => {
  const [form] = Form.useForm<FormValues>()
  const authContext = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  return (
    <>
      <Helmet>
        <title>Reset password - Doorstep</title>
      </Helmet>
      <Layout>
        <Layout.Header style={{ background: 'none' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              marginRight: '3rem',
            }}
          >
            <img src="/logo-dark.png" height="24px" />
          </div>
        </Layout.Header>
        <Layout.Content>
          <Card
            style={{
              maxWidth: '500px',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: '20vh',
            }}
          >
            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
              <Title level={2} style={{ margin: 0 }}>
                Reset password
              </Title>
              {error && !loading && (
                <Alert
                  message="Error"
                  description={error}
                  type="error"
                  showIcon
                />
              )}
              <Form
                form={form}
                layout="vertical"
                name="debit-update"
                autoComplete="off"
                requiredMark="optional"
                initialValues={{}}
                onFinish={async values => {
                  if (!authContext.auth) {
                    setError('There was an issue. Please try again.')
                    return
                  }

                  try {
                    setLoading(true)

                    await sendPasswordResetEmail(
                      authContext.auth,
                      values.email,
                      {
                        url: `${window.location.origin}${Routes.LOGIN}`,
                      },
                    )

                    message.success(
                      'Reset password email sent, check your inbox',
                    )
                  } catch (e: any) {
                    if ('code' in e) {
                      setError(
                        errorMessages[e.code] ||
                          'There was an issue. Please try again.',
                      )
                    } else {
                      setError('There was an issue. Please try again.')
                    }
                  }

                  setLoading(false)
                }}
              >
                <Space
                  direction="vertical"
                  size="middle"
                  style={{ width: '100%' }}
                >
                  <Row>
                    <Col xs={24}>
                      <Form.Item
                        label="Email"
                        name="email"
                        style={{ margin: '0' }}
                        required
                        rules={[
                          {
                            required: true,
                            message: 'Please input email',
                          },
                          {
                            type: 'email',
                            message: 'Please input a valid email',
                          },
                        ]}
                      >
                        <Input size="large" type="email" disabled={loading} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        loading={loading}
                        style={{ marginRight: '1rem' }}
                      >
                        Submit
                      </Button>
                      <Button
                        size="large"
                        disabled={loading}
                        onClick={() => navigate(Routes.LOGIN)}
                      >
                        Back
                      </Button>
                    </Col>
                  </Row>
                </Space>
              </Form>
            </Space>
          </Card>
        </Layout.Content>
      </Layout>
    </>
  )
}

export default ResetPassword
