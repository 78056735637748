import React from 'react'
import ReactDOM from 'react-dom'
import { initializeApp } from 'firebase/app'
import './app.less'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

const firebaseConfig =
  process.env.REACT_APP_ENV === 'dev'
    ? {
        apiKey: 'AIzaSyCrSaur6L4g3u2cKm61_zvQxU7ypIzcDyA',
        authDomain: 'doorstep-preprod.firebaseapp.com',
        projectId: 'doorstep-preprod',
        storageBucket: 'doorstep-preprod.appspot.com',
        messagingSenderId: '697693883466',
        appId: '1:697693883466:web:ea5c1734723f3ab87e2ab3',
      }
    : {
        apiKey: 'AIzaSyC8oj1N3kFL3IErw8517MfQsjx1mGajtII',
        authDomain: 'doorstep-324402.firebaseapp.com',
        projectId: 'doorstep-324402',
        storageBucket: 'doorstep-324402.appspot.com',
        messagingSenderId: '546045657051',
        appId: '1:546045657051:web:21a1d96cd62e767965b1fe',
        measurementId: 'G-GS48FN4P9T',
      }

initializeApp(firebaseConfig)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
