import { Link, RouteComponentProps } from '@reach/router'
import { Breadcrumb, Card, Progress, Skeleton, Space, Steps } from 'antd'
import { authenticatedRoute } from '../components/AuthenticatedRoute'
import PageHeader from '../components/PageHeader'
import { useContext, useEffect, useState } from 'react'
import { useLazyQuery } from '../hooks/useLazyQuery'
import { CashAccount } from '../../../shared/types/CashAccount'
import { APIRoute } from '../network'
import { Credit } from '../../../shared/types/Credit'
import { Debit } from '../../../shared/types/Debit'
import { Property } from '../../../shared/types/Property'
import { PurchasePlan } from '../../../shared/types/PurchasePlan'
import { Routes } from '../routes'
import { Helmet } from 'react-helmet'
import * as auth from 'firebase/auth'
import { UserContext } from '../components/UserContext'

const Home: React.FC<RouteComponentProps> = ({ children }) => {
  const user = useContext(UserContext)
  const [currentStep, setCurrentStep] = useState<number | undefined>(0)
  const [idToken, setIdToken] = useState<auth.IdTokenResult | null>(null)
  const [sentWelcome, setSentWelcome] = useState(false)

  const { makeQuery: sendWelcome, loading: welcomeLoading } =
    useLazyQuery<null>(APIRoute.SendUserWelcomeEmail)

  const {
    data: cashAccountsData,
    loading: cashAccountsLoading,
    makeQuery: getCashAccounts,
  } = useLazyQuery<CashAccount[]>(APIRoute.GetCashAccounts)
  const {
    data: creditsData,
    loading: creditsLoading,
    makeQuery: getCredits,
  } = useLazyQuery<Credit[]>(APIRoute.GetCredits)
  const {
    data: debitsData,
    loading: debitsLoading,
    makeQuery: getDebits,
  } = useLazyQuery<Debit[]>(APIRoute.GetDebits)
  const {
    data: propertiesData,
    loading: propertiesLoading,
    error: propertiesError,
    makeQuery: getProperties,
  } = useLazyQuery<Property[]>(APIRoute.GetProperties)
  const {
    data: purchasePlansData,
    loading: purchasePlansLoading,
    makeQuery: getPurchasePlans,
  } = useLazyQuery<PurchasePlan[]>(APIRoute.GetPurchasePlans)

  const loading =
    cashAccountsLoading ||
    creditsLoading ||
    debitsLoading ||
    propertiesLoading ||
    purchasePlansLoading

  useEffect(() => {
    getCashAccounts()
    getProperties()
  }, [])

  useEffect(() => {
    if (cashAccountsData && cashAccountsData.length > 0) {
      getCredits()
      setCurrentStep(1)
    }
  }, [cashAccountsData])

  useEffect(() => {
    if (creditsData && creditsData.length > 0) {
      getDebits()
      setCurrentStep(2)
    }
  }, [creditsData])

  useEffect(() => {
    if (debitsData && debitsData.length > 0) {
      setCurrentStep(3)

      if (propertiesError?.name === 'RequiresUpgradeError') {
        getPurchasePlans()
      } else {
        getProperties()
      }
    }
  }, [debitsData])

  useEffect(() => {
    if (propertiesData && propertiesData.length > 0) {
      getPurchasePlans()
      setCurrentStep(4)
    }
  }, [propertiesData, propertiesError])

  useEffect(() => {
    if (purchasePlansData && purchasePlansData.length > 0) {
      if (propertiesError?.name === 'RequiresUpgradeError') {
        setCurrentStep(5)
      } else {
        setCurrentStep(6)
      }
    } else if (propertiesData && propertiesData.length > 0) {
      setCurrentStep(5)
    }
  }, [purchasePlansData])

  useEffect(() => {
    if (user) {
      user.getIdTokenResult().then((res: auth.IdTokenResult) => setIdToken(res))
    }
  }, [user])
  useEffect(() => {
    if (
      !welcomeLoading &&
      idToken &&
      !idToken.claims.emailWelcomeSent &&
      !sentWelcome
    ) {
      sendWelcome()
      setSentWelcome(true)
    }
  }, [idToken])

  const percentage = Math.min((100 / 5) * (currentStep || 0), 100)
  const isComplete = percentage === 100

  return (
    <>
      <Helmet>
        <title>Home - Doorstep</title>
      </Helmet>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Breadcrumb>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        </Breadcrumb>
        <PageHeader title="Welcome" />
        {loading && <Skeleton loading active />}
        {!loading && (
          <>
            <Card className="profile-progress">
              <div>
                <h3>
                  {isComplete
                    ? 'Your profile is complete'
                    : 'Complete your profile'}
                </h3>
                {isComplete && (
                  <p>
                    You can click on each step below to jump to the section of
                    Doorstep.
                  </p>
                )}
                {!isComplete && (
                  <>
                    <p>Your profile is {percentage}% complete.</p>
                    <p>
                      To get the most out of Doorstep, please follow the steps
                      listed below. You can click on each step to complete it
                      and return to this page by clicking the home icon in the
                      main menu.
                    </p>
                  </>
                )}
              </div>
              <div className="progress">
                <Progress type="circle" percent={percentage} />
              </div>
            </Card>
            <Steps current={currentStep} direction="vertical">
              <Steps.Step
                title={
                  <Link
                    to={`${Routes.FINANCES}${Routes.CASH_ACCOUNTS}`}
                    style={{ color: 'black' }}
                  >
                    Add your savings
                  </Link>
                }
                description="This will be used in your loans and purchase plans to determine how much cash you will have in the future."
              />
              <Steps.Step
                title={
                  <Link
                    to={`${Routes.FINANCES}${Routes.CREDITS}`}
                    style={{ color: 'black' }}
                  >
                    Add your income
                  </Link>
                }
                description="Add any capital gains, including income, gifts, or dividends, for example. This will help forecast how much cash you will have in the future."
              />
              <Steps.Step
                title={
                  <Link
                    to={`${Routes.FINANCES}${Routes.DEBITS}`}
                    style={{ color: 'black' }}
                  >
                    Add your expenses
                  </Link>
                }
                description="Add any debits, including expenses, or holidays, for example. This will help forecast how much cash you will have in the future."
              />
              {propertiesError?.name === 'RequiresUpgradeError' ? null : (
                <Steps.Step
                  title={
                    <Link to={Routes.PROPERTIES} style={{ color: 'black' }}>
                      Add properties you own
                    </Link>
                  }
                  description="Register any properties you own and the loans you are paying against them. This will enable you to calculate the equity you can access from these properties, and also allow you to determine the best ways for you to pay off your home loan faster."
                />
              )}
              <Steps.Step
                title={
                  <Link to={Routes.PURCHASE_PLANS} style={{ color: 'black' }}>
                    Add a purchase plan
                  </Link>
                }
                description="Create plans to purchase properties in the future, determining what you can afford based on if you use cash, sell a property, or use equity in a property. Forecast your future finances to determine if your plan is actually achievable."
              />
            </Steps>
          </>
        )}
        {children}
      </Space>
    </>
  )
}

export default authenticatedRoute(Home)
