import { useState } from 'react'
import { query, RouteFunction } from '../network'

interface Result<T> {
  data?: T
  loading: boolean
  error?: Error
}

export function useLazyQuery<T>(routeFn: RouteFunction) {
  const [result, setResult] = useState<Result<T>>({
    loading: false,
  })

  const makeQuery = async (body?: object, ...params: any[]) => {
    try {
      setResult({
        data: result.data,
        loading: true,
        error: undefined,
      })

      const data = await query<T | { error: string; message: string }>(
        routeFn(...params),
        body,
      )

      if ('error' in data) {
        const error = new Error(data.message)
        error.name = data.error

        throw error
      }

      setResult({
        data,
        loading: false,
        error: undefined,
      })

      return data
    } catch (error) {
      setResult({
        data: undefined,
        loading: false,
        error,
      })
    }
  }

  return { makeQuery, ...result }
}
