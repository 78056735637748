import { RouteComponentProps, Link, navigate, useLocation } from '@reach/router'
import { Alert, Menu, Skeleton, Space, Breadcrumb } from 'antd'
import { useEffect, useState } from 'react'
import PageHeader from '../components/PageHeader'
import { useLazyQuery } from '../hooks/useLazyQuery'
import { APIRoute } from '../network'
import { Routes } from '../routes'
import { authenticatedRoute } from '../components/AuthenticatedRoute'
import { Helmet } from 'react-helmet'
import { PurchasePlan } from '../../../shared/types/PurchasePlan'
import PurchasePlanAffordabilityView from '../components/PurchasePlanAffordabilityView'
import PurchasePlanTargetView from '../components/PurchasePlanTargetView'
import { HelpLabel } from '../components/HelpLabel'
import { HelpText } from '../HelpText'

const PurchasePlanView: React.FC<RouteComponentProps<{ id: string }>> = ({
  id,
}) => {
  const location = useLocation()
  const [plan, setPlan] = useState<PurchasePlan | undefined>(undefined)

  const {
    makeQuery: getPlan,
    data: planData,
    loading: planLoading,
    error: planError,
  } = useLazyQuery<PurchasePlan>(APIRoute.GetPurchasePlan)

  useEffect(() => {
    getPlan(undefined, id)
  }, [])

  useEffect(() => {
    if (planData) {
      setPlan(planData)
    }
  }, [planData])

  const current = location.href.includes('target') ? 'target' : 'affordability'

  return (
    <>
      <Helmet>
        <title>{plan ? plan.name : 'Purchase Plan'} - Doorstep</title>
      </Helmet>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={Routes.PROPERTIES}>Purchase Plans</Link>
          </Breadcrumb.Item>
          {plan && <Breadcrumb.Item>{plan.name}</Breadcrumb.Item>}
        </Breadcrumb>
        <PageHeader title={plan ? plan.name : 'Purchase Plan'} />
        {planError && (
          <Alert
            message="Error"
            description="There was an issue getting this purchase plan. Please try again later."
            type="error"
            showIcon
          />
        )}
        {plan && (
          <Menu
            onClick={({ key }) =>
              navigate(
                `${Routes.PURCHASE_PLANS}/${id}${
                  key === 'target' ? '/target' : ''
                }`,
              )
            }
            selectedKeys={[current]}
            mode="horizontal"
          >
            <Menu.Item key="affordability">
              <HelpLabel tooltip={HelpText.PlanAffordability}>
                Affordability
              </HelpLabel>
            </Menu.Item>
            <Menu.Item key="target">
              <HelpLabel tooltip={HelpText.PlanTarget}>Target</HelpLabel>
            </Menu.Item>
          </Menu>
        )}
        <Skeleton loading={planLoading} active />
        {plan && current === 'affordability' && (
          <PurchasePlanAffordabilityView
            plan={plan}
            onUpdate={updatedPlan => setPlan(updatedPlan)}
          />
        )}
        {plan && current === 'target' && (
          <PurchasePlanTargetView
            plan={plan}
            onUpdate={updatedPlan => setPlan(updatedPlan)}
          />
        )}
      </Space>
    </>
  )
}

export default authenticatedRoute(PurchasePlanView)
