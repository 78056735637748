import { RouteComponentProps } from '@reach/router'
import { Alert, Col, Row, Skeleton, Space, message, Button } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Credit } from '../../../shared/types/Credit'
import CreditCreateForm from '../components/CreditCreateForm'
import CreditForm from '../components/CreditForm'
import { FinancesContext } from '../components/FinancesContext'
import { useLazyQuery } from '../hooks/useLazyQuery'
import { APIRoute } from '../network'

const CreditList: React.FC<RouteComponentProps> = () => {
  const financesContext = useContext(FinancesContext)
  const [credits, setCredits] = useState<Credit[] | undefined>(undefined)
  const [createOpen, setCreateOpen] = useState(false)

  const {
    makeQuery: getCredits,
    data: creditsData,
    loading: creditsLoading,
    error: creditsError,
  } = useLazyQuery<Credit[]>(APIRoute.GetCredits)
  const {
    makeQuery: createCredit,
    data: createData,
    error: createError,
  } = useLazyQuery<Credit>(APIRoute.CreateCredit)
  const {
    makeQuery: updateCredit,
    data: updateData,
    error: updateError,
  } = useLazyQuery<Credit>(APIRoute.UpdateCredit)
  const {
    makeQuery: deleteCredit,
    data: deleteData,
    error: deleteError,
  } = useLazyQuery<Credit>(APIRoute.DeleteCredit)

  useEffect(() => {
    getCredits()
  }, [])

  useEffect(() => {
    if (creditsData) {
      setCredits(creditsData)
    }
  }, [creditsData])

  useEffect(() => {
    if (createData) {
      financesContext.setFinancesUpdated(financesContext.financesUpdated + 1)
      message.success('Saved')
      setCreateOpen(false)

      setCredits([...(credits || []), createData])
    }
  }, [createData])

  useEffect(() => {
    if (updateData && credits) {
      financesContext.setFinancesUpdated(financesContext.financesUpdated + 1)
      message.success('Saved')

      const creditIndex = credits.findIndex(
        credit => credit.id === updateData.id,
      )

      if (creditIndex !== -1) {
        setCredits([
          ...credits.slice(0, creditIndex),
          updateData,
          ...credits.slice(creditIndex + 1),
        ])
      }
    }
  }, [updateData])

  useEffect(() => {
    if (deleteData && credits) {
      financesContext.setFinancesUpdated(financesContext.financesUpdated + 1)
      message.success('Deleted')

      const creditIndex = credits.findIndex(
        credit => credit.id === deleteData.id,
      )

      if (creditIndex !== -1) {
        setCredits([
          ...credits.slice(0, creditIndex),
          ...credits.slice(creditIndex + 1),
        ])
      }
    }
  }, [deleteData])

  return (
    <>
      <Helmet>
        <title>Credits - Doorstep</title>
      </Helmet>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Skeleton loading={creditsLoading} active />
        {creditsError && (
          <Alert
            message="Error"
            description="There was an issue getting your credits. Please try again later."
            type="error"
            showIcon
          />
        )}
        {createError && (
          <Alert
            message="Error"
            description="There was an issue creating your credit. Please try again later."
            type="error"
            showIcon
          />
        )}
        {updateError && (
          <Alert
            message="Error"
            description="There was an issue updating your credit. Please try again later."
            type="error"
            showIcon
          />
        )}
        {deleteError && (
          <Alert
            message="Error"
            description="There was an issue deleting your credit. Please try again later."
            type="error"
            showIcon
          />
        )}
        {credits && (
          <>
            {credits.map(credit => (
              <Row key={credit.id}>
                <Col xs={24}>
                  <CreditForm
                    credit={credit}
                    onUpdate={values =>
                      updateCredit(
                        {
                          ...values,
                          startDate: new Date(
                            values.startDate.format('yyyy-MM-DD'),
                          ).toISOString(),
                          endDate: values.endDate
                            ? new Date(
                                values.endDate.format('yyyy-MM-DD'),
                              ).toISOString()
                            : undefined,
                        },
                        credit.id,
                      )
                    }
                    onDelete={() => deleteCredit(undefined, credit.id)}
                  />
                </Col>
              </Row>
            ))}
          </>
        )}
        {!creditsLoading && !createOpen && (
          <Button
            size="large"
            type="primary"
            onClick={() => setCreateOpen(true)}
          >
            Add Income
          </Button>
        )}
        {createOpen && (
          <CreditCreateForm
            onCreate={values =>
              createCredit({
                ...values,
                startDate: new Date(
                  values.startDate.format('yyyy-MM-DD'),
                ).toISOString(),
                endDate: values.endDate
                  ? new Date(values.endDate.format('yyyy-MM-DD')).toISOString()
                  : undefined,
              })
            }
            onCancel={() => setCreateOpen(false)}
          />
        )}
      </Space>
    </>
  )
}

export default CreditList
