import { navigate, RouteComponentProps } from '@reach/router'
import { Breadcrumb, Menu, Space } from 'antd'
import { authenticatedRoute } from '../components/AuthenticatedRoute'
import PageHeader from '../components/PageHeader'
import { HelpLabel } from '../components/HelpLabel'
import { HelpText } from '../HelpText'
import { Routes } from '../routes'
import { APIRoute } from '../network'
import { useLazyQuery } from '../hooks/useLazyQuery'
import { CreditDebitOverview } from '../../../shared/types/Finances'
import { useContext, useEffect } from 'react'
import { format } from 'date-fns'
import { currencyFormatter } from '../util'
import { Column } from '@ant-design/charts'
import { FinancesContext } from '../components/FinancesContext'

const SHOW_GRAPH = false

const Finances: React.FC<RouteComponentProps> = ({ location, children }) => {
  const financesContext = useContext(FinancesContext)

  const { makeQuery, data, error } = useLazyQuery<CreditDebitOverview[]>(
    APIRoute.GetCreditDebitOverview,
  )

  useEffect(() => {
    if (SHOW_GRAPH) {
      makeQuery()
    }
  }, [financesContext.financesUpdated])

  const graphConfig = {
    isGroup: true,
    xField: 'date',
    yField: 'amount',
    seriesField: 'name',
    xAxis: {
      tickCount: 10,
      label: {
        formatter: (date: string) => format(new Date(date), 'MMM yyyy'),
      },
      title: {
        text: 'Date',
      },
    },
    yAxis: {
      label: {
        formatter: (price: any) => currencyFormatter.format(price),
      },
    },
    legend: { position: 'top' as any },
    slider: {
      start: 0,
      end: 1 / 30,
      formatter: (date: string) => format(new Date(date), 'MMM yyyy'),
    },
    data: data || [],
  }

  return (
    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
      <Breadcrumb>
        <Breadcrumb.Item>Finances</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader title="Finances" />
      <Menu
        mode="horizontal"
        onClick={e => navigate(`${Routes.FINANCES}/${e.key}`)}
        selectedKeys={[
          location?.pathname.includes('cash-accounts') ? 'cash-accounts' : '',
          location?.pathname.includes('credits') ? 'credits' : '',
          location?.pathname.includes('debits') ? 'debits' : '',
        ]}
        style={{ marginBottom: '1rem' }}
      >
        <Menu.Item key="cash-accounts">
          <HelpLabel tooltip={HelpText.CashAccount}>Savings</HelpLabel>
        </Menu.Item>
        <Menu.Item key="credits">
          <HelpLabel tooltip={HelpText.Credits}>Income</HelpLabel>
        </Menu.Item>
        <Menu.Item key="debits">
          <HelpLabel tooltip={HelpText.Debits}>Expenses</HelpLabel>
        </Menu.Item>
      </Menu>
      {children}
      {!error && SHOW_GRAPH && <Column {...graphConfig} />}
    </Space>
  )
}

export default authenticatedRoute(Finances)
